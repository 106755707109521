import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LangService } from '@app/core/services/api';
import { LanguageService } from '@app/core/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LanguageSelectorComponent implements OnInit {
  languageSelect: any = '';
  languages: string[] = [];

  constructor(private languageService: LanguageService, private translateService: TranslateService, private langService: LangService) {}

  ngOnInit() {
    this.langService.langGet().subscribe(
      (response: string[]) => {
        this.languages = response;
        this.languageSelect = this.translateService.currentLang;
        localStorage.setItem('langSelect', this.languageSelect);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  changeLanguage() {
    this.languageService.changeLanguage(this.languageSelect).subscribe(
      (ok: boolean) => {
        if (ok) {
          localStorage.setItem('langSelect', this.languageSelect);
          window.location.reload();
        }
      },
      (err) => {
        this.languageSelect = null;
        console.error(err);
      }
    );
  }
}
