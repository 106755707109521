import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RatesService } from '@app/core/services/api';
import { ModalService } from '@app/core/services/modal/modal.service';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';

const MOCK_DATA: any[] = [
  {
    cie: '',
    description: {
      en: 'Try to set records and/or acrobatics and/or flight test',
      fr: "Essayer d'établir des records et/ou des vols acrobatiques et/ou un test de vol",
      pt: 'Tentativa de estabelecer recordes e/ou voos acrobáticos e/ou prova de voo',
      es: 'Intento establecer records y/o vuelos acrobáticos y/o test de vuelo'
    },
    coberturas: {
      V: 'R',
      FA: 'R',
      IP: 'R'
    }
  },
  {
    cie: '',
    description: {
      en: 'Member of a club/skill level',
      fr: "Membre d'un club/Degré d'aptitude",
      pt: 'Membro de um clube/Grau de habilidade',
      es: 'Miembro de un club/Grado de aptitud'
    },
    coberturas: {
      V: '',
      FA: '',
      IP: ''
    },
    children: [
      {
        cie: '',
        description: {
          en: 'Advanced/Master',
          fr: 'Avancé/Master',
          pt: 'Avançado/Mestre',
          es: 'Avanzado/Master'
        },
        coberturas: {
          V: '',
          FA: '',
          IP: ''
        },
        children: [
          {
            cie: '',
            description: {
              en: 'Number of flights per year',
              fr: 'Nombre de vols par an',
              pt: 'Número de voos por ano',
              es: 'Numero de vuelos por año'
            },
            coberturas: {
              V: '',
              FA: '',
              IP: ''
            },
            children: [
              {
                cie: '',
                description: {
                  en: '1|&le; 12',
                  fr: '1|&le; 12',
                  pt: '1|&le; 12',
                  es: '1|&le; 12'
                },
                coberturas: {
                  V: 'Std/1‰',
                  FA: 'Std/2,5x',
                  IP: '2,5x/3,5x'
                }
              },
              {
                cie: '',
                description: {
                  en: '2|13-24',
                  fr: '2|13-24',
                  pt: '2|13-24',
                  es: '2|13-24'
                },
                coberturas: {
                  V: '1‰/2‰',
                  FA: '2,5x/5x',
                  IP: '5x/7x'
                }
              },
              {
                cie: '',
                description: {
                  en: '3|&ge; 25',
                  fr: '3|&ge; 25',
                  pt: '3|&ge; 25',
                  es: '3|&ge; 25'
                },
                coberturas: {
                  V: '2‰/3‰',
                  FA: '5x/7x',
                  IP: 'Ex'
                }
              }
            ]
          }
        ]
      },
      {
        cie: '',
        description: {
          en: 'Club/Started',
          fr: 'Club/Initié',
          pt: 'Clube/Iniciado',
          es: 'Club/Iniciado'
        },
        coberturas: {
          V: '',
          FA: '',
          IP: ''
        },
        children: [
          {
            cie: '',
            description: {
              en: 'Number of flights per year',
              fr: 'Nombre de vols par an',
              pt: 'Número de voos por ano',
              es: 'Numero de vuelos por año'
            },
            coberturas: {
              V: '',
              FA: '',
              IP: ''
            },
            children: [
              {
                cie: '',
                description: {
                  en: '1|&le; 12',
                  fr: '1|&le; 12',
                  pt: '1|&le; 12',
                  es: '1|&le; 12'
                },
                coberturas: {
                  V: 'Std',
                  FA: 'Std',
                  IP: '2,5x'
                }
              },
              {
                cie: '',
                description: {
                  en: '2|13-24',
                  fr: '2|13-24',
                  pt: '2|13-24',
                  es: '2|13-24'
                },
                coberturas: {
                  V: '1‰',
                  FA: '2,5x',
                  IP: '5x'
                }
              },
              {
                cie: '',
                description: {
                  en: '3|&ge; 25',
                  fr: '3|&ge; 25',
                  pt: '3|&ge; 25',
                  es: '3|&ge; 25'
                },
                coberturas: {
                  V: '2‰',
                  FA: '5x',
                  IP: '7x'
                }
              }
            ]
          }
        ]
      },
      {
        cie: '',
        description: {
          en: 'Instructor/Two-seat Pilot',
          fr: 'Instructeur/Pilote biplace',
          pt: 'Instrutor/Piloto Biposto',
          es: 'Instructor/Piloto Biplaza'
        },
        coberturas: {
          V: '',
          FA: '',
          IP: ''
        },
        children: [
          {
            cie: '',
            description: {
              en: 'Number of flights per year',
              fr: 'Nombre de vols par an',
              pt: 'Número de voos por ano',
              es: 'Numero de vuelos por año'
            },
            coberturas: {
              V: '',
              FA: '',
              IP: ''
            },
            children: [
              {
                cie: '',
                description: {
                  en: '1|< 50',
                  fr: '1|< 50',
                  pt: '1|< 50',
                  es: '1|< 50'
                },
                coberturas: {
                  V: 'Std/1‰',
                  FA: 'Std/2,5x',
                  IP: '2,5x/3,5x'
                }
              },
              {
                cie: '',
                description: {
                  en: '2|&ge; 50',
                  fr: '2|&ge; 50',
                  pt: '2|&ge; 50',
                  es: '2|&ge; 50'
                },
                coberturas: {
                  V: '1‰/2‰',
                  FA: '2,5x/5x',
                  IP: '5x/7x'
                }
              }
            ]
          }
        ]
      },
      {
        cie: '',
        description: {
          en: 'Pilot',
          fr: 'Pilote',
          pt: 'Piloto',
          es: 'Piloto'
        },
        coberturas: {
          V: '',
          FA: '',
          IP: ''
        },
        children: [
          {
            cie: '',
            description: {
              en: 'Number of flights per year',
              fr: 'Nombre de vols par an',
              pt: 'Número de voos por ano',
              es: 'Numero de vuelos por año'
            },
            coberturas: {
              V: '',
              FA: '',
              IP: ''
            },
            children: [
              {
                cie: '',
                description: {
                  en: '1|&le; 12',
                  fr: '1|&le; 12',
                  pt: '1|&le; 12',
                  es: '1|&le; 12'
                },
                coberturas: {
                  V: '1‰/2‰',
                  FA: '2,5x/5x',
                  IP: '5x/7x'
                }
              },
              {
                cie: '',
                description: {
                  en: '2|13-24',
                  fr: '2|13-24',
                  pt: '2|13-24',
                  es: '2|13-24'
                },
                coberturas: {
                  V: '2‰/3‰',
                  FA: '5x/7x',
                  IP: 'Ex'
                }
              },
              {
                cie: '',
                description: {
                  en: '3|&ge; 25',
                  fr: '3|&ge; 25',
                  pt: '3|&ge; 25',
                  es: '3|&ge; 25'
                },
                coberturas: {
                  V: '3‰/4‰',
                  FA: '7x/Ex',
                  IP: 'Ex'
                }
              }
            ]
          }
        ]
      },
      {
        cie: '',
        description: {
          en: 'Beginner/Elementary',
          fr: 'Débutant/Élémentaire',
          pt: 'Principiante/Elementar',
          es: 'Principiante/Elemental'
        },
        coberturas: {
          V: '2‰',
          FA: '5x',
          IP: 'Ex'
        }
      }
    ]
  },
  {
    cie: '',
    description: {
      en: 'Not a member of a club',
      fr: "Pas membre d'un club",
      pt: 'Não membro de clube',
      es: 'No miembro de club'
    },
    coberturas: {
      V: '4‰',
      FA: 'Ex',
      IP: 'Ex'
    }
  }
];
@Component({
  selector: 'app-edit-tarifas-table',
  templateUrl: './edit-tarifas-table.component.html',
  styleUrls: ['./edit-tarifas-table.component.scss']
})
export class EditTarifasTableComponent implements OnInit {
  editForm: FormGroup = new FormGroup({
    rates: this.formBuilder.array([])
  });
  @Input() risk = '';
  @Output() cancelEvent: EventEmitter<boolean> = new EventEmitter();
  constructor(private formBuilder: FormBuilder, private loaderSrv: SpinnerService, private ratesSrv: RatesService, private modalSrv: ModalService) {}

  ngOnInit() {
    /* this.editForm = new FormGroup({
      rates: this.formBuilder.array([this.createRateForm()])
    });*/
    this.loadData();
    // console.log(this.editForm);
  }

  loadData() {
    // console.log(this.risk);
    const loader = this.loaderSrv.showSpinner();
    this.ratesSrv.ratesEditGet(this.risk).subscribe({
      next: (response) => {
        // console.log(response);
        this.editForm = new FormGroup({
          children: this.formBuilder.array([])
        });
        this.prepareData(response as any, this.editForm);
        this.editForm.patchValue((response as any).values);
        loader.close();
      },
      error: (error) => loader.close()
    });
  }

  prepareData(data: any[], parentForm: FormGroup) {
    data.forEach((item, index) => {
      // console.log(index);
      const form: FormGroup = this.addRate(item, parentForm);
      if (item.children && item.children.length) {
        this.prepareData(item.children, form);
      }
    });
  }

  recursiveReadChildren(rate: any, index: number, refControl: FormArray) {
    // Es un elemento
    if (rate) {
      // Lo añadimos en el form control
      if (Array.isArray(rate)) {
        rate.forEach((children, cIndex) => {
          this.recursiveReadChildren(children, cIndex, (refControl.controls[index] as FormGroup).controls.children as FormArray);
        });
      } else {
        refControl.push(this.createRateForm());
        this.recursiveReadChildren(rate.children, index, refControl[index]);
      }
    }
  }
  addRate(rate, parentForm: FormGroup) {
    const formGroup = this.createRateForm(rate);
    (parentForm.controls.children as FormArray).push(formGroup);
    return formGroup;
  }
  createRateForm(value?: any): FormGroup {
    const form: FormGroup = new FormGroup({
      cie: new FormControl('', []),
      description: new FormGroup({
        en: new FormControl('', [Validators.required]),
        fr: new FormControl('', [Validators.required]),
        pt: new FormControl('', [Validators.required]),
        es: new FormControl('', [Validators.required])
      }),
      coberturas: new FormGroup({
        V: new FormControl('', [Validators.required]),
        FA: new FormControl('', [Validators.required]),
        IP: new FormControl('', [Validators.required])
      }),
      children: new FormArray([], [])
    });

    if (value) {
      form.patchValue(value);
    }

    return form;
  }

  addParent() {
    this.addRate(null, this.editForm);
  }

  addChild(event: FormGroup) {
    this.addRate(null, event);
    // console.log(this.editForm.value);
  }
  deleteChild(event: { parent: FormGroup; index: number; child?: boolean }, i: number) {
    if (event.child) {
      (this.editForm.controls.children as FormArray).removeAt(i);
    } else {
      (event.parent.controls.children as FormArray).removeAt(event.index);
    }
    // console.log(this.editForm.value);
  }

  cancelEdit() {
    this.cancelEvent.emit(false);
  }
  saveRate() {
    // Obtener el formulario
    // console.log(this.editForm);
    // Data tiene los datos correctos
    const loader = this.loaderSrv.showSpinner();
    const data = this.editForm.value;
    // console.log(data);
    // console.log(data.children);
    this.ratesSrv.ratesSavePost({ risk: this.risk, rates: data.children }).subscribe({
      next: (response) => {
        loader.close();
        if (response && (response as any).orden > 0) {
          this.cancelEvent.emit(true);
        } else if (this.instanceOfRatesError(response)) {
          this.modalSrv.openModalDanger('Error', '');
        } else {
          this.cancelEvent.emit(false);
        }
      },
      error: (error) => {
        loader.close();
      }
    });
  }

  private instanceOfRatesError(data: any) {
    if (!data) {
      return true;
    } else {
      return 'err' in data;
    }
  }
}
