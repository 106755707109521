import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, MissingTranslationHandler, TranslateLoader } from '@ngx-translate/core';
import { CustomMissingTranslationHandler } from '@services/language/missing-translation-handler';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from './core/core.module';
import { GlobalModule } from './global/global.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QbFooterModule, QbHeaderModule } from '@fwmovilidad/qb-lib-ng-components';
import { SharedModule } from './shared';
import { MaterialModule } from './shared/material/material.module';
import { ApiModule } from './core/services/api';
import { AuthConfigModule } from './core/authentication/auth-code.module';

import es from '@angular/common/locales/es';
import en from '@angular/common/locales/en';
import pt from '@angular/common/locales/pt';
import fr from '@angular/common/locales/fr';
import { CommonModule, DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LanguagesInterceptorInterceptor } from './core/interceptors/languages-interceptor.interceptor';
import { EmptyPipe } from './core/pipes/empty.pipe';
registerLocaleData(es);
registerLocaleData(en);
registerLocaleData(pt);
registerLocaleData(fr);

// Translate
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    CoreModule,
    GlobalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
      }
    }),
    BrowserAnimationsModule,
    QbHeaderModule,
    QbFooterModule,
    MaterialModule.forRoot(),
    SharedModule,
    ApiModule,
    AuthConfigModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: MAT_DATE_LOCALE, useValue: localStorage.getItem('langSelect') ? localStorage.getItem('langSelect') : 'ES-es' },
    { provide: LOCALE_ID, useValue: localStorage.getItem('langSelect') ? localStorage.getItem('langSelect') : 'ES-es' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguagesInterceptorInterceptor,
      multi: true
    },
    DatePipe,
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
