import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss']
})
export class CommonFilterComponent implements OnInit {
  @Output() search = new EventEmitter<any>();
  @Output() updateFilter = new EventEmitter<any>();
  @Output() hide = new EventEmitter<boolean>();
  searchForm: FormGroup;

  constructor() {}

  ngOnInit() {
    this.searchForm = new FormGroup({
      idCase: new FormControl('', []),
      referenceNumber: new FormControl('', []),
      startDate: new FormControl('', []),
      endDate: new FormControl('', [])
    });
    this.searchForm.valueChanges.subscribe({
      next: (value) => this.updateFilter.emit(value)
    });
  }

  apply() {
    this.search.emit(this.searchForm.value);
  }

  clearForm() {
    this.searchForm.reset({ idCase: '', referenceNumber: '' });
    this.apply();
  }

  hideFilter() {
    this.hide.emit(true);
  }
}
