import { Component, OnInit, ViewChild, ViewEncapsulation, AfterViewChecked, AfterViewInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { CaseDataService } from '@app/core/services/case-data/case-data.service';
import { CedenteService } from '@app/core/services/cedente/cedente.service';
import { MenuService } from '@app/core/services/menu/menu.service';
import { DOCTOR } from '../utils/commonMethods';

@Component({
  selector: 'app-maresel-nav-menu',
  templateUrl: './maresel-nav-menu.component.html',
  styleUrls: ['./maresel-nav-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MareselNavMenuComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @ViewChild('drawer') public sidenav: MatSidenav;

  linkHover: any;
  linkHover2: any;
  linkHover3: any;

  activeLink: any;
  activeLink2: any;
  activeLink3: any;

  currentLink: string;
  allMenuIndex: any;
  displayedLevel2: any;
  secondLevelBoolean: boolean;

  constructor(
    public menuService: MenuService,
    public caseDataSrv: CaseDataService,
    private cedenteSrv: CedenteService,
    private router: Router,
    private authSrv: AuthenticationService
  ) { }

  ngOnInit() {
    if (this.menuService.menuResponse?.length === 0) {
      this.menuService.getMenu();
    }
    this.caseDataSrv.checkCase();
    this.cedenteSrv.getCedentes();
    this.currentLink = this.menuService.getCurrentMenu();

  }
  ngAfterViewInit(): void {
    this.menuService.getMenuLabels().subscribe(() => {
      this.allMenuIndex = this.menuService?.menuResponse;
      this.checkMenu();
      // console.log(this.allMenuIndex);
    });
  }

  checkMenu() {
    let i = 0;
    this.secondLevelBoolean = false; // Establece inicialmente a false

    for (const item of this.allMenuIndex) {
      if (i < 3) {
        if (item.text === this.currentLink) {
          // console.log(item.text);
          this.secondLevelBoolean = true; // Si encuentra una coincidencia, establece a true
          // simular el click
          this.hoverL1(item);
          break; // Detén la ejecución del bucle una vez que se encuentra una coincidencia
        }
        i++;
      } else {
        break; // Si ya se han verificado las tres primeras entradas, detén la ejecución del bucle
      }
    }
  }


  ngAfterViewChecked(): void {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 250);

  }

  hoverL1(link: any) {
    this.linkHover = link;
    if (this.linkHover?.menu?.length === 0) {
      this.sidenav.close();
    }
    this.menuService.setCurrentMenu(link.text);
    this.secondLevelBoolean = true;
    this.clearL3();
  }

  hoverL2(link: any) {
    this.linkHover2 = link;
    this.clearL3();
    if (this.linkHover2?.menu?.length > 0) {
      this.sidenav.open();
    } else {
      this.sidenav.close();
    }
  }
  hoverL3(link: any) {
    this.linkHover3 = link;
  }

  clearL3() {
    if (this.linkHover3?.menu?.length) {
      this.linkHover3 = undefined;
    }
  }

  childresIsActive(link: any) {
    for (let index = 0; index < link?.length; index++) {
      const element = link[index];
      if (element.content === this.router.url) {
        return true;
      } else {
        let result = this.childresIsActive(element.menu);
        if (result) {
          return true;
        }
      }
    }
  }

  isDoctorRole() {
    return this.authSrv.checkRolUser(DOCTOR);
  }
}
