import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-metabolic-calc-lipid-glycemic-profile',
  templateUrl: './metabolic-calc-lipid-glycemic-profile.component.html',
  styleUrls: ['./metabolic-calc-lipid-glycemic-profile.component.scss']
})
export class MetabolicCalcLipidGlycemicProfileComponent implements OnInit {
  @Input() data;

  lipidGlycemicProfile: FormGroup = new FormGroup({
    unitMeasurement: new FormControl('mg/dl')
  });

  constructor(private formGroupDirective: FormGroupDirective) {}

  ngOnInit() {
    if (this.formGroupDirective.form) {
      this.formGroupDirective.form.setControl('lipidGlycemicProfile', this.lipidGlycemicProfile);
    }

  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit(): void {
    if (this.data) {
      this.lipidGlycemicProfile.patchValue(this.data);
    }
  }
}
