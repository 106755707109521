import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ActivityService } from './api/activity.service';
import { ArticlesService } from './api/articles.service';
import { CancelregisteruserService } from './api/cancelregisteruser.service';
import { CasesService } from './api/cases.service';
import { CedentesService } from './api/cedentes.service';
import { ContacRequestService } from './api/contacRequest.service';
import { ContenidoService } from './api/contenido.service';
import { CountryService } from './api/country.service';
import { CoveragesService } from './api/coverages.service';
import { CreatetarifaService } from './api/createtarifa.service';
import { CurrenciesService } from './api/currencies.service';
import { DurationService } from './api/duration.service';
import { FaqService } from './api/faq.service';
import { FormsService } from './api/forms.service';
import { ForgotPasswordService } from './api/forgotPassword.service';
import { HomeService } from './api/home.service';
import { LangService } from './api/lang.service';
import { LoginService } from './api/login.service';
import { MedicamentosService } from './api/medicamentos.service';
import { MelanomaService } from './api/melanoma.service';
import { MenusService } from './api/menus.service';
import { MetabolicoService } from './api/metabolico.service';
import { ModifyuserService } from './api/modifyuser.service';
import { OperandosService } from './api/operandos.service';
import { RatesService } from './api/rates.service';
import { RefreshTokenService } from './api/refreshToken.service';
import { RegistercaseService } from './api/registercase.service';
import { RegisteruserService } from './api/registeruser.service';
import { RemoveuserService } from './api/removeuser.service';
import { RolesService } from './api/roles.service';
import { SearchService } from './api/search.service';
import { SearchcaseService } from './api/searchcase.service';
import { SearchuserService } from './api/searchUser.service';
import { SignupService } from './api/signup.service';
import { ValidateuserService } from './api/validateuser.service';
import { VerificacodeService } from './api/verificacode.service';
import { VerpassService } from './api/verpass.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    ActivityService,
    ArticlesService,
    CancelregisteruserService,
    CasesService,
    CedentesService,
    ContacRequestService,
    ContenidoService,
    CountryService,
    CoveragesService,
    CreatetarifaService,
    CurrenciesService,
    DurationService,
    FaqService,
    FormsService,
    ForgotPasswordService,
    HomeService,
    LangService,
    LoginService,
    MedicamentosService,
    MelanomaService,
    MenusService,
    MetabolicoService,
    ModifyuserService,
    OperandosService,
    RatesService,
    RefreshTokenService,
    RegistercaseService,
    RegisteruserService,
    RemoveuserService,
    RolesService,
    SearchService,
    SearchcaseService,
    SearchuserService,
    SignupService,
    ValidateuserService,
    VerificacodeService,
    VerpassService
  ]
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
