import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { LoginService } from '@app/core/services/api';
import { OidcSecurityService, UserDataResult } from 'angular-auth-oidc-client';
import { Subscription } from 'rxjs';

export interface IDataFABMenu {
  name: string;
  nameInitials: string;
  profile: string;
}

@Component({
  selector: 'app-maresel-header-logo',
  templateUrl: './maresel-header-logo.component.html',
  styleUrls: ['./maresel-header-logo.component.scss']
})
export class MareselHeaderLogoComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  isAuthorized = false;
  userData: any;
  fabMenuData: IDataFABMenu;
  // nameInitials: string;
  constructor(
    public oidcSecurityService: OidcSecurityService,
    private authenticationService: AuthenticationService,
    private loginService: LoginService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscription = this.oidcSecurityService.isAuthenticated$.subscribe(async (data) => {
      if (data.isAuthenticated) {
        const roles = await this.loginService.rolUserGet().toPromise();
        if (roles && Array.isArray(roles) && roles.length) {
          this.isAuthorized = data.isAuthenticated;
          this.oidcSecurityService.userData$.subscribe((dataUser: UserDataResult) => {
            if (dataUser && dataUser.userData) {
              this.userData = dataUser.userData;
              this.letterName(this.userData.name);
              this.getFABMenuData();
            }
          });
        }
      } else if (sessionStorage.getItem(this.authenticationService.TOKEN) !== null) {
        if (this.authenticationService.checkToken(sessionStorage.getItem(this.authenticationService.TOKEN))) {
          if (this.router.url !== '/login') {
            this.isAuthorized = true;
            this.userData = this.authenticationService.decodeJwt(sessionStorage.getItem(this.authenticationService.TOKEN));
            this.getFABMenuData();
          } else {
            this.isAuthorized = false;
          }
        }
      } else {
        this.isAuthorized = false;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getFABMenuData() {
    const { name } = this.userData;
    const profile = this.authenticationService.getRoles()[0];
    this.fabMenuData = { name, profile, nameInitials: this.letterName(name) };
  }

  letterName(name: string): string {
    if (!name) {
      return;
    }
    const nameParts = name.split(',');
    if (nameParts[1]) {
      // this.nameInitials = nameParts[0][0] + nameParts[1].trim()[0];
      return nameParts[0][0] + nameParts[1].trim()[0];
    } else {
      // this.nameInitials = nameParts[0][0];
      return nameParts[0][0];
    }
  }
}
