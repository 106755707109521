/**
 * servicios menus y contenido
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: csgj@gft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { InlineResponse20017 } from '../model/inlineResponse20017';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class SearchuserService {
  protected basePath = environment.apiUrl + environment.apiEnv;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Obtiene la tabla
   * @param email
   * @param state
   * @param fromDate
   * @param toDate
   * @param paginator
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchuserGet(
    email?: string,
    state?: number,
    fromDate?: string,
    toDate?: string,
    paginator?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse20017>;
  public searchuserGet(
    email?: string,
    state?: number,
    fromDate?: string,
    toDate?: string,
    paginator?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse20017>>;
  public searchuserGet(
    email?: string,
    state?: number,
    fromDate?: string,
    toDate?: string,
    paginator?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse20017>>;
  public searchuserGet(
    email?: string,
    state?: number,
    fromDate?: string,
    toDate?: string,
    paginator?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (email !== undefined && email !== null) {
      queryParameters = queryParameters.set('email', <any>email);
    }
    if (state !== undefined && state !== null) {
      queryParameters = queryParameters.set('state', <any>state);
    }
    if (fromDate !== undefined && fromDate !== null) {
      queryParameters = queryParameters.set('fromDate', <any>fromDate);
    }
    if (toDate !== undefined && toDate !== null) {
      queryParameters = queryParameters.set('toDate', <any>toDate);
    }
    if (paginator !== undefined && paginator !== null) {
      queryParameters = queryParameters.set('paginator', <any>paginator);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20017>('get', `${this.basePath}/searchuser`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * Obtiene xlsx los usuarios
   * @param email
   * @param state
   * @param fromDate
   * @param toDate
   * @param paginator
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchuserxlsxGet(
    email?: string,
    state?: number,
    fromDate?: string,
    toDate?: string,
    paginator?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public searchuserxlsxGet(
    email?: string,
    state?: number,
    fromDate?: string,
    toDate?: string,
    paginator?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public searchuserxlsxGet(
    email?: string,
    state?: number,
    fromDate?: string,
    toDate?: string,
    paginator?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public searchuserxlsxGet(
    email?: string,
    state?: number,
    fromDate?: string,
    toDate?: string,
    paginator?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (email !== undefined && email !== null) {
      queryParameters = queryParameters.set('email', <any>email);
    }
    if (state !== undefined && state !== null) {
      queryParameters = queryParameters.set('state', <any>state);
    }
    if (fromDate !== undefined && fromDate !== null) {
      queryParameters = queryParameters.set('fromDate', <any>fromDate);
    }
    if (toDate !== undefined && toDate !== null) {
      queryParameters = queryParameters.set('toDate', <any>toDate);
    }
    if (paginator !== undefined && paginator !== null) {
      queryParameters = queryParameters.set('paginator', <any>paginator);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/vnd.ms-excel'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Blob>('get', `${this.basePath}/searchuserxlsx`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
