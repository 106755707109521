import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class LanguagesInterceptorInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;

    if (localStorage.getItem('langSelect') !== null) {
      const language = localStorage.getItem('langSelect');
      request = request.clone({
        setHeaders: {
          'Accept-Language': `${language}`
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          'Accept-Language': 'es'
        }
      });
    }

    return next.handle(request).pipe(
      // tslint:disable-next-line: no-any
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => throwError(error))
    );
  }
}
