import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogOverviewComponent } from '@shared/dialog-overview/dialog-overview.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  constructor(public dialog: MatDialog) {}

  showSpinner(message?: string) {
    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      data: { message },
      disableClose: true
    });
    return dialogRef;
  }
}
