import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MedicamentosService } from '@app/core/services/api';
import { GetDrugIndicationsBody } from '@app/core/services/api/model/getDrugIndicationsBody';
import { MedicamentosBody } from '@app/core/services/api/model/medicamentosBody';
import { MedicamentosInner } from '@app/core/services/api/model/medicamentosInner';
import { ModalService } from '@app/core/services/modal/modal.service';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { Observable, of } from 'rxjs';
import { DRUGSTORAGE, instanceOfError } from '../utils/commonMethods';

export interface IDrugItem {
  dci: string; // Drug name
  ingredients: string[]; // Ingredients name
  name: string; // Display Name
}

@Component({
  selector: 'app-drug-finder-seeker',
  templateUrl: './drug-finder-seeker.component.html',
  styleUrls: ['./drug-finder-seeker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DrugFinderSeekerComponent implements OnInit {
  @Output() getData = new EventEmitter<any>();
  @ViewChild('autoTrigger', { read: MatAutocompleteTrigger })
  autoTrigger: MatAutocompleteTrigger;
  drugFinderControl: FormControl;
  drugOptions: Observable<any[]>;
  dataSelected: any;
  drugList: any[] = [];
  drugListExtended: MedicamentosInner[] = [];
  drugIsNotAdded = true;
  console = console;
  constructor(private medicineSrv: MedicamentosService, private loaderSrv: SpinnerService, private modalService: ModalService) {}

  ngOnInit() {
    this.drugFinderControl = new FormControl();
    this.drugOptions = of([]);
    /* this.drugFinderControl.valueChanges.pipe(debounceTime(500)).subscribe({
      next: (medicineName: string | MedicamentosInner) => {
        // console.log(medicineName);
        if (typeof medicineName === 'string' && medicineName.length) {
          const loader = this.loaderSrv.showSpinner();
          const body: MedicamentosBody = {
            medicineName
          };
          this.medicineSrv.medicamentosPost(body).subscribe({
            next: (data) => {
              if (!instanceOfError(data)) {
                this.drugOptions = of(data);
              }
              loader.close();
            },
            error: (error) => loader.close()
          });
        }
      }
    }); */
  }

  getDrugList(medicineName: string) {
    // console.log('Buscamos:', medicineName);
    const loader = this.loaderSrv.showSpinner();
    const body: MedicamentosBody = {
      medicineName
    };
    this.medicineSrv.medicamentosPost(body).subscribe({
      next: (data) => {
        if (!instanceOfError(data)) {
          this.drugOptions = of(data);
          this.autoTrigger.openPanel();
        }
        loader.close();
      },
      error: (error) => loader.close()
    });
  }

  searchDrug() {
    if (this.dataSelected) {
      this.dataSelected = null;
    }
    const medicineName = this.drugFinderControl.value;
    
    if (!medicineName) {
      this.modalService.openModalInfo('MODAL-WARNING.TITLE', 'MODAL-WARNING.MSG-DRUG-FINDER.NO-TEXT-DETECTED');
    } else if (typeof medicineName === 'string') {
      if (medicineName.length) {
        this.getDrugList(medicineName);

      }
    } else {
      this.getDrugList(medicineName.displayName);
    }
  }

  sendData() {
    if (this.drugList.length) {
      this.getData.emit(this.drugList);
    } else {
      this.getData.emit(null);
    }
  }

  addDrug(drug: any) {
    this.drugList = [...this.drugList, { name: drug.name!==undefined? drug.name : drug.strength.number!==null?drug.displayName+' '+drug.strength.number+drug.strength.unit : drug.displayName, ingredients: drug.ingredients.map((d) => d.name), dci:drug.name!==undefined ? drug.name : drug.displayName+' '+drug.strength.number+drug.strength.unit }];
    this.drugListExtended = [...this.drugListExtended, drug];
    sessionStorage.setItem(DRUGSTORAGE, JSON.stringify({ drugs: this.drugListExtended }));
    this.drugIsNotAdded = false;
    this.sendData();
  }
  removeDrug(index: number) {
    if (
      this.drugList[index].dci === this.dataSelected.name &&
      this.drugList[index].name === this.dataSelected.displayName &&
      this.drugList[index].ingredients.length === this.dataSelected.ingredients.length &&
      this.dataSelected.ingredients.map((ingredient) => this.drugList[index].ingredients.includes(ingredient.name))
    ) {
      this.drugIsNotAdded = true;
    }
    this.drugList.splice(index, 1);
    this.drugListExtended.splice(index, 1);
    sessionStorage.setItem(DRUGSTORAGE, JSON.stringify({ drugs: this.drugListExtended }));
    this.sendData();
  }

  removeAll() {
    this.drugList.splice(0, this.drugList.length);
    this.drugListExtended.splice(0, this.drugListExtended.length);
    sessionStorage.setItem(DRUGSTORAGE, JSON.stringify({ drugs: this.drugListExtended }));
    this.drugIsNotAdded = true;
    this.sendData();
  }

  displayFn(selected: any): string {
    return selected && selected.name ? selected.name : selected && !selected.name ? selected.strength.number!==null ? `${selected.displayName} ${selected.strength.number}${selected.strength.unit}` : selected.displayName :  '';
  }

  showResults(event: MatAutocompleteSelectedEvent) {
    // console.log(event);
    const optionSelected = event.option.value;
    const getIndicationsBody: GetDrugIndicationsBody = {
      drugbankId: []
    };
    for (const ingredient of optionSelected.ingredients) {
      getIndicationsBody.drugbankId.push(ingredient.drugbank_id);
    }
    const loader = this.loaderSrv.showSpinner();
    this.medicineSrv.getDrugIndicationsPost(getIndicationsBody).subscribe(
      (data) => {
        if (!instanceOfError(data)) {
          let indications;
          if (Array.isArray(data)) {
            indications = [...data];
          } else {
            indications = JSON.parse((data as any).body);
          }
          indications.map((indication) => {
            const index = optionSelected.ingredients.findIndex((item) => item.drugbank_id === indication.id);
            if (index !== -1) {
              optionSelected.ingredients[index] = {
                ...optionSelected.ingredients[index],
                conditions: indication.conditions,
                conditionsAssociated: indication.conditionsAssociated
              };
            }
          });
          this.dataSelected = optionSelected;
          if (
            this.drugList.find(
              (item) =>
                item.dci === this.dataSelected.name &&
                item.name === this.dataSelected.displayName &&
                item.ingredients.length === this.dataSelected.ingredients.length &&
                this.dataSelected.ingredients.map((ingredient) => item.ingredients.includes(ingredient.name))
            )
          ) {
            this.drugIsNotAdded = false;
          } else {
            this.drugIsNotAdded = true;
          }
        }
        loader.close();
      },
      (err) => loader.close()
    );
  }

  showDrug(drug: IDrugItem) {
    this.dataSelected = this.drugListExtended.find((item) => item.name === drug.dci);
  }
}
