import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CalcValidators } from '@app/core/directives/validators/calc-validators';
import { CountryService, InlineResponse20019, Mensaje, MetabolicoService } from '@app/core/services/api';
import { CaseDataService } from '@app/core/services/case-data/case-data.service';
import { ModalService } from '@app/core/services/modal/modal.service';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { forkJoin, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-metabolic-calc-form',
  templateUrl: './metabolic-calc-form.component.html',
  styleUrls: ['./metabolic-calc-form.component.scss']
})
export class MetabolicCalcFormComponent implements OnInit {
  @Input() data;

  heightMeasures: any[] = ['m', 'cm', 'ft'];
  weightMeasures: any[] = ['kg', 'lb'];
  abdominalPerimeterMeasures: any[] = ['cm', 'in'];
  tobaccoQuantities: any[] = [];

  minDate = new Date(new Date().getFullYear() - 110, 0, 1);
  maxDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay());
  basicData: FormGroup;

  showBirthDate: boolean = false;
  birthDate: string;

  @Output() addFormParent = new EventEmitter<FormGroup>();

  constructor(
    private caseInProgressSrv: CaseDataService,
    private formGroupDirective: FormGroupDirective,
    private spinnerService: SpinnerService,
    private metabolicoService: MetabolicoService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private activatedRouter: ActivatedRoute
  ) {}
  caseSuscription: Subscription;
  ngOnInit() {
    const spinner = this.spinnerService.showSpinner();
    forkJoin([this.metabolicoService.smokeGet()]).subscribe(
      (response: [InlineResponse20019[]]) => {
        spinner.close();
        this.tobaccoQuantities = [...response[0]];
      },
      (error) => {
        spinner.close();
        this.modalService.openModalDanger('Error', error);
      }
    );
    this.showBirthDate = !this.hasCaseInCourse() && !this.hasCaseInParams();
    if (this.hasCaseInCourse() && !this.data) {
      this.initForm({ ...JSON.parse(localStorage.getItem('CASE_IN_COURSE')), birthDate: new Date().toISOString() });
    } else {
      if (this.data) {
        this.initForm(this.data);
      } else {
        this.initForm();
      }
    }
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit(): void {
    if (this.data) {
      // console.log(this.data);
      this.basicData.patchValue(this.data);
    }
  }

  // Method to init form
  initForm(caseData?: any) {
    // console.log(caseData);
    // this.showBirthDate = caseData?.age ? false : true;
    this.basicData = new FormGroup({
      age: new FormControl(caseData ? { disabled: true, value: caseData.age } : { disabled: true, value: '' }, [Validators.required]),
      gender: new FormControl(caseData ? { disabled: true, value: caseData.gender } : '', [Validators.required]),
      country: new FormControl(caseData ? { disabled: true, value: this.translateService.instant('COUNTRY.' + caseData.country) } : '', [Validators.required]),
      height: new FormControl('', [Validators.required, CalcValidators.height()]),
      heightMeasure: new FormControl('', [Validators.required]),
      weight: new FormControl('', [Validators.required, CalcValidators.weight()]),
      weightMeasure: new FormControl('', [Validators.required]),
      imc: new FormControl({ disabled: true, value: '' }, [Validators.required]),
      abdominalPerimeter: new FormControl('', [CalcValidators.abdominalPerimeter(0, 0, '')]),
      abdominalPerimeterMeasure: new FormControl(''),
      tobacco: new FormControl(false),
      tobaccoQuantity: new FormControl('')
    });
    if (this.showBirthDate) {
      this.basicData.addControl(
        'birthDate',
        new FormControl(caseData ? { disabled: true, value: this.birthDate ? this.birthDate : caseData.birthDate } : '', [
          CalcValidators.age(18, 100),
          Validators.required
        ])
      );
      this.basicData.controls.birthDate.valueChanges.subscribe((value) => (this.birthDate = value));
    }
    if (this.formGroupDirective.form) {
      this.formGroupDirective.form.setControl('basicData', this.basicData);
    }

    this.basicData.controls.tobacco.valueChanges.subscribe((value) => {
      if (!value) {
        this.basicData.controls.tobaccoQuantity.reset();
      }
    });

    this.basicData.controls.gender.valueChanges.subscribe((value) => {
      this.calcIMC();
      if (this.formGroupDirective.form) {
        (this.formGroupDirective.form.controls.kidneyProfile as FormGroup).controls.creatinine.patchValue(
          (this.formGroupDirective.form.controls.kidneyProfile as FormGroup).controls.creatinine.value
        );
      }
    });
    this.basicData.controls.height.valueChanges.pipe(debounceTime(500)).subscribe(() => this.calcIMC());
    this.basicData.controls.heightMeasure.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.calcIMC();
      this.basicData.controls.height.updateValueAndValidity();
    });
    this.basicData.controls.weight.valueChanges.pipe(debounceTime(500)).subscribe(() => this.calcIMC());
    this.basicData.controls.weightMeasure.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.calcIMC();
      this.basicData.controls.weight.updateValueAndValidity();
    });

    this.basicData.controls.abdominalPerimeterMeasure.valueChanges.subscribe((value: string) => {
      this.basicData.controls.abdominalPerimeter.clearValidators();
      if (value === 'cm') {
        this.basicData.controls.abdominalPerimeter.addValidators([CalcValidators.abdominalPerimeter(35, 300, value)]);
      } else if (value === 'in') {
        this.basicData.controls.abdominalPerimeter.addValidators([CalcValidators.abdominalPerimeter(19, 119, value)]);
      } else {
        this.basicData.controls.abdominalPerimeter.addValidators([CalcValidators.abdominalPerimeter(0, 0, '')]);
      }
      this.basicData.controls.abdominalPerimeter.updateValueAndValidity();
    });
  }

  // Method which set age form from date change detected
  calcAge(data: MatDatepickerInputEvent<Date>) {
    const date = moment(data.value);
    this.basicData.get('age').setValue(moment().year() - date.year());
  }

  // Method to check if we have a case in progress assigned
  hasCaseInCourse(): boolean {
    return this.caseInProgressSrv.hasInCourse();
  }

  hasCaseInParams(): boolean {
    const idCaseValue = this.activatedRouter.snapshot.paramMap.get('idCase');
    // Devolvemos true si no es null o valor vacío
    return idCaseValue !== null && idCaseValue !== '';
  }

  calcIMC() {
    if (
      this.basicData.controls.height.value &&
      this.basicData.controls.height.valid &&
      this.basicData.controls.heightMeasure.value &&
      this.basicData.controls.weight.value &&
      this.basicData.controls.weight.valid &&
      this.basicData.controls.weightMeasure.value
    ) {
      const spinner = this.spinnerService.showSpinner();
      const body = this.formGroupDirective?.form.getRawValue();
      delete body.basicData?.birthDate;
      this.metabolicoService.imcPost(body).subscribe(
        (response: Mensaje) => {
          // this.basicData.controls.imc.setValue(response.value);
          if (response.value && typeof response.value === 'object') {
            this.basicData.controls.imc.setValue((response.value as any).imc);
          } else {
            this.basicData.controls.imc.setValue(response.value);
          }
          spinner.close();
        },
        (error) => {
          spinner.close();
          this.modalService.openModalDanger('Error', error);
        }
      );
    }
  }

  compareTobacco(o1: any, o2: any): boolean {
    return typeof o1 === 'object' && typeof o2 === 'object' && o1 !== null && o2 !== null && o1.id === o2.id && o1.value === o2.value;
  }
}
