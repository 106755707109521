import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
@Injectable({
  providedIn: 'root'
})
export class GenericRequestService {
  public url = environment.apiUrl;

  constructor(private http: HttpClient) {}

  get(endPoint) {
    // let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Basic ' + this.basicAuth });
    const headers = new HttpHeaders({ accept: '*/*' });
    const options = { headers };
    return this.http.get(this.url + endPoint, options);
  }

  post(endPoint, body?, headers?) {
    // let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Basic ' + this.basicAuth });
    if (!headers) {
      headers = new HttpHeaders({ accept: '*/*' });
    }
    const options = { headers, observe: 'response' as 'body' };
    // let bodyPost = JSON.stringify(body);
    return this.http.post<HttpResponse<any>>(this.url + endPoint, body, options);
  }

  patch(endPoint, id, body) {
    // let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Basic ' + this.basicAuth });
    const headers = new HttpHeaders({ accept: '*/*' });
    const options = { headers, observe: 'response' as 'body' };
    const bodyPost = JSON.stringify(body);
    this.http.patch<HttpResponse<any>>(this.url + endPoint + '/' + id, bodyPost, options);
  }

  put(endPoint, id?, body?, headers?) {
    // let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Basic ' + this.basicAuth });
    if (!headers) {
      headers = new HttpHeaders({ accept: '*/*' });
    }
    const options = { headers, observe: 'response' as 'body' };
    const bodyPost = JSON.stringify(body);
    let url = this.url + endPoint;
    if (id) {
      url = this.url + endPoint + '/' + id;
    }
    return this.http.put<HttpResponse<any>>(url, bodyPost, options);
  }

  delete(endPoint, headers?, responseType?) {
    if (!headers) {
      headers = new HttpHeaders({ accept: '*/*' });
    }
    const options: any = { headers };
    if (responseType) {
      options.responseType = responseType;
    }

    return this.http.delete(this.url + endPoint, options);
  }
}
