/**
 * servicios menus y contenido
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: csgj@gft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Rates } from './rates';

export interface RatesElement {
  /**
   * b
   */
  id?: string;
  values?: Array<any>;
  children?: Array<Rates>;
}
export namespace RatesElement {
  export type ValuesEnum = 'en' | 'es';
  export const ValuesEnum = {
    En: 'en' as ValuesEnum,
    Es: 'es' as ValuesEnum
  };
}
