import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { CasesService } from '@app/core/services/api';
import { Product } from '@app/core/services/api/model/product.model';
import { ShoppingCart } from '@app/core/services/api/model/shopping-cart.model';
import { CaseDataService } from '@app/core/services/case-data/case-data.service';
import { ModalService } from '@app/core/services/modal/modal.service';
import { ShoppingCartService } from '@app/core/services/shopping-cart/shopping-cart.service';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { Subscription } from 'rxjs';
import { DOCTOR } from '../utils/commonMethods';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
  public numItems = 0;
  rates = [];
  idCase = '';
  suscripcion: Subscription;

  constructor(
    private router: Router,
    private caseSrv: CaseDataService,
    private authSrv: AuthenticationService,
    private loaderSrv: SpinnerService,
    private modalService: ModalService,
    private shoppingCartService: ShoppingCartService,
    private casesSrv: CasesService
  ) {}

  ngOnInit() {
    this.shoppingCartService.get().subscribe((value: ShoppingCart) => {
      this.numItems = this.shoppingCartService.numItemsCart();
    });

    this.suscripcion = this.caseSrv.get().subscribe({
      next: (c) => {
        if (c) {
          this.idCase = c.idCase;
        }
      }
    });

    this.suscripcion = this.shoppingCartService.get().subscribe({
      next: (items) => {
        this.rates = items.items;
      }
    });
  }

  ngOnDestroy() {
    this.suscripcion.unsubscribe();
  }
  showDetailCaseInCourse() {
    this.router.navigate(['case-detail', this.idCase]);
  }

  assignCaseInCourse() {
    const loader = this.loaderSrv.showSpinner();
    const body = {
      idCase: this.idCase,
      rates: this.rates.map((item) => {
        item.id = item.id.replace('cart_', '');
        return { idRate: item.id };
      })
    };
    this.casesSrv.addratescasePost(body).subscribe({
      next: (resp) => {
        // console.log(resp);
        loader.close();
        this.emptyRates();
      }
    });
  }

  openCase() {
    this.modalService
      .openModalReopenCase(this.idCase)
      .afterClosed()
      .subscribe({
        next: (response) => {
          this.casesSrv.openCasePost(response).subscribe({
            next: (rsp) => {
              this.caseSrv.checkCase();
              this.caseSrv.setChangeState(rsp);
            }
          });
          const loader = this.loaderSrv.showSpinner();
          this.caseSrv.remove();
          loader.close();
        }
      });
  }

  goRates() {}
  emptyRates() {
    this.shoppingCartService.empty();
  }

  hasCaseInCourse() {
    return this.caseSrv.hasInCourse();
  }

  isAllowed() {
    return !this.authSrv.checkRolUser('Consulta');
  }

  goToWorkArea() {
    this.router.navigate(['/work-area']);
  }

  removeToCart(p: Product): void {
    this.shoppingCartService.removeItem(p);
  }

  isDoctorRole() {
    return this.authSrv.checkRolUser(DOCTOR);
  }
}
