import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { CartItem } from '../api/model/cart-item.model';
import { Product } from '../api/model/product.model';
import { ShoppingCart } from '../api/model/shopping-cart.model';
import { LocalStorageServie, StorageService } from '../storage/storage.service';

const CART_KEY = 'cart';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {
  private storage: Storage;
  private subscriptionObservable: Observable<ShoppingCart>;
  private subscribers: Array<Observer<ShoppingCart>> = new Array<Observer<ShoppingCart>>();
  // private products: Product[];
  // private deliveryOptions: DeliveryOption[];

  public constructor(
    private storageService: LocalStorageServie // private productService: ProductsDataService,
  ) /* private deliveryOptionsService: DeliveryOptionsDataService*/ {
    this.storage = this.storageService.get();
    // this.productService.all().subscribe((products) => this.products = products);
    // this.deliveryOptionsService.all().subscribe((options) => this.deliveryOptions = options);

    this.subscriptionObservable = new Observable<ShoppingCart>((observer: Observer<ShoppingCart>) => {
      this.subscribers.push(observer);
      observer.next(this.retrieve());
      return () => {
        this.subscribers = this.subscribers.filter((obs) => obs !== observer);
      };
    });
  }

  public get(): Observable<ShoppingCart> {
    return this.subscriptionObservable;
  }

  public addItem(product: Product): void {
    const cart = this.retrieve();

    // Verifica si el producto tiene un ID que comienza con 'cart_CALC_MET'
    if (product.id.startsWith('cart_CALC_MET')) {
      // Filtra los productos que NO comiencen con 'cart_CALC_MET'
      const filteredItems = cart.items.filter(p => !p.id.startsWith('cart_CALC_MET'));
      // Reemplaza los items del carrito con el array filtrado
      cart.items = filteredItems;
    }

    let item = cart.items.find((p) => p.id === product.id);
    if (item === undefined) {
      item = new CartItem({});
      item.updateFrom(product);
      cart.items.push(item);
    }

    this.save(cart);
    this.dispatch(cart);
  }
  public removeItem(product: Product): void {
    const cart = this.retrieve();
    const itemIndex = cart.items.findIndex((p) => p.id === product.id);
    if (itemIndex !== -1) {
      cart.items.splice(itemIndex, 1);
    }

    this.save(cart);
    this.dispatch(cart);
  }

  public empty(): void {
    const newCart = new ShoppingCart();
    this.save(newCart);
    this.dispatch(newCart);
  }

  /* public setDeliveryOption(deliveryOption: DeliveryOption): void {
      const cart = this.retrieve();
      cart.deliveryOptionId = deliveryOption.id;
      this.calculateCart(cart);
      this.save(cart);
      this.dispatch(cart);
    } */

  /* private calculateCart(cart: ShoppingCart): void {
       cart.itemsTotal = cart.items
           .map((item) => item.quantity * item.price)
           .reduce((previous, current) => previous + current, 0);
      cart.deliveryTotal = cart.deliveryOptionId ?
           this.deliveryOptions.find((x) => x.id === cart.deliveryOptionId).price :
           0;
       cart.grossTotal = cart.itemsTotal + cart.deliveryTotal;

       cart.grossTotal = cart.itemsTotal;
   }*/

  private retrieve(): ShoppingCart {
    const cart = new ShoppingCart();
    const storedCart = this.storage.getItem(CART_KEY);
    if (storedCart) {
      cart.updateFrom(JSON.parse(storedCart));
    }

    return cart;
  }

  public save(cart: ShoppingCart): void {
    this.storage.setItem(CART_KEY, JSON.stringify(cart));
  }

  private dispatch(cart: ShoppingCart): void {
    this.subscribers.forEach((sub) => {
      try {
        sub.next(cart);
      } catch (e) {
        // we want all subscribers to get the update even if one errors.
      }
    });
  }

  public isInCart(id: string): boolean {
    return this.retrieve().items.find((p) => id === p.id) !== undefined;
  }

  public numItemsCart(): number {
    return this.retrieve().items?.length;
  }
}
