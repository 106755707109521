import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { CedentesService } from '../api';
import { ICedente } from '../api/model/cedente.model';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({ providedIn: 'root' })
export class CedenteService {
  cedentes: ICedente[] = [];
  cedenteSubject$: BehaviorSubject<ICedente[]> = new BehaviorSubject<ICedente[]>(null);

  constructor(private cedenteAPISrv: CedentesService, private loaderSrv: SpinnerService) {}

  getCedentes() {
    if (!this.cedentes.length) {
      this.cedenteAPISrv.cedentesGet().subscribe({
        next: (response: ICedente[]) => {
          this.cedentes = [...response];
          this.cedenteSubject$.next(response);
        },
        error: (error) => console.log(error)
      });
    }
  }

  clearCedentes() {
    this.cedentes = [];
    this.cedenteSubject$.next([]);
  }
}
