/**
 * servicios menus y contenido
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: csgj@gft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Createtarifa {
  riesgo?: string;
  orden?: number;
  descripcion?: Array<Createtarifa.DescripcionEnum>;
  nivel?: number;
  cobertura?: Array<Createtarifa.CoberturaEnum>;
}
export namespace Createtarifa {
  export type DescripcionEnum = 'es' | 'en';
  export const DescripcionEnum = {
    Es: 'es' as DescripcionEnum,
    En: 'en' as DescripcionEnum
  };
  export type CoberturaEnum = 'cobertura' | 'cobertura2';
  export const CoberturaEnum = {
    Cobertura: 'cobertura' as CoberturaEnum,
    Cobertura2: 'cobertura2' as CoberturaEnum
  };
}
