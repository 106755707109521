import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { fadeAnimation } from '../utils/commonMethods';

@Component({
  selector: 'app-workarea-filter',
  templateUrl: './workarea-filter.component.html',
  styleUrls: ['./workarea-filter.component.scss'],
  animations: [fadeAnimation]
})
export class WorkareaFilterComponent implements OnInit {
  @Output() search = new EventEmitter<any>();
  @Output() newCase = new EventEmitter<any>();
  idCase: string;
  referenceNumber: string;
  startDate: string;
  endDate: string;
  state: number | undefined;
  showCommonFilter = false;

  constructor(private _authSrv: AuthenticationService) {}

  ngOnInit() {
    this.idCase = '';
    this.referenceNumber = '';
    this.startDate = '';
    this.endDate = '';
    this.state = undefined;
  }

  showFilter() {
    this.showCommonFilter = !this.showCommonFilter;
  }

  hideFilter() {
    this.showCommonFilter = !this.showCommonFilter;
  }

  updateData(data: any) {
    this.idCase = data.idCase.length ? data.idCase : '';
    this.referenceNumber = data.referenceNumber.length ? data.referenceNumber : '';
    this.startDate = data.startDate !== null && typeof data.startDate === 'object' ? data.startDate.toISOString() : '';
    this.endDate = data.endDate !== null && typeof data.endDate === 'object' ? data.endDate.toISOString() : '';
  }

  commonFilter(data) {
    this.idCase = data.idCase;
    this.referenceNumber = data.referenceNumber;
    this.startDate = data.startDate ? data.startDate.toISOString() : '';
    this.endDate = data.endDate ? data.endDate.toISOString() : '';
    this.searchFilter();
  }

  navTabFilter(state?: any) {
    if (typeof state === 'number') {
      this.state = state;
    } else {
      this.state = undefined;
      this.idCase = '';
      this.referenceNumber = '';
      this.startDate = '';
      this.endDate = '';
    }
    this.searchFilter();
  }
  checkDoctor() {
    return this._authSrv.checkRolUser('Médico');
  }
  searchFilter() {
    if (this.showCommonFilter) {
      this.hideFilter();
    }
    this.search.emit({
      idCase: this.idCase,
      referenceNumber: this.referenceNumber,
      startDate: this.startDate,
      endDate: this.endDate,
      state: this.state
    });
  }

  createCase() {
    this.newCase.emit();
  }
}
