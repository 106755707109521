import * as actions from './language.actions';
import { LanguageState } from './language.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const initialState: LanguageState = {
  language: null,
  changeLanguageSuccess: false,
  downloadSuccess: false,
  updateSuccess: false
};

export function languageReducer(state = initialState, action: actions.languageActions) {
  switch (action.type) {
    case actions.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language
      };

    case actions.CHANGE_LANGUAGE_RESPONSE:
      return {
        ...state,
        changeLanguageSuccess: action.response
      };

    case actions.DONWOLOAD_LANGUAGE_RESPONSE:
      return {
        ...state,
        downloadSuccess: action.response
      };

    case actions.UPDATE_LANGUAGE_RESPONSE:
      return {
        ...state,
        updateSuccess: action.response
      };

    default:
      return state;
  }
}

// Create default selectors
export const getLanguageState = createFeatureSelector<LanguageState>('language');

export const getLanguage = createSelector(getLanguageState, (state: LanguageState) => state.language);

export const changeLanguageResponse = createSelector(getLanguageState, (state: LanguageState) => state.changeLanguageSuccess);

export const downloadLanguageResponse = createSelector(getLanguageState, (state: LanguageState) => state.downloadSuccess);

export const updateLanguageResponse = createSelector(getLanguageState, (state: LanguageState) => state.updateSuccess);
