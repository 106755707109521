import { Injectable } from '@angular/core';
import { LanguageService } from '@services/language/language.service';
import { mergeMap, tap } from 'rxjs/operators';
import { ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromLanguage from '@store/language/language.reducer';
import { ERRORS } from '@global/constants';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationErrorService {
  // Ejemplo de mensajes de error
  private readonly genericError = this.translate.instant('ERROR.GENERIC');
  private messages = {
    required: this.translate.instant('ERROR.REQUIRED'),
    phoneValidator: this.translate.instant('ERROR.PHONE'),
    pattern: this.translate.instant('ERROR.INVALID-PASSWORD')
  };

  constructor(private readonly languageService: LanguageService, private readonly translate: TranslateService, private readonly store: Store) {
    this.getTranslatedMessages().subscribe();
  }

  private getTranslatedMessages() {
    return this.store
      .select(fromLanguage.changeLanguageResponse)
      .pipe(mergeMap(() => this.languageService.get(ERRORS).pipe(tap((response) => (this.messages = response)))));
  }

  getMessage(error: ValidationErrors): string {
    const key = error ? Object.keys(error)[0] : null;
    const requiredValue = key ? this.getRequiredValue(error) : null;
    const message = requiredValue ? this.replace(this.messages[key], requiredValue) : this.messages[key];

    return !message ? this.genericError : message;
  }

  private getRequiredValue(error: ValidationErrors) {
    let length;

    if (error.min) {
      length = error.min.min;
    } else if (error.max) {
      length = error.max.max;
    } else if (error.minlength) {
      length = error.minlength.requiredLength;
    } else if (error.maxlength) {
      length = error.maxlength.requiredLength;
    }
    return length;
  }

  private replace(message: string, param: string | number): string {
    return message.replace('{{ value }}', param.toString());
  }
}
