import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CedenteService } from '@app/core/services/cedente/cedente.service';

@Component({
  selector: 'app-modal-select-cedente',
  templateUrl: './modal-select-cedente.component.html',
  styleUrls: ['./modal-select-cedente.component.scss']
})
export class ModalSelectCedenteComponent implements OnInit {
  cedente: FormControl;
  constructor(
    public dialogRef: MatDialogRef<ModalSelectCedenteComponent>,
    public cedenteSrv: CedenteService,
    @Inject(MAT_DIALOG_DATA) public data: { cedente?: number }
  ) {}

  ngOnInit() {
    this.cedente = new FormControl('', []);
  }

  sendCedente() {
    if (this.cedente.valid) {
      this.dialogRef.close(this.cedente.value.key);
    }
  }

  close() {
    this.dialogRef.close();
  }
}
