import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from '@environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: `https://login.microsoftonline.com/${environment.tentantId}/v2.0`,
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.appId,
        scope: `openid profile email offline_access user.read`,
        responseType: 'code',
        silentRenew: true,
        maxIdTokenIatOffsetAllowedInSeconds: 600,
        issValidationOff: false,
        secureRoutes: [
          'http://localhost:4200/',
          'http://localhost:8080/',
          'http://localhost:8081/',
          'http://localhost:8082/',
          environment.apiUrl
        ],
        autoUserInfo: false,
        useRefreshToken: true,
        triggerAuthorizationResultEvent: false,
        unauthorizedRoute: '/unauthorized',
        historyCleanupOff: false
      }
    })
  ],
  exports: [AuthModule]
})
export class AuthConfigModule { }
