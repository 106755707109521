import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICurrency } from '@app/core/services/api/model/iCurrency';

@Component({
  selector: 'app-detail-case-basic-print',
  templateUrl: './detail-case-basic-print.component.html',
  styleUrls: ['./detail-case-basic-print.component.scss'],
})
export class DetailCaseBasicPrintComponent {
  @Input() data = null;
  @Input() canEdit = false;
  @Input() state: number;
  @Input() showReference = true;
  @Output() saveChange: EventEmitter<any> = new EventEmitter();
  displayedColumns: string[] = ['coverage', 'capital'];
  notHasChanges = true;
  editMode = false;
  countries: any[];
  coverages: any[];
  coveragesInCase: any[] = [];
  currencies: ICurrency[];
  currenciesMap: string[];

  saveData() {
    this.edit();
    this.saveChange.emit();
  }

  detectChange(): void {
    if (this.notHasChanges) {
      this.notHasChanges = false;
    }
  }

  isInBlackList(key: string) {
    return this.coveragesInCase.find((item) => item.key === key) !== undefined;
  }

  edit() {
    this.editMode = !this.editMode;
  }
}
