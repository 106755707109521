import { environment } from '@environments/environment';

const BASE_URL = '/mapfre';
const API_URI = '/api/v1';

export const REGISTER_ACTIVITY = 'https://ts6khlq345.execute-api.eu-west-1.amazonaws.com/test/activities';
export const UPDATE_REGISTER_ACTIVITY = '/update-session?id=$1';
export const REGISTER_ACTIVITY_COMPONENT = '/$1/component-event';
export const REGISTER_ACTIVITY_PAGE = '/$1/page-event';
export const BUSINESS_RULES = '/business-rules';

export const GET_ENDPOINT = (url: string, params?: string[]) => {
  let uri = `${environment.apiUrl}${BASE_URL}${API_URI}${url}`;
  params &&
    params.forEach((param, index) => {
      uri = uri.replace(`$${index + 1}`, param);
    });
  return uri;
};
