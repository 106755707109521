/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-const */
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { CalcValidators } from '@app/core/directives/validators/calc-validators';
import { InlineResponse20017, Mensaje, MetabolicoService } from '@app/core/services/api';
import { ModalService } from '@app/core/services/modal/modal.service';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-metabolic-calc-kidney-profile',
  templateUrl: './metabolic-calc-kidney-profile.component.html',
  styleUrls: ['./metabolic-calc-kidney-profile.component.scss']
})
export class MetabolicCalcKidneyProfileComponent implements OnInit {
  @Input() data;

  creatinineMeasures: any[] = ['mg/dl', 'µmol/dl'];
  sinceDiagnosedOptions: any[] = [
    {
      id: '< 1 año',
      label: 'METABOLIC-CALC.SINCE-DIAGNOSIS-OPTIONS.OPTION1'
    },
    {
      id: 'Entre 2-3 años',
      label: 'METABOLIC-CALC.SINCE-DIAGNOSIS-OPTIONS.OPTION2'
    },
    {
      id: '> 3 años',
      label: 'METABOLIC-CALC.SINCE-DIAGNOSIS-OPTIONS.OPTION3'
    }
  ];
  // sinceDiagnosedOptions: any[] = ['< 1 año', 'Entre 2-3 años', '> 3 años'];

  /*
  creatinineMessage = '';
  albuminuriaMessage = '';
  proteinuriaMessage = '';
  ERCMessage = '';
  sinceDiagnosedMessage = '';
  */

  kidneyProfile: FormGroup = new FormGroup({
    creatinine: new FormControl(),
    creatinineMeasure: new FormControl(),
    albuminuria: new FormControl(),
    proteinuria: new FormControl(),
    uricAcid: new FormControl(),
    treatmentUricAcid: new FormControl(),
    GFR: new FormControl(),
    ERC: new FormControl({ disabled: true, value: '' }),
    sinceDiagnosed: new FormControl(),
    urea: new FormControl()
  });

  constructor(
    private formGroupDirective: FormGroupDirective,
    private spinnerService: SpinnerService,
    private metabolicoService: MetabolicoService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    if (this.formGroupDirective.form) {
      this.formGroupDirective.form.setControl('kidneyProfile', this.kidneyProfile);
    }

    this.kidneyProfile.controls.uricAcid.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if (value && value !== ''){
        this.kidneyProfile.controls.uricAcid.clearValidators();
        this.addUricAcidValidator();
        this.kidneyProfile.controls.uricAcid.updateValueAndValidity();
      } else {
        this.kidneyProfile.controls.uricAcid.clearValidators();
        this.kidneyProfile.controls.uricAcid.updateValueAndValidity();
      }
    });
    this.kidneyProfile.controls.creatinine.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      // this.calcCreatine();
      if (value) {
        this.kidneyProfile.controls.creatinine.clearValidators();
        this.kidneyProfile.controls.creatinine.addValidators([CalcValidators.creatinine()]);
        this.kidneyProfile.controls.creatinine.updateValueAndValidity();

        this.kidneyProfile.controls.creatinineMeasure.clearValidators();
        this.kidneyProfile.controls.creatinineMeasure.addValidators(Validators.required);
        this.kidneyProfile.controls.creatinineMeasure.updateValueAndValidity();
      } else {
        this.kidneyProfile.controls.creatinineMeasure.clearValidators();
        this.kidneyProfile.controls.creatinineMeasure.updateValueAndValidity();
      }
    });
    this.kidneyProfile.controls.GFR.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.calcGFR();
    });
    /*
    this.kidneyProfile.controls.creatinineMeasure.valueChanges.subscribe(() => {
      this.calcCreatine();
    });
    this.kidneyProfile.controls.albuminuria.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.calcAlbuminuria();
    });
    this.kidneyProfile.controls.proteinuria.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.calcProteinuria();
    });

    this.kidneyProfile.controls.sinceDiagnosed.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.calcSinceDiagnosed();
    });
    */
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit(): void {
    if (this.data) {
      this.kidneyProfile.patchValue(this.data);
    }
  }

  calcGFR() {
    if (this.kidneyProfile.controls.GFR.value && this.kidneyProfile.controls.GFR.valid) {
      const body = this.formGroupDirective?.form.getRawValue();
      delete body.basicData?.birthDate;
      let loading = this.spinnerService.showSpinner();
      this.metabolicoService.nivelGFRPost(body).subscribe(
        (response: Mensaje) => {
          // this.ERCMessage = response.text;
          // this.kidneyProfile.controls.ERC.setValue(response.value);
          if (response.value && typeof response.value === 'object') {
            this.kidneyProfile.controls.ERC.setValue((response.value as any).erc);
            loading.close();
          } else {
            this.kidneyProfile.controls.ERC.setValue(response.value);
            loading.close();
          }
        },
        (error) => {
          this.modalService.openModalDanger('Error', error);
          loading.close();
        }
      );
    } else {
      this.kidneyProfile.controls.ERC.setValue('');
    }
  }

  addUricAcidValidator() {
    this.kidneyProfile.controls.uricAcid.addValidators([CalcValidators.uricAcid(0, 50)]);
  }

  compareSignedDiagnosed(o1: any, o2: any): boolean {
    return JSON.stringify(o1) === JSON.stringify(o2) && o1.id === o2.id;
  }
  /* Comentado tras la revision del documento metabolico V8.1
  calcCreatine() {
    if (
      this.kidneyProfile.controls.creatinine.value &&
      this.kidneyProfile.controls.creatinine.valid &&
      this.kidneyProfile.controls.creatinineMeasure.value
    ) {
      const body = this.formGroupDirective?.form.getRawValue();
      delete body.basicData?.birthDate;
      this.metabolicoService.creatininePost(body).subscribe(
        (response: Mensaje) => {
          this.creatinineMessage = response.text;
        },
        (error) => {
          this.modalService.openModalDanger('Error', error);
        }
      );
    } else {
      this.creatinineMessage = '';
    }
  }

  // Revisar Lambda con Santi para comprobar utilidad
  calcAlbuminuria() {
    if (this.kidneyProfile.controls.albuminuria.value && this.kidneyProfile.controls.albuminuria.valid) {
      const body = this.formGroupDirective?.form.getRawValue();
      delete body.basicData?.birthDate;
      this.metabolicoService.albuminuriaPost(body).subscribe(
        (response: Mensaje) => {
          this.albuminuriaMessage = response.text;
        },
        (error) => {
          this.modalService.openModalDanger('Error', error);
        }
      );
    }
  }

  calcProteinuria() {
    if (this.kidneyProfile.controls.proteinuria.value && this.kidneyProfile.controls.proteinuria.valid) {
      const body = this.formGroupDirective?.form.getRawValue();
      delete body.basicData?.birthDate;
      this.metabolicoService.proteinuriaPost(body).subscribe(
        (response: Mensaje) => {
          this.proteinuriaMessage = response.text;
        },
        (error) => {
          this.modalService.openModalDanger('Error', error);
        }
      );
    }
  }
  */

  /*
  calcSinceDiagnosed() {
    //hay que obtener el sexo
    if (this.kidneyProfile.controls.sinceDiagnosed.value && this.kidneyProfile.controls.sinceDiagnosed.valid) {
      let loading = this.spinnerService.showSpinner();
      setTimeout(() => {
        this.sinceDiagnosedMessage = 'METABOLIC-CALC.SINCE-DIAGNOSED-MESSAGE';
        loading.close();
      }, 500);
    }
  }
  */
  /*
  getComboGFR() {
     let spinner = this.spinnerService.showSpinner();
    this.metabolicoService.nivelGFRGet().subscribe((response: InlineResponse20017[] ) => {
      this.proteinuriaMessage = response.text;
      spinner.close();
    }, error => {
      spinner.close();
      this.modalService.openModalDanger('Error', error);
    });
  }
  */
}
