export enum LEVEL {
  debug,
  info,
  warn,
  error,
  fatal
}

export class GlobalError {
  statusCode: number;
  message: string;
  level: LEVEL;
  error: any;

  constructor(statusCode: number, message: string, level: LEVEL, error: any) {
    this.statusCode = statusCode;
    this.message = message;
    this.level = level;
    this.error = error;
  }
}
