import { Action } from '@ngrx/store';

export const CHANGE_LANGUAGE = '[LANGUAGE] CHANGE_LANGUAGE';
export const CHANGE_LANGUAGE_RESPONSE = '[LANGUAGE] CHANGE_LANGUAGE_RESPONSE';

export const UPDATE_LANGUAGE = '[LANGUAGE] UPDATE_LANGUAGE';
export const UPDATE_LANGUAGE_RESPONSE = '[LANGUAGE] UPDATE_LANGUAGE_RESPONSE';

export const DONWOLOAD_LANGUAGE = '[LANGUAGE]  DONWOLOAD_LANGUAGE';
export const DONWOLOAD_LANGUAGE_RESPONSE = '[LANGUAGE]  DONWOLOAD_LANGUAGE_RESPONSE';

export class ChangeLanguage implements Action {
  readonly type = CHANGE_LANGUAGE;
  constructor(public language: string) {}
}

export class UpdateLanguage implements Action {
  readonly type = UPDATE_LANGUAGE;
  constructor(public url: string, public localVersion?: string) {}
}

export class DownloadLanguage implements Action {
  readonly type = DONWOLOAD_LANGUAGE;
  constructor(public url: string) {}
}

export class ChangeLanguageResponse implements Action {
  readonly type = CHANGE_LANGUAGE_RESPONSE;
  constructor(public response: boolean) {}
}

export class DownloadLanguageResponse implements Action {
  readonly type = DONWOLOAD_LANGUAGE_RESPONSE;
  constructor(public response: boolean) {}
}

export class UpdateLanguageResponse implements Action {
  readonly type = UPDATE_LANGUAGE_RESPONSE;
  constructor(public response: boolean) {}
}
export type languageActions =
  | ChangeLanguage
  | UpdateLanguage
  | DownloadLanguage
  | UpdateLanguageResponse
  | DownloadLanguageResponse
  | ChangeLanguageResponse;
