import { Component, Input, OnInit } from '@angular/core';

interface Treatment {
  name: string;
  ingredients: string[];
  state: boolean;
}

@Component({
  selector: 'app-metabolic-calc-results-treatment',
  templateUrl: './metabolic-calc-results-treatment.component.html',
  styleUrls: ['./metabolic-calc-results-treatment.component.scss']
})
export class MetabolicCalcResultsTreatmentComponent implements OnInit {
  @Input('treatmentList') treatmentList: Treatment[] = [];
  @Input('title') title: string;

  constructor() {}

  ngOnInit() {}
}
