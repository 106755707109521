import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { RequestService } from '@services/request/request.service';
import { map, catchError, mapTo } from 'rxjs/operators';
import { VERSION } from '@global/constants';
import { DateAdapter } from '@angular/material/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly requestService: RequestService,
    private dateAdapter: DateAdapter<any>,
    @Inject(LOCALE_ID) public locale: string
  ) {
    if (localStorage.getItem('langSelect') !== null && localStorage.getItem('langSelect') !== undefined) {
      this.translateService.use(localStorage.getItem('langSelect'));
      this.dateAdapter.setLocale(localStorage.getItem('langSelect'));
      this.locale = localStorage.getItem('langSelect');
    } else {
      const browserLang = this.getBrowserPreferredLanguageCode('es') // this.translateService.getBrowserLang();
      this.translateService.setDefaultLang(browserLang);
      this.translateService.use(browserLang);
      this.dateAdapter.setLocale(browserLang);
      this.locale = localStorage.getItem(browserLang);
    }
  }

  // Returns two digits language code from browser preferences,
  // try to find the first occurrence on language code array list from browser preferences
  // if browser language is not supported returns defaultLang

  getBrowserPreferredLanguageCode(defaultLang: string): string {

    const navigatorLanguages = navigator.languages;
    const supportedLanguages = ['es', 'en', 'fr', 'pt'];

    const preferredLanguage = navigatorLanguages.find(lang => supportedLanguages.includes(lang.slice(0, 2)));

    return preferredLanguage ? preferredLanguage.slice(0, 2) : defaultLang;

  }


  changeLanguage(language: string): Observable<boolean> {
    return this.translateService.use(language).pipe(
      mapTo(true),
      catchError((error) => of(false))
    );
  }

  // tslint:disable-next-line: no-any
  updateLanguageVersion(currentLang: string, url: string, localFileVersion?: string): Observable<{}> {
    const request = this.requestService.get(url);

    return request.pipe(
      map((json) => {
        const downloadedFileVersion = json[VERSION];
        const localVersion = localFileVersion ? localFileVersion : this.translateService.instant(VERSION);

        if (this.isServerVersionHigher(localVersion, downloadedFileVersion)) {
          this.translateService.setTranslation(currentLang, json, true);
          return json;
        }
        return {};
      })
    );
  }

  downLoadLanguage(currentLang: string, url: string): Observable<{}> {
    return this.updateLanguageVersion(currentLang, url, '0.0.0');
  }

  // tslint:disable-next-line: no-any
  get(key: string | string[], interpolateParams?: Object): Observable<any> {
    return this.translateService.get(key, interpolateParams);
  }

  /**
   *
   *
   * @param localVersion The first version to be compared.
   * @param serverVersion The second version to be compared.
   *
   * @returns
   *    0 versions are equal
   *   -1 integer localVersion < serverVersion
   *    1 integer localVersion > serverVersion
   */
  private isServerVersionHigher(localVersion: string, serverVersion: string) {
    const local: number = localVersion ? Number(localVersion.split('.').join('')) : 0;
    const server: number = serverVersion ? Number(serverVersion.split('.').join('')) : 0;

    return !local || local < server;
  }
}
