import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CasesService, SearchcaseService } from '@app/core/services/api';
import { ModalService } from '@app/core/services/modal/modal.service';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { instanceOfErrAttribute } from '../utils/commonMethods';

@Component({
  selector: 'app-modal-similar-pricing',
  templateUrl: './modal-similar-pricing.component.html',
  styleUrls: ['./modal-similar-pricing.component.scss']
})
export class ModalSimilarPricingComponent implements OnInit {
  dataSource: any;
  consultView: boolean;
  basicData: any;
  risksFees: any;
  form: FormGroup;
  state;

  constructor(
    public dialogRef: MatDialogRef<ModalSimilarPricingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { idCase: string },
    private casesSrv: CasesService,
    private searchCase: SearchcaseService,
    private loaderSrv: SpinnerService,
    private translateSrv: TranslateService,
    private modalSrv: ModalService
  ) {}

  ngOnInit() {
    // console.log(this.data.idCase);
    this.consultView = false;
    this.form = new FormGroup({});
    this.getData();
  }

  getData() {
    const loader = this.loaderSrv.showSpinner();
    this.searchCase.similaryHistoryGet(this.data.idCase).subscribe({
      next: (data) => {
        loader.close();
        if (!instanceOfErrAttribute(data)) {
          if ((data as any).Items.length === 0) {
            this.modalSrv.openModalInfo('', 'MODAL.SIMILAR-PRICING.INFO.NO-CASE');
          } else {
            this.dataSource = [...(data as any).Items];
          }
        } else {
          this.modalSrv.openModalDanger('ERROR.ERROR', '');
        }
      },
      error: (error) => {
        loader.close();
      }
    });
  }

  consultCase(idCase: string) {
    const loader = this.loaderSrv.showSpinner();
    forkJoin([
      this.casesSrv.consultCaseGet(idCase, this.translateSrv.currentLang),
      this.searchCase.searchcaseGet(idCase, undefined, undefined, undefined, undefined)
    ]).subscribe(([caseData, searchCase]: any) => {
      this.basicData = {
        basicData: caseData.basicData,
        coveragesContract: caseData.coveragesContract
      };
      this.risksFees = caseData.risksFees;
      this.state = searchCase.cases[0].state;
      loader.close();
      this.showDetailCase();
    });
  }

  showDetailCase() {
    this.consultView = !this.consultView;
  }

  backToListCases() {
    this.showDetailCase();
  }

  close() {
    this.dialogRef.close();
  }
}
