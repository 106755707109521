import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { CoveragesService, RegistercaseBody, RegistercaseService } from '@app/core/services/api';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import moment from 'moment';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { instanceOfError } from '../utils/commonMethods';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-modal-new-case',
  templateUrl: './modal-new-case.component.html',
  styleUrls: ['./modal-new-case.component.scss']
})
export class ModalNewCaseComponent implements OnInit {
  newCaseForm: FormGroup;
  coverages: any[];
  countries: any[];
  minDate: Date;
  maxDate: Date;
  coveragesInCase: any[];
  currenciesMap: string[];

  constructor(
    public dialogRef: MatDialogRef<ModalNewCaseComponent>,
    private coverageSrv: CoveragesService,
    private createCaseSrv: RegistercaseService,
    private loaderSrv: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: { cedente?: number }
  ) { }

  ngOnInit() {
    this.coveragesInCase = [];
    const today = new Date();
    this.minDate = new Date(today.getFullYear() - 110, 0, 1);
    this.maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDay());
    const loader = this.loaderSrv.showSpinner();
    forkJoin([this.coverageSrv.coveragesGet()]).subscribe(
      ([coverageData]) => {
        this.coverages = [...coverageData];
        loader.close();
      },
      (error) => loader.close()
    );
    this.newCaseForm = new FormGroup({
      referenceNumber: new FormControl('', [Validators.required]),
      birthDate: new FormControl('', [Validators.required]),
      age: new FormControl({ disabled: true, value: '' }, [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      location: new FormControl('', [Validators.required]),
      duration: new FormControl('', [Validators.required]),
      coverages: new FormArray([])
    });
    this.addCoverage();
    this.newCaseForm.controls['coverages'].valueChanges.subscribe({
      next: (value) => {
        this.coveragesInCase = value.map((item) => ({ key: item.coverage }));
      }
    });
  }

  isRequired(control: AbstractControl) {
    try {
      return control.hasValidator(Validators.required);
    } catch (error) {
      return false;
    }
  }

  isInBlackList(key: string) {
    return this.coveragesInCase.find((item) => item.key === key) !== undefined;
  }

  getCoverageTranslate(key: string) {
    return `RATE.COBERTURAS.${key}`;
  }

  addCoverage() {
    (this.newCaseForm.controls['coverages'] as FormArray).push(
      new FormGroup({
        coverage: new FormControl('', [Validators.required]),
        capital: new FormControl('', [Validators.required]),
        currency: new FormControl('', [Validators.required])
      })
    );
  }

  deleteCoverage(index: number) {
    (this.newCaseForm.controls['coverages'] as FormArray).removeAt(index);
  }

  calcAge(data: MatDatepickerInputEvent<Date>) {
    const date = moment(data.value);
    this.newCaseForm.get('age').setValue(moment().year() - date.year());
  }

  close() {
    this.dialogRef.close();
  }

  createCase() {
    const loader = this.loaderSrv.showSpinner();

    let body: RegistercaseBody;
    if (this.data.cedente) {
      body = {
        _case: { ...this.newCaseForm.getRawValue() },
        cedente: this.data.cedente
      };
    } else {
      body = { ...this.newCaseForm.getRawValue() };
    }
    // console.log(body);
    this.createCaseSrv.registercasePost(body).subscribe(
      (response: any) => {
        loader.close();
        if (instanceOfError(response)) {
          this.dialogRef.close(response);
        } else {
          this.dialogRef.close(response.newCase);
        }
      },
      (error) => loader.close()
    );
  }
}
