/**
 * servicios menus y contenido
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: csgj@gft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ConsultCaseBasicData } from './consultCaseBasicData';
import { ConsultCaseComments } from './consultCaseComments';
import { ConsultCaseCoveragesContract } from './consultCaseCoveragesContract';
import { ConsultCaseRisksFees } from './consultCaseRisksFees';
interface IResult {
  title: string;
  value: string;
}

export interface ConsultCase {
  basicData?: ConsultCaseBasicData;
  coveragesContract?: ConsultCaseCoveragesContract;
  finalResult?: {
    result: IResult[];
    resultComments: ConsultCaseComments[];
  };
  finalComments?: {
    date: string;
    email: string;
    comment: string;
  };
  risksFees?: ConsultCaseRisksFees;
  comments?: Array<ConsultCaseComments>;
}
