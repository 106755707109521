import { Injectable } from '@angular/core';
import { RequestService } from '@services/request/request.service';
import { tap, catchError, mapTo, map, mergeMap, concatMap } from 'rxjs/operators';
import { BehaviorSubject, concat, Observable, of } from 'rxjs';
import { Token, Jwt, TokenError } from './token-model';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { SpinnerService } from '../services/spinner/spinner.service';
import { Router } from '@angular/router';
import { LoginService } from '../services/api';
import { LoginInput } from '../services/api/model/loginInput';
import { Login } from '../services/api/model/login';
import { ModalService } from '../services/modal/modal.service';
import { MenuService } from '../services/menu/menu.service';
import { instanceOfError } from '@app/shared/utils/commonMethods';
import { RefreshTokenService } from '../services/api/api/refreshToken.service';
import { RefreshTokenInput } from '../services/api/model/refreshTokenInput';

@Injectable()
export class AuthenticationService {
  readonly TOKEN = 'COGNITO_TOKEN';
  readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  readonly ACCESS_TOKEN = 'ACCESS_TOKEN';
  readonly ROLES = 'SESSION_ROLE';

  private readonly tokenSubject: BehaviorSubject<string>;
  public authType: 'AZURE' | 'COGNITO';

  constructor(
    private readonly requestService: RequestService,
    public oidcSecurityService: OidcSecurityService,
    private spinnerService: SpinnerService,
    private loginService: LoginService,
    public router: Router,
    private modalService: ModalService,
    private menuSrv: MenuService,
    private refreshTokenSrv: RefreshTokenService
  ) {
    this.tokenSubject = new BehaviorSubject<string>(sessionStorage.getItem(this.TOKEN));
  }

  public get currentTokenValue(): string {
    if (this.authType === 'AZURE') {
      return this.oidcSecurityService.getIdToken();
    } else if (this.authType === 'COGNITO') {
      return this.tokenSubject.value;
    }
    return this.tokenSubject.value;
  }

  refreshToken(): Observable<Login> {
    return this.refreshTokenSrv.refreshTokenPost(this.getCognitoTokens()).pipe(tap((tokens: Login) => {
      this.storeTokens(tokens);
    }));
  }

  checkToken(token: string): { valid: boolean; expirationDate: number } | null {
    const decodedToken = this.decodeJwt(token);
    if (decodedToken) {
      return {
        valid: decodedToken.exp >= new Date().getTime() / 1000,
        expirationDate: decodedToken.exp < new Date().getTime() / 1000 ? -1 : decodedToken.exp
      };
    }
    return null;
  }

  localLogOut() {
    this.logOut();
  }

  globalLogOut(url: string): Observable<boolean> {
    return this.requestService.delete(url, { idToken: this.getRefreshToken() }).pipe(
      tap(() => {
        this.logOut();
      }),
      mapTo(true),
      catchError((error) => of(false))
    );
  }

  decodeJwt(token: string): Jwt {
    let jsonPayload = token;

    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url && base64Url.replace(/-/g, '+').replace(/_/g, '/');
      jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => {
            const str = `00${c.charCodeAt(0).toString(16)}`;
            return `%${str.slice(-2)}`;
          })
          .join('')
      );
    }

    return jsonPayload ? JSON.parse(jsonPayload) : jsonPayload;
  }

  getDomimainLogin(email: string) {
    try {
      return email.split('@')[1];
    } catch (error) {
      return '';
    }
  }

  isMapfreDomian(domain: string) {
    return domain?.toLocaleLowerCase().indexOf('mapfre') !== -1;
  }

  loginAzure() {
    this.oidcSecurityService.authorize();
  }

  setAzureToken(token: string) {
    sessionStorage.setItem(this.TOKEN, token);
    this.tokenSubject.next(token);
  }

  loginCognito(email: string, password: string) {
    const loading = this.spinnerService.showSpinner();
    const loginBody: LoginInput = {
      email,
      password
    };

    this.loginService
      .loginPost(loginBody)
      .pipe(
        concatMap((response: Login) => {
          if (response.jwtToken) {
            sessionStorage.setItem(this.TOKEN, response.jwtToken);
            this.storeTokens(response);
            this.tokenSubject.next(response.jwtToken);
            return this.loginService.rolUserGet();
          } else {
            return of([]);
          }
        })
      )
      .subscribe(
        (roles: string[] | any) => {
          if (!instanceOfError(roles)) {
            if (Array.isArray(roles) && roles.length) {
              console.log('Roles', roles);
              sessionStorage.setItem(this.ROLES, JSON.stringify({ userData: roles }));
              this.router.navigateByUrl('/home');
            } else {
              if (roles.body) {
                const error = JSON.parse(roles.body);
                this.modalService.openModalDanger('ERROR.LOGIN', error.err.key);
                console.error(error.err.key);
              } else {
                this.modalService.openModalDanger('ERROR.LOGIN', 'ERROR.LOGIN-MESSAGE');
              }
              sessionStorage.clear();
            }
          } else {
            this.modalService.openModalDanger('ERROR.LOGIN', 'ERROR.LOGIN-MESSAGE');
            sessionStorage.clear();
          }
          loading.close();
        },
        (err) => {
          loading.close();
          this.modalService.openModalDanger('ERROR.LOGIN', 'ERROR.LOGIN-MESSAGE');
          console.error(err);
        }
      );
  }

  getRoles(): string[] {
    if (sessionStorage.getItem(this.ROLES)) {
      return JSON.parse(sessionStorage.getItem(this.ROLES)).userData;
    } else {
      return [];
    }
  }

  checkRolUser(rol: string) {
    return this.getRoles().includes(rol);
  }

  clearLocalStorage() {
    const lan: string = localStorage.getItem('langSelect');
    localStorage.clear();
    if (lan) {
      localStorage.setItem('langSelect', lan);
    }
  }

  setUserRole(roles: string[]) {
    sessionStorage.setItem(this.ROLES, JSON.stringify({ userData: roles }));
    // this.roles.next(roles);
  }

  private logOut() {
    this.menuSrv.clearMenu();
    this.tokenSubject.next(null);
    this.clearSessionStorage();
    this.clearLocalStorage();
    // remove user from local storage and set current user to null
    const loader = this.spinnerService.showSpinner();
    if (this.authType === 'AZURE') {
      this.authType = undefined;
      this.oidcSecurityService.logoff();
    } else {
      loader.close();
      this.router.navigateByUrl('/login');
    }
  }

  private clearSessionStorage() {
    this.removeTokens();
  }

  private getRefreshToken() {
    if (this.authType === 'AZURE') {
      return this.oidcSecurityService.getRefreshToken();
    } else {
      return sessionStorage.getItem(this.REFRESH_TOKEN);
    }
  }

  public storeTokens(tokens: Login) {
    if (tokens.jwtToken) {
      this.tokenSubject.next(tokens.jwtToken);
      sessionStorage.setItem(this.TOKEN, tokens.jwtToken);
    }
    if (tokens.jwtRefresToken) {
      sessionStorage.setItem(this.REFRESH_TOKEN, tokens.jwtRefresToken);
    }
    if (tokens.jwtAccessToken) {
      sessionStorage.setItem(this.ACCESS_TOKEN, tokens.jwtAccessToken);
    }
  }

  public getCognitoTokens(): RefreshTokenInput {
    return {
      jwtToken: sessionStorage.getItem(this.TOKEN),
      jwtRefresToken: sessionStorage.getItem(this.REFRESH_TOKEN),
      jwtAccessToken: sessionStorage.getItem(this.ACCESS_TOKEN)
    }
  }

  private removeTokens() {
    sessionStorage.removeItem(this.TOKEN);
    sessionStorage.removeItem(this.REFRESH_TOKEN);
    sessionStorage.removeItem(this.ROLES);
  }
}
