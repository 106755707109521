import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

export interface IAutocompte {
  label: string;
  value: any;
}
export interface IRiskAutoComplete {
  label: string;
  link: any;
}

@Component({
  selector: 'app-maresel-input-autocomplete',
  templateUrl: './maresel-input-autocomplete.component.html',
  styleUrls: ['./maresel-input-autocomplete.component.scss']
})
export class MareselInputAutocompleteComponent implements OnInit {
  @Input() label = '';
  @Input() control: AbstractControl = new FormControl();
  @Input() options: string[] | any[] = [];
  @Input() filteredOptions: Observable<string[] | any[]>;

  public modeString: boolean = true;

  constructor() {}

  ngOnInit() {
    if (!this.filteredOptions) {
      this.filteredOptions = this.control.valueChanges.pipe(
        startWith(''),
        debounceTime(200),
        map((value) => this._filter(value))
      );
    }
    this.control.addValidators(this.inArrayOptionsValidator(this.options));
  }

  inArrayOptionsValidator(array: string[] | IAutocompte[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (this.modeString) {
        if (control.value !== undefined && array.indexOf(control.value) === -1) {
          return { inArrayOptions: true };
        }
      } else {
        if (control.value !== undefined && (this.options as IAutocompte[]).find((o) => o.value === control.value) === undefined) {
          return { inArrayOptions: true };
        }
      }

      return null;
    };
  }

  isRequired() {
    try {
      return this.control?.validator(this.control)?.required;
    } catch (err) {
      return false;
    }
  }
  private _filter(value: string | any): string[] | any[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

    if (typeof this.options[0] === 'string') {
      this.modeString = true;
      return this.options.filter((option) => option?.toLowerCase().includes(filterValue));
    } else if (typeof this.options[0] === 'object') {
      this.modeString = false;
      return this.options.filter((option) => option.label?.toLowerCase().includes(filterValue));
    }
    return [];
  }

  getOptionText(option) {
    if (option) return (this.options as IAutocompte[]).find((o) => o.value.includes(option))?.label;
    else return '';
  }
}
