import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CountryService } from '@app/core/services/api';
import { TranslateService } from '@ngx-translate/core';
import { IAutocompte } from '../maresel-input-autocomplete/maresel-input-autocomplete.component';

@Component({
  selector: 'app-select-countries',
  templateUrl: './select-countries.component.html',
  styleUrls: ['./select-countries.component.scss'],
})
export class SelectCountriesComponent implements OnInit {

  @Input() label: string = '';
  @Input() control: FormControl = new FormControl();

  countries: IAutocompte[] = [];

  constructor(
    private translateSrv: TranslateService,
    private countrySrv: CountryService
  ) { }

  ngOnInit() {
    this.countrySrv.countryGet(this.translateSrv.currentLang).subscribe((countryCodes: string[]) => {
      this.countries = countryCodes.map((_c: string) => { return { value: _c, label: this.translateSrv.instant("COUNTRY." + _c) } }).sort((a, b) => (a.label > b.label) ? 1 : -1);
    })
  }

}