import { Component, OnInit } from '@angular/core';
import { MenuService } from '@app/core/services/menu/menu.service';

@Component({
  selector: 'app-maresel-header-sticky',
  templateUrl: './maresel-header-sticky.component.html',
  styleUrls: ['./maresel-header-sticky.component.scss']
})
export class MareselHeaderStickyComponent implements OnInit {
  constructor(public menuService: MenuService) {}

  ngOnInit() {}
}
