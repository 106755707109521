import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit-tarifas-table-form',
  templateUrl: './edit-tarifas-table-form.component.html',
  styleUrls: ['./edit-tarifas-table-form.component.scss']
})
export class EditTarifasTableFormComponent implements OnInit {
  @Input() child: FormGroup;
  @Output() addChildEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteChildEvent: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  addChild($event?) {
    if ($event) {
      this.addChildEvent.emit($event);
    } else {
      this.addChildEvent.emit(this.child);
    }
  }

  deleteChild($event?, index?: number) {
    if ($event?.child === true) {
      this.deleteChildEvent.emit({ parent: this.child, index });
    } else if (!$event?.index) {
      this.deleteChildEvent.emit({ child: true });
    } else {
      this.deleteChildEvent.emit($event);
    }
  }
}
