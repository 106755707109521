import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CasesService } from '@app/core/services/api';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';

interface ISelectDoctor {
  name: string;
  email: string;
}

@Component({
  selector: 'app-modal-select-doctor',
  templateUrl: './modal-select-doctor.component.html',
  styleUrls: ['./modal-select-doctor.component.scss']
})
export class ModalSelectDoctorComponent implements OnInit {
  email: FormControl;
  doctors: ISelectDoctor[] = [];

  constructor(
    public dialogRef: MatDialogRef<ModalSelectDoctorComponent>,
    private loaderSrv: SpinnerService,
    private casesSrv: CasesService,
    @Inject(MAT_DIALOG_DATA) public data: { idCase: string }
  ) {}

  ngOnInit() {
    this.email = new FormControl('', [Validators.required]);
    const loader = this.loaderSrv.showSpinner();
    this.casesSrv.doctorCaseGet(this.data.idCase).subscribe({
      next: (response) => {
        response.map((item) => this.doctors.push({ email: item.id, name: item.value }));
        loader.close();
      }
    });
  }

  saveDoctor() {
    if (this.email.valid) {
      // console.log(this.email.value);
      this.dialogRef.close(this.email.value);
    }
  }

  close() {
    this.dialogRef.close();
  }
}
