import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthenticationService } from './core/authentication/authentication.service';
import { LoginService } from './core/services/api';
import { ModalService } from './core/services/modal/modal.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    public oidcSecurityService: OidcSecurityService,
    public router: Router,
    private loginService: LoginService,
    private authSrv: AuthenticationService,
    private modalSrv: ModalService
  ) {}

  ngOnInit() {
    // Comentario para desplegar en PRO
    this.oidcSecurityService.checkAuth().subscribe(
      async (data: LoginResponse) => {
        if (data.isAuthenticated) {
          // console.log('Data authentication from azure', data);
          this.authSrv.setAzureToken(data.idToken);
          const roles = await this.loginService.rolUserGet().toPromise();
          // console.log(roles);
          if (roles && Array.isArray(roles) && roles.length) {
            this.authSrv.setUserRole(roles);
            if (this.router.url.indexOf('/login') !== -1){
              this.router.navigateByUrl('/home');
            }
          } else {
            this.modalSrv.openModalDanger('ERROR.LOGIN', 'ERROR.LOGIN-MESSAGE-ROLE');
            this.authSrv.localLogOut();
            this.oidcSecurityService.logoffLocal();
            this.router.navigateByUrl('/home');
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
