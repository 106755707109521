import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, tap, mergeMap, withLatestFrom } from 'rxjs/operators';

import * as actions from './language.actions';
import * as reducer from './language.reducer';
import { LanguageService } from '@services/language/language.service';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class LanguageEffects {
  constructor(private readonly languageService: LanguageService, private readonly actions$: Actions, private readonly store: Store) {}

  @Effect()
  // tslint:disable-next-line: no-any
  ChangeLanguage$: Observable<any> = this.actions$.pipe(
    ofType<actions.ChangeLanguage>(actions.CHANGE_LANGUAGE),
    tap((response) => this.store.dispatch(new actions.ChangeLanguageResponse(false))),
    map((data) => data.language),
    mergeMap((language) => this.languageService.changeLanguage(language).pipe(map((response) => new actions.ChangeLanguageResponse(response))))
  );

  @Effect()
  DownloadLanguage$: Observable<any> = this.actions$.pipe(
    ofType<actions.DownloadLanguage>(actions.DONWOLOAD_LANGUAGE),
    tap((response) => this.store.dispatch(new actions.DownloadLanguageResponse(false))),
    map((data) => data.url),
    withLatestFrom(this.store.select(reducer.getLanguage)),
    mergeMap(([url, lang]) =>
      this.languageService
        .downLoadLanguage(lang, url)
        .pipe(map((response) => new actions.DownloadLanguageResponse(!Object.keys(response).length ? false : true)))
    )
  );

  @Effect()
  UpdateLanguage$: Observable<any> = this.actions$.pipe(
    ofType<actions.UpdateLanguage>(actions.UPDATE_LANGUAGE),
    tap((response) => this.store.dispatch(new actions.UpdateLanguageResponse(false))),
    map((data) => data),
    withLatestFrom(this.store.select(reducer.getLanguage)),
    mergeMap(([data, lang]) =>
      this.languageService
        .updateLanguageVersion(lang, data.url, data.localVersion)
        .pipe(map((response) => new actions.UpdateLanguageResponse(!Object.keys(response).length ? false : true)))
    )
  );
}
