import { Action } from '@ngrx/store';
import { MetadataState } from './metadata.interface';
import { ActRegisterComponent } from '@services/activity-register/activity-register.interface';

export const UPDATE = '[METADATA] UPDATE';
export const INIT_ACTIVITY = '[METADATA] INIT_ACTIVITY';
export const UPDATE_ACTIVITY_ID = '[METADATA] UPDATE_ACTIVITY_ID';
export const SEND_COMPONENT_ACTIVITY = '[METADATA] SEND_COMPONENT_ACTIVITY';
export const SEND_COMPONENT_ACTIVITY_FINISHED = '[METADATA] SEND_COMPONENT_ACTIVITY_FINISHED';
export const SEND_COMPONENT_ACTIVITY_EXPIRED = '[METADATA] SEND_COMPONENT_ACTIVITY_EXPIRED';
export const SEND_COMPONENT_ACTIVITY_SUCCESS = '[METADATA] SEND_COMPONENT_ACTIVITY_SUCCESS';
export const SEND_COMPONENT_ACTIVITY_FAILED = '[METADATA] SEND_COMPONENT_ACTIVITY_FAILED';

export const SEND_ENTITY_ACTIVITY = '[METADATA] SEND_ENTITY_ACTIVITY';
export const SEND_ENTITY_ACTIVITY_EXPIRED = '[METADATA] SEND_ENTITY_ACTIVITY_EXPIRED';
export const SEND_ENTITY_ACTIVITY_FINISHED = '[METADATA] SEND_ENTITY_ACTIVITY_FINISHED';
export const SEND_ENTITY_ACTIVITY_SUCCESS = '[METADATA] SEND_ENTITY_ACTIVITY_SUCCESS';
export const SEND_ENTITY_ACTIVITY_FAILED = '[METADATA] SEND_ENTITY_ACTIVITY_FAILED';

export class Update implements Action {
  readonly type = UPDATE;
  constructor(public changes: Partial<MetadataState>) {}
}

export class InitActivity implements Action {
  readonly type = INIT_ACTIVITY;
  constructor() {}
}

export class UpdateActivityId implements Action {
  readonly type = UPDATE_ACTIVITY_ID;
  constructor() {}
}

// COMPONENT
export class SendComponentActivity implements Action {
  readonly type = SEND_COMPONENT_ACTIVITY;
  constructor(public data: ActRegisterComponent[]) {}
}

export class SendComponentActivityExpired implements Action {
  readonly type = SEND_COMPONENT_ACTIVITY_EXPIRED;
  constructor(public data: ActRegisterComponent[]) {}
}

export class SendComponentActivityFinished implements Action {
  readonly type = SEND_COMPONENT_ACTIVITY_FINISHED;
  constructor(public data: ActRegisterComponent[]) {}
}

export class SendComponentActivitySuccess implements Action {
  readonly type = SEND_COMPONENT_ACTIVITY_SUCCESS;
  constructor() {}
}

export class SendComponentActivityFailed implements Action {
  readonly type = SEND_COMPONENT_ACTIVITY_FAILED;
  constructor() {}
}

// ENTITYS
export class SendEntitysActivity implements Action {
  readonly type = SEND_ENTITY_ACTIVITY;
  constructor(public entities: string[]) {}
}

export class SendEntitysActivityExpired implements Action {
  readonly type = SEND_ENTITY_ACTIVITY_EXPIRED;
  constructor(public entities: string[]) {}
}

export class SendEntitysActivityFinished implements Action {
  readonly type = SEND_ENTITY_ACTIVITY_FINISHED;
  constructor(public entities: string[]) {}
}

export class SendEntitysActivitySuccess implements Action {
  readonly type = SEND_ENTITY_ACTIVITY_SUCCESS;
  constructor() {}
}

export class SendEntitysActivityFailed implements Action {
  readonly type = SEND_ENTITY_ACTIVITY_FAILED;
  constructor() {}
}

export type metadataActions =
  | Update
  | UpdateActivityId
  | InitActivity
  | SendComponentActivity
  | SendEntitysActivity
  | SendEntitysActivityExpired
  | SendComponentActivityFinished
  | SendComponentActivityExpired
  | SendEntitysActivitySuccess
  | SendComponentActivitySuccess
  | SendComponentActivityFailed
  | SendEntitysActivityFailed;
