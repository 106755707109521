import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogOverviewComponent } from '@shared/dialog-overview/dialog-overview.component';
import { ButtonModal, GenericModalComponent } from '@app/shared/generic-modal/generic-modal.component';
import { ModalTarifasComponent } from '@app/shared/modal-tarifas/modal-tarifas.component';
import { ModalDataUserComponent } from '@app/shared/modal-data-user/modal-data-user.component';
import { ModalNewCaseComponent } from '@app/shared/modal-new-case/modal-new-case.component';
import { ModalSubscribeUserComponent } from '@app/shared/modal-subscribe-user/modal-subscribe-user.component';
import { Searchuser } from '../api/model/searchUser';
import { ICedente } from '../api/model/cedente.model';
import { ModalDynamicIntegrationComponent } from '@app/shared/modal-dynamic-integration/modal-dynamic-integration.component';
import { Subject } from 'rxjs';
import { ModalDrugBankComponent } from '@app/shared/modal-drug-bank/modal-drug-bank.component';
import { ModalReopenCaseComponent } from '@app/shared/modal-reopen-case/modal-reopen-case.component';
import { ModalSelectDoctorComponent } from '@app/shared/modal-select-doctor/modal-select-doctor.component';
import { ModalSimilarPricingComponent } from '@app/shared/modal-similar-pricing/modal-similar-pricing.component';
import { SearchuserUsers } from '../api';
import { ModalSelectCedenteComponent } from '@app/shared/modal-select-cedente/modal-select-cedente.component';
import { MedicamentosInner } from '../api/model/medicamentosInner';
import { ModalDetailDrugComponent } from '@app/shared/modal-detail-drug/modal-detail-drug.component';

/*
   let mainButton: ButtonModal = {
      name: 'BIBLIOGRAPHY',
      f: () =>{
        console.log("Console log main button0");
      }
    }
    this.modalService.openModalInfo('LOREM','LOREM', mainButton, secondaryButton);
*/

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public refreshUsers$: Subject<any> = new Subject();
  constructor(public dialog: MatDialog) {}

  openModalDetailDrug(drug: MedicamentosInner) {
    return this.dialog.open(ModalDetailDrugComponent, {
      data: drug,
      disableClose: true,
      panelClass: 'drug-finder-modal'
    });
  }

  openModalSimilarPricing(idCase: string) {
    return this.dialog.open(ModalSimilarPricingComponent, {
      data: { idCase },
      disableClose: true,
      panelClass: 'full-screen-modal'
    });
  }

  openModalSelectCedente(cedente?: number) {
    return this.dialog.open(ModalSelectCedenteComponent, {
      data: { cedente },
      disableClose: true,
      panelClass: 'select-modal'
    });
  }

  openModalSelectDoctor(idCase: string) {
    return this.dialog.open(ModalSelectDoctorComponent, {
      data: { idCase },
      disableClose: true,
      panelClass: 'select-modal'
    });
  }

  openModalReopenCase(idCase: string) {
    return this.dialog.open(ModalReopenCaseComponent, {
      data: idCase,
      disableClose: true,
      panelClass: 'comment-modal'
    });
  }
  openModalSubscribeUser(data: SearchuserUsers) {
    return this.dialog
      .open(ModalSubscribeUserComponent, {
        data,
        disableClose: true
      })
      .afterClosed()
      .subscribe(() => {
        this.refreshUsers$.next(true);
      });
  }
  openModalDataUser(data: any) {
    return this.dialog
      .open(ModalDataUserComponent, {
        data,
        disableClose: true,
        panelClass: 'full-screen-modal'
      })
      .afterClosed()
      .subscribe(() => {
        this.refreshUsers$.next(true);
      });
  }

  openModalDrugBankSeeker() {
    return this.dialog.open(ModalDrugBankComponent, {
      disableClose: true,
      panelClass: 'drug-finder-modal'
    });
  }

  openModalTarifas(risk: string, lang: string, order?: number) {
    const dialogRef = this.dialog.open(ModalTarifasComponent, {
      data: {
        risk,
        lang,
        order
      },
      disableClose: true,
      panelClass: 'full-screen-modal'
    });
    return dialogRef;
  }

  openModalSuccess(title: string, subtitle: string, mainButton?: ButtonModal, secondaryButton?: ButtonModal) {
    const dialogRef = this.dialog.open(GenericModalComponent, {
      data: { title, subtitle, type: 'success', mainButton, secondaryButton },
      disableClose: true
    });
    return dialogRef;
  }
  openModalInfo(title: string, subtitle: string, mainButton?: ButtonModal, secondaryButton?: ButtonModal) {
    const dialogRef = this.dialog.open(GenericModalComponent, {
      data: { title, subtitle, type: 'info', mainButton, secondaryButton },
      disableClose: true
    });
    return dialogRef;
  }
  openModalWarning(title: string, subtitle: string, mainButton?: ButtonModal, secondaryButton?: ButtonModal) {
    const dialogRef = this.dialog.open(GenericModalComponent, {
      data: { title, subtitle, type: 'warning', mainButton, secondaryButton },
      disableClose: true
    });
    return dialogRef;
  }
  openModalDanger(title: string, subtitle: string, mainButton?: ButtonModal, secondaryButton?: ButtonModal) {
    const dialogRef = this.dialog.open(GenericModalComponent, {
      data: { title, subtitle, type: 'danger', mainButton, secondaryButton },
      disableClose: true
    });
    return dialogRef;
  }

  openModalDynamicIntegration(content: string) {
    const dialogRef = this.dialog.open(ModalDynamicIntegrationComponent, {
      data: { content },
      disableClose: true
    });
    return dialogRef;
  }

  openModalNewCase(cedente?: number) {
    const dialogRef = this.dialog.open(ModalNewCaseComponent, {
      data: { cedente },
      disableClose: true,
      panelClass: 'full-screen-modal'
    });
    return dialogRef;
  }
}
