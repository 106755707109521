import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cart-menu-popover',
  templateUrl: './cart-menu-popover.component.html',
  styleUrls: ['./cart-menu-popover.component.scss']
})
export class CartMenuPopoverComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
