import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({ providedIn: 'root' })
export class RolesGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthenticationService) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    let roles = route.data.roles as Array<string>;
    let userRoles = this.authService.getRoles();
    let checkRol: boolean = false;
    for (let index = 0; index < roles.length; index++) {
      const r = roles[index];
      if (!checkRol) {
        checkRol = userRoles.includes(r);
      }
    }

    if (checkRol) {
      return true;
    } else {
      this.router.navigateByUrl('/home');
      return false;
    }
  }
}
