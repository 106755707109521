import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GET_ENDPOINT } from '@global/endpoints';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  constructor(private readonly httpClient: HttpClient) {}

  get<T>(url: string, params?: string[]): Observable<T> {
    return this.httpClient.get<T>(this.createUrl(url, params));
  }

  post<T, K>(url: string, body: K, params?: string[]): Observable<T> {
    return this.httpClient.post<T>(this.createUrl(url, params), body);
  }

  put<T, K>(url: string, body: K, params?: string[]): Observable<T> {
    return this.httpClient.put<T>(this.createUrl(url, params), body);
  }

  delete<T, K>(url: string, body: K, params?: string[]): Observable<T> {
    return this.httpClient.delete<T>(this.createUrl(url, params), body);
  }

  getLocalJson<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(url);
  }

  private createUrl(url: string, params?: string[]) {
    return GET_ENDPOINT(url, params);
  }
}
