/**
 * servicios menus y contenido
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: csgj@gft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MetabolicoInputBloodPressureTreatmentList } from './metabolicoInputBloodPressureTreatmentList';

export interface MetabolicoInputKidneyProfile {
  creatinine?: number;
  creatinineMeasure?: string;
  albuminuria?: number;
  albuminuriaMeasure?: string;
  proteinuria?: number;
  uricAcid?: number;
  treatmentUricAcid?: boolean;
  treatmentList?: Array<MetabolicoInputBloodPressureTreatmentList>;
  GFR?: number;
  ERC?: string;
  sinceDiagnosed?: string;
}
