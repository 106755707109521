import { Action } from '@ngrx/store';
import { HomeState } from './home.interface';

export const CREATE_HOME = '[HOME] CREATE_HOME';
export const UPDATE_HOME = '[HOME] UPDATE_HOME';

export class CreateHome implements Action {
  readonly type = CREATE_HOME;
  constructor(public changes: HomeState) {}
}

export class UpdatePyme implements Action {
  readonly type = UPDATE_HOME;
  constructor(public changes: Partial<HomeState>) {}
}

export type homeActions = CreateHome | UpdatePyme;
