import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard implements CanActivate {
  constructor(private oidcSecurityService: OidcSecurityService, private router: Router, private authenticationService: AuthenticationService) { }

  // Cuando tengamos los dos esto no nos vale

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new Promise((resolve, reject) => {
      this.oidcSecurityService.isAuthenticated$.subscribe(
        async ({ isAuthenticated }) => {
          // allow navigation if authenticated
          if (isAuthenticated) {
            this.authenticationService.authType = 'AZURE';
            if (sessionStorage.getItem(this.authenticationService.ROLES) !== null) {
              const roleStorage = JSON.parse(sessionStorage.getItem(this.authenticationService.ROLES));
              if (roleStorage && roleStorage.userData && roleStorage.userData.length) {
                if (this.authenticationService.checkToken(this.authenticationService.currentTokenValue).valid) {
                  resolve(true);
                  return true;
                }
              }
            }
          } else if (sessionStorage.getItem(this.authenticationService.TOKEN) !== null) {
            // comprobar la seguridad de cognito
            this.authenticationService.authType = 'COGNITO';
            if (this.authenticationService.checkToken(this.authenticationService.currentTokenValue).valid) {
              resolve(true);
              return true;
            } else {
              let tokens = await this.authenticationService.refreshToken().toPromise();
              if (tokens.jwtToken) {
                this.authenticationService.storeTokens(tokens);
                resolve(true);
                return true;
              } else {
                resolve(false);
                return this.router.navigateByUrl('/unauthorized');
              }
            }
          }
          // redirect if not authenticated
          this.router.navigateByUrl('/unauthorized');
          resolve(false);
        }
      );
    })
  }
}
