import { Injectable } from '@angular/core';
import { WorkspaceTable } from '@app/pages/work-area/work-area.page';
import { instanceOfError } from '@app/shared/utils/commonMethods';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CasesService } from '../api';
import { ICaseInCourse } from '../api/model/case-in-course.model';
import { LocalStorageServie } from '../storage/storage.service';

const CASE_KEY = 'CASE_IN_COURSE';

@Injectable({
  providedIn: 'root'
})
export class CaseDataService {
  private storage: Storage;
  private case$: BehaviorSubject<ICaseInCourse> = new BehaviorSubject(undefined);
  private caseChangeState$: Subject<ICaseInCourse> = new Subject();

  constructor(private storageSrv: LocalStorageServie, private casesSrv: CasesService) {
    this.storage = this.storageSrv.get();
    // We check if local storage has case in course when init service
    this.checkCase();
    /* this.casesSrv.incourseDataGet().subscribe({
      next: (response) => {
        if (!instanceOfError(response)) {
          this.set(response as ICaseInCourse);
        }
      }
    }); */
    /* if (this.hasInCourse()) {
      this.set(JSON.parse(this.storage.getItem(CASE_KEY)));
    } */
  }
  public setInStorage(caseInCourse: ICaseInCourse) {
    this.storage.setItem(CASE_KEY, JSON.stringify(caseInCourse));
  }

  public get() {
    return this.case$.asObservable();
  }

  public getCase(): ICaseInCourse {
    return this.case$.value;
  }
  public getChangeState() {
    return this.caseChangeState$.asObservable();
  }
  public setChangeState(changeState: any) {
    return this.caseChangeState$.next(changeState);
  }

  public checkCase() {
    this.casesSrv.incourseDataGet().subscribe({
      next: (response) => {
        if (!instanceOfError(response)) {
          this.set(response as ICaseInCourse);
        } else {
          this.remove();
        }
      }
    });
  }

  public set(caseInCourse: ICaseInCourse) {
    return new Promise((resolve, reject) => {
      this.casesSrv.inCourseCasePost({ idCase: caseInCourse.idCase }).subscribe({
        next: (response: any) => {
          if (!instanceOfError(response)) {
            this.case$.next(caseInCourse);
            this.storage.setItem(CASE_KEY, JSON.stringify(caseInCourse));
            resolve(response);
          } else {
            reject(response);
          }
        },
        error: (error) => reject(error.error)
      });
    });
  }

  public remove() {
    this.case$.next(undefined);
    this.storage.setItem(CASE_KEY, '');
  }

  public hasInCourse(): boolean {
    if (this.storage.getItem(CASE_KEY) && this.storage.getItem(CASE_KEY).length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
