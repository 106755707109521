// Reducer
import { ActionReducerMap } from '@ngrx/store';
import { homeReducer } from './home/home.reducer';
import { languageReducer } from './language/language.reducer';
// State
import { LanguageState } from './language/language.interface';
import { HomeState } from './home/home.interface';
import { MetadataState } from './metadata/metadata.interface';
// Effetcs
import { LanguageEffects } from '@store/language/language.effects';
import { metadataReducer } from './metadata/metadata.reducer';

// tslint:disable-next-line: no-any
const reducers: ActionReducerMap<any> = {
  home: homeReducer,
  language: languageReducer,
  metadata: metadataReducer
};

const effects = [LanguageEffects];

export interface AppState {
  home: HomeState;
  language: LanguageState;
  metadata: MetadataState;
}

export { reducers, effects };
