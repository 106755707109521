import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { CasesService, ReminderDoctorBody } from '@app/core/services/api';
import { ICedente } from '@app/core/services/api/model/cedente.model';
import { CaseDataService } from '@app/core/services/case-data/case-data.service';
import { CedenteService } from '@app/core/services/cedente/cedente.service';
import { ModalService } from '@app/core/services/modal/modal.service';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { ADMINISTRATOR, CHIEFSUSCRIPTOR, DOCTOR, SUSCRIPTOR } from '../utils/commonMethods';

@Component({
  selector: 'app-table-cases',
  templateUrl: './table-cases.component.html',
  styleUrls: ['./table-cases.component.scss']
})
export class TableCasesComponent implements OnInit {
  @Output() changeState: EventEmitter<any> = new EventEmitter();
  @Output() consultCase: EventEmitter<string> = new EventEmitter();
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[];
  cedentes: ICedente[];

  constructor(
    private authSrv: AuthenticationService,
    private loaderSrv: SpinnerService,
    private caseDataSrv: CaseDataService,
    private cedenteSrv: CedenteService,
    private casesSrv: CasesService,
    private modalService: ModalService,
    private translateSrv: TranslateService
  ) {}

  @Input() set data(value) {
    this.dataSource = new MatTableDataSource(value);
  }
  ngOnInit() {
    this.cedentes = [...this.cedenteSrv.cedentes];
    if (this.isSubscriberRol()) {
      this.displayedColumns = ['case', 'creation', 'coberture', 'result', 'state', 'menu'];
    } else if (this.isAdminSubscriberRol() || this.isUserAdminRol()) {
      this.displayedColumns = ['case', 'userCode', 'creation', 'coberture', 'result', 'state', 'menu'];
    } else if (this.isDoctorRol()) {
      this.displayedColumns = ['case', 'responsible', 'reviewDate', 'coberture', 'result', 'state', 'menu'];
    }
  }

  isSubscriberRol(): boolean {
    return this.authSrv.checkRolUser(SUSCRIPTOR);
  }

  isAdminSubscriberRol(): boolean {
    return this.authSrv.checkRolUser(CHIEFSUSCRIPTOR);
  }

  isDoctorRol(): boolean {
    return this.authSrv.checkRolUser(DOCTOR);
  }

  isUserAdminRol() {
    return this.authSrv.checkRolUser(ADMINISTRATOR);
  }

  getCedente(key: number | string) {
    const cedente = this.cedentes.find((item) => item.key === key);
    if (cedente) {
      return cedente.name;
    } else {
      return '';
    }
  }

  sendReminder(item: any) {
    const loader = this.loaderSrv.showSpinner();
    const body: ReminderDoctorBody = { idCase: item.idCase };
    this.casesSrv.reminderDoctorPost(body).subscribe({
      next: (response) => {
        loader.close();
        // console.log(response);
        if (!response) {
          this.modalService.openModalSuccess('', 'MODAL.SUCCESS.REMINDER-MSG');
        } else {
          this.modalService.openModalDanger('ERROR.ERROR', 'MODAL.ERROR.REMINDER-MSG-ERROR');
        }
      }
    });
  }

  openDetailCase(item: any) {
    this.consultCase.emit(item.idCase);
  }

  setCaseInProgress(item: any) {
    const loader = this.loaderSrv.showSpinner();

    this.caseDataSrv
      .set({ idCase: item.idCase, referenceNumber: item.referenceNumber, age: item.age, gender: item.gender, location: item.location, country: item.country })
      .then((value) => {
        loader.close();
        this.changeState.emit();
      })
      .catch((error) => {
        loader.close();
        this.modalService.openModalDanger('ERROR.ERROR', error.errorMessage.key);
      });
  }
  closeCase(item: any) {
    const loader = this.loaderSrv.showSpinner();
    const body = { idCase: item.idCase };
    this.casesSrv.closeCasePost(body).subscribe({
      next: (response) => {
        loader.close();
        this.caseDataSrv.checkCase();
        this.changeState.emit();
      }
    });
  }
  reopenCase(item: any) {
    this.modalService
      .openModalReopenCase(item.idCase)
      .afterClosed()
      .subscribe({
        next: (response) => {
          if (response) {
            const loader = this.loaderSrv.showSpinner();
            this.casesSrv.openCasePost(response).subscribe({
              next: (rsp) => {
                this.caseDataSrv.checkCase();
                loader.close();
                this.changeState.emit();
              }
            });
          }
        }
      });
  }
}
