import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MedicamentosService } from '@app/core/services/api';
import { GetDrugIndicationsBody } from '@app/core/services/api/model/getDrugIndicationsBody';
import { MedicamentosInner } from '@app/core/services/api/model/medicamentosInner';
import { ModalService } from '@app/core/services/modal/modal.service';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { concat, of } from 'rxjs';
import { concatMap, find, map, tap } from 'rxjs/operators';
import { IDrugItem } from '../drug-finder-seeker/drug-finder-seeker.component';
import { DRUGSTORAGE, instanceOfError } from '../utils/commonMethods';

@Component({
  selector: 'app-medication-detail',
  templateUrl: './medication-detail.component.html',
  styleUrls: ['./medication-detail.component.scss']
})
export class MedicationDetailComponent implements OnInit, OnDestroy {
  @Input('formControlListName') formControlListName: string;
  @Input('data') data: any[];

  treatmentForm: FormArray;

  constructor(
    private modalSrv: ModalService,
    private formGroupDirective: FormGroupDirective,
    private drugService: MedicamentosService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    // Initialize treatement form
    this.treatmentForm = new FormArray([]);
    if (this.formGroupDirective.form) {
      this.formGroupDirective.form.setControl(this.formControlListName ? this.formControlListName : 'treatmentList', this.treatmentForm);
    }
    if (this.data) {
      this.data.forEach((treament) => {
        this.addTreatmentForm(treament);
      });
    }
  }

  ngOnDestroy() {
    if (this.formGroupDirective.form) {
      this.formGroupDirective.form.removeControl(this.formControlListName ? this.formControlListName : 'treatmentList');
    }
  }

  addTreatmentForm(value?: any) {
    // Mockeamos datos con el formulario
    // Llamamos al modal que devolverá el array de medicamentos
    if (value) {
      // console.log('Valor imagino que de lo guardado', value);
      const form: FormGroup = new FormGroup({
        name: new FormControl(''),
        ingredients: new FormControl(''),
        dci: new FormControl(''),
        state: new FormControl('', [Validators.required])
      });
      form.patchValue(value);
      this.treatmentForm.push(form);
    } else {
      this.modalSrv
        .openModalDrugBankSeeker()
        .afterClosed()
        .subscribe({
          next: (response) => {
            // console.log('Respuesta del modal del buscador', response);
            if (response) {
              response.map((drug) => {
                const form: FormGroup = new FormGroup({
                  name: new FormControl(drug.name),
                  ingredients: new FormControl(drug.ingredients),
                  dci: new FormControl(drug.dci),
                  state: new FormControl('', [Validators.required])
                });
                this.treatmentForm.push(form);
              });
            }
          }
        });
    }
  }

  removeTreatmentForm(i: number) {
    this.treatmentForm.removeAt(i);
  }

  showDrug(form: AbstractControl) {
    // console.log(form);

    const drug: IDrugItem = form.value;
    // console.log(drug);
    if (drug.dci.length) {
      // TODO call service and send data to modal. We need create the modal
      // Primero miramos si tenemos en la sesión lo datos de medicamentos y en ese caso, lo buscamos, si no tendremos que hacer uso de los servicios.
      if (sessionStorage.getItem(DRUGSTORAGE)) {
        const list: MedicamentosInner[] = JSON.parse(sessionStorage.getItem(DRUGSTORAGE)).drugs;
        const drugItem = list.find((item) => item.name!==undefined ? item.name === drug.dci : item.displayName+' '+item.strength.number+item.strength.unit===drug.dci);
        if (drugItem) {
          this.openDetailDrugModal(drugItem);
        } else {
          this.getDrugData(drug.dci);
        }
      } else {
        this.getDrugData(drug.dci);
      }
    } else {
      this.modalSrv.openModalDanger('ERROR.ERROR', 'ERROR.MODAL.ERROR-LOAD-DRUG');
    }
  }

  getDrugData(medicineName: string) {
    const loader = this.spinnerService.showSpinner();
    let drug: MedicamentosInner;
    this.drugService
      .medicamentosPost({ medicineName })
      .pipe(
        map((item) => item.find((d) => d.name === medicineName)),
        concatMap((drugInner) => {
          if (drugInner) {
            drug = { ...drugInner };
            const getIndicationsBody: GetDrugIndicationsBody = {
              drugbankId: []
            };
            for (const ingredient of drugInner.ingredients) {
              getIndicationsBody.drugbankId.push(ingredient.drugbank_id);
            }
            return this.drugService.getDrugIndicationsPost(getIndicationsBody);
          } else {
            return of(null);
          }
        })
      )
      .subscribe(
        (data) => {
          // console.log(data);
          if (!instanceOfError(data)) {
            let indications;
            if (Array.isArray(data)) {
              indications = [...data];
            } else {
              indications = JSON.parse((data as any).body);
            }
            indications.map((indication) => {
              const index = drug.ingredients.findIndex((item) => item.drugbank_id === indication.id);
              if (index !== -1) {
                drug.ingredients[index] = {
                  ...drug.ingredients[index],
                  conditions: indication.conditions,
                  conditionsAssociated: indication.conditionsAssociated
                };
              }
            });
          }
          this.openDetailDrugModal(drug);
          loader.close();
        },
        (err) => {
          loader.close();
          console.log(err);
        }
      );
  }

  openDetailDrugModal(data: MedicamentosInner) {
    // console.log('Datos para el modal', data);
    this.modalSrv.openModalDetailDrug(data);
  }
}
