import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CurrenciesService } from '@app/core/services/api';
import { ICurrency } from '@app/core/services/api/model/iCurrency';
import { TranslateService } from '@ngx-translate/core';
import { IAutocompte } from '../maresel-input-autocomplete/maresel-input-autocomplete.component';

@Component({
  selector: 'app-select-currencies',
  templateUrl: './select-currencies.component.html',
  styleUrls: ['./select-currencies.component.scss'],
})
export class SelectCurrenciesComponent implements OnInit {

  @Input() label: string = '';
  @Input() control: FormControl = new FormControl();

  currencies: IAutocompte[] = [];

  constructor(
    private currenciesSrv: CurrenciesService,
    private translateSrv: TranslateService
  ) { }

  ngOnInit() {
    this.currenciesSrv.currenciesGet(this.translateSrv.currentLang).subscribe((currencies) => {
      this.currencies = currencies.map(c => { return { value: c.cla_moneda, label: c.cla_moneda + ' - ' + this.translateSrv.instant("CURRENCY." + c.cla_moneda) } });
    })
  }

}
