import * as actions from './home.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HomeState } from './home.interface';
import { sample } from 'rxjs/operators';

export const initialState: HomeState = {
  location: {
    zipCode: null,
    state: null,
    population: null
  }
};

export function homeReducer(state: HomeState = initialState, action: actions.homeActions) {
  switch (action.type) {
    case actions.CREATE_HOME:
      return { ...state, ...action.changes };

    case actions.UPDATE_HOME:
      return { ...state, ...action.changes };

    default:
      return state;
  }
}

export const getHomeState = createFeatureSelector<HomeState>('home');

export const getHome = createSelector(getHomeState, (state: HomeState) => state);
