import { Component, Input, OnChanges } from '@angular/core';
import { ConsultCaseRisksFees } from '@app/core/services/api';

export interface CoberturaTable {
  title: string;
  value: string;
  last: boolean;
}

@Component({
  selector: 'app-detail-case-tarifas-print',
  templateUrl: './detail-case-tarifas-print.component.html',
  styleUrls: ['./detail-case-tarifas-print.component.scss'],
})
export class DetailCaseTarifasPrintComponent implements OnChanges {
  @Input() dataSourceTable: ConsultCaseRisksFees;
  @Input() finalComments = [];
  headerCoberturas = [];
  listadoRiesgos = [];
  indiceCobertura = 0;

  ngOnChanges() {
    // console.log(this.dataSourceTable);
    this.headerCoberturas = this.dataSourceTable.headerValues.map((item) => item).slice(2);
    this.indiceCobertura = this.headerCoberturas.length;
    this.listadoRiesgos = this.dataSourceTable.values.filter((item) => item);
  }

  sanitizerHtml(value: string) {
    const div = document.createElement('div');
    div.innerHTML = value;
    let text = div.textContent || div.innerText || '';
    text = text.replace('SafeValue must use [property]=binding: ', '');
    text = text.replace(/ *\([^)]*\) */g, '');
    return text;
  }

  getDataTable(idCob: number): CoberturaTable[] {
    const coberturas: CoberturaTable[] = [];
    this.dataSourceTable.values.forEach((item) => {
      coberturas.push({
        title: item.values[1],
        value: item.values[idCob] ? this.sanitizerHtml(item.values[idCob]) : '-',
        last: item.id === 'finalResult' || item.id === 'Resultado' ? true : false
      });
    });
    return coberturas;
  }
}
