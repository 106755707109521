import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { CalcValidators } from '@app/core/directives/validators/calc-validators';
import { InlineResponse20016, MetabolicoService } from '@app/core/services/api';
import { ModalService } from '@app/core/services/modal/modal.service';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-metabolic-calc-liver-profile',
  templateUrl: './metabolic-calc-liver-profile.component.html',
  styleUrls: ['./metabolic-calc-liver-profile.component.scss']
})
export class MetabolicCalcLiverProfileComponent implements OnInit {
  @Input() data;
  
  liverProfile: FormGroup = new FormGroup({
    GGTALP: new FormGroup({
      measuredValues: new FormControl(0, [CalcValidators.liverValues(0, 2000)]),
      measuredMaxValuesLab: new FormControl('', [CalcValidators.liverValues(0, 2000)]),
      alterationRange: new FormControl({ disabled: true, value: '' })
    }),
    GOTAST: new FormGroup({
      measuredValues: new FormControl(0, [CalcValidators.liverValues(0, 2000)]),
      measuredMaxValuesLab: new FormControl('', [CalcValidators.liverValues(0, 2000)]),
      alterationRange: new FormControl({ disabled: true, value: '' })
    }),
    GPTALT: new FormGroup({
      measuredValues: new FormControl(0, [CalcValidators.liverValues(0, 2000)]),
      measuredMaxValuesLab: new FormControl('', [CalcValidators.liverValues(0, 2000)]),
      alterationRange: new FormControl({ disabled: true, value: '' })
    })
  });

  constructor(
    private formGroupDirective: FormGroupDirective,
    private spinnerService: SpinnerService,
    private metabolicoService: MetabolicoService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    if (this.formGroupDirective.form) {
      this.formGroupDirective.form.setControl('liverProfile', this.liverProfile);
    }
    this.getMaxValues();
    (this.liverProfile.controls.GGTALP as FormGroup).valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      (this.liverProfile.controls.GGTALP as FormGroup).controls.alterationRange.setValue(
        this.calcAverage(value.measuredValues, value.measuredMaxValuesLab),
        { emitEvent: false }
      );
    });
    (this.liverProfile.controls.GOTAST as FormGroup).valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      (this.liverProfile.controls.GOTAST as FormGroup).controls.alterationRange.setValue(
        this.calcAverage(value.measuredValues, value.measuredMaxValuesLab),
        { emitEvent: false }
      );
    });
    (this.liverProfile.controls.GPTALT as FormGroup).valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      (this.liverProfile.controls.GPTALT as FormGroup).controls.alterationRange.setValue(
        this.calcAverage(value.measuredValues, value.measuredMaxValuesLab),
        { emitEvent: false }
      );
    });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit(): void {
    if (this.data) {
      this.liverProfile.patchValue(this.data);
    }
  }

  calcAverage(value1: number, value2: number): string {
    return (value1 / value2).toFixed(2).toString() + 'x';
  }

  getMaxValues() {
    let spinner = this.spinnerService.showSpinner();
    this.metabolicoService.hepaticoGet().subscribe(
      (response: any) => {
        (this.liverProfile.controls.GGTALP as FormGroup).controls.measuredMaxValuesLab.setValue(response.GGT);
        (this.liverProfile.controls.GOTAST as FormGroup).controls.measuredMaxValuesLab.setValue(response.GOT);
        (this.liverProfile.controls.GPTALT as FormGroup).controls.measuredMaxValuesLab.setValue(response.GPT);
        spinner.close();
      },
      (error) => {
        spinner.close();
        this.modalService.openModalDanger('Error', error);
      }
    );
  }
}
