import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-maresel-footer',
  templateUrl: './maresel-footer.component.html',
  styleUrls: ['./maresel-footer.component.scss']
})
export class MareselFooterComponent implements OnInit {
  customPath: './privacy-policy';
  constructor( private router: Router ) {}


  ngOnInit() {}

  btnPolicy () {
    this.router.navigateByUrl('privacy-policy');
  }

  btnCookies () {
    this.router.navigateByUrl('cookie-policy');
  }

}
