import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ShoppingCartService } from '@app/core/services/shopping-cart/shopping-cart.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

interface IKeyValue {
  key: string | number;
  value: string;
}
@Component({
  selector: 'app-melanoma-calc-result',
  templateUrl: './melanoma-calc-result.component.html',
  styleUrls: ['./melanoma-calc-result.component.scss']
})
export class MelanomaCalcResultComponent implements OnInit, OnDestroy {
  @Input() dataSource: { data: any; result: any };
  @Output() dataToEdit: EventEmitter<any> = new EventEmitter();
  suscription: Subscription;
  dataResult: any;

  /* breslow = [
    { key: 0, value: 'MELANOMA-CALC.HISTOLOGY.INDETERMINATED' },
    { key: 1, value: 'In situ' },
    { key: 2, value: '≤ 1mm' },
    { key: 3, value: '1,01 - 2 mm' },
    { key: 4, value: '2,01 - 4 mm' },
    { key: 5, value: '> 4 mm' }
  ]; */
  breslow: IKeyValue[] = [
    { key: 'Tx', value: 'MELANOMA-CALC.HISTOLOGY.INDETERMINATED' },
    { key: 'Tis', value: 'In situ' },
    { key: 'T1', value: '≤ 1mm' },
    { key: 'T2', value: '1,01 - 2 mm' },
    { key: 'T3', value: '2,01 - 4 mm' },
    { key: 'T4', value: '> 4 mm' }
  ];
  listGanglionOptions = [
    { key: 0, value: 'MELANOMA-CALC.HISTOLOGY.INDETERMINATED' },
    { key: 1, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.0' },
    { key: 2, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.1' },
    { key: 3, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.2' },
    { key: 4, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.3' },
    { key: 5, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.4' },
    { key: 6, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.5' },
    { key: 7, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.6' },
    { key: 8, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.7' },
    { key: 9, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.8' },
    { key: 10, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.9' }
  ];

  nodalInvolvementOptions: IKeyValue[] = [
    {
      key: 'N0',
      value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.N0'
    },
    {
      key: 'N1',
      value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.N1'
    },
    {
      key: 'N2',
      value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.N2'
    },
    {
      key: 'N3',
      value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.N3'
    },
    {
      key: 'Nx',
      value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.Nx'
    }
  ];

  metastesesOptions: IKeyValue[] = [
    {
      key: 'M0',
      value: 'MELANOMA-CALC.HISTOLOGY.HAVE-METASTESES.M0'
    },
    {
      key: 'M1',
      value: 'MELANOMA-CALC.HISTOLOGY.HAVE-METASTESES.M1'
    }
  ];

  constructor(private translateSrv: TranslateService, private cartShoppingSrv: ShoppingCartService) {}
  ngOnDestroy(): void {
    this.suscription.unsubscribe();
  }

  ngOnInit() {
    this.prepareResultData();
    this.suscription = this.cartShoppingSrv.get().subscribe((cart) => {
      cart.items.forEach((item) => {
        if (item.id.indexOf('CALC_MEL') !== -1) {
          item.dataCalc = this.dataSource.data;
        }
      });
      this.cartShoppingSrv.save(cart);
    });
  }

  prepareResultData() {
    this.dataResult = {
      stickyColsStart: 2,
      stickyColsEnd: 1,
      headerValues: [' ', 'RATE.TTL-HEADER-RISK'],
      values: [
        {
          id: this.dataSource.result.id,
          values: ['', 'TITLES.MELANOMA-CALCULATOR']
        }
      ]
    };
    this.dataSource.result.cobertura.map((coverage) => {
      this.dataResult.headerValues.push(this.translateSrv.instant(`RATE.COBERTURAS.${coverage.id}`));
      this.dataResult.values[0].values.push(coverage.valor);
    });
  }

  getMelanomaType = () => this.translateSrv.instant(`MELANOMA-CALC.MELANOMA-TYPE.TYPES.${this.dataSource.data.melanomaType.typeCode}`);

  hasTreatment(treatment: string) {
    if (this.dataSource.data.treatmentType[treatment]) {
      return true;
    } else {
      return false;
    }
  }

  getSurgicalTreatment() {
    if (this.dataSource.data.treatmentType.surgical.exeresis === 0) {
      return this.translateSrv.instant('MELANOMA-CALC.TREATMENT-TYPE.SURGICAL.TOTAL');
    } else {
      return this.translateSrv.instant('MELANOMA-CALC.TREATMENT-TYPE.SURGICAL.PARTIAL');
    }
  }

  getInCourseLabel(treatment: string, option?: string) {
    if (option) {
      if (this.dataSource.data.treatmentType[treatment][option]) {
        return this.translateSrv.instant('MELANOMA-CALC.TREATMENT-TYPE.GENERAL.YES');
      } else {
        return this.translateSrv.instant('MELANOMA-CALC.TREATMENT-TYPE.GENERAL.NO');
      }
    }
    if (this.dataSource.data.treatmentType[treatment].inCourse) {
      return this.translateSrv.instant('MELANOMA-CALC.TREATMENT-TYPE.GENERAL.YES');
    } else {
      return this.translateSrv.instant('MELANOMA-CALC.TREATMENT-TYPE.GENERAL.NO');
    }
  }

  getMedicalTreatmentState(medicalTreatment: any) {
    if (medicalTreatment.state) {
      return this.translateSrv.instant('MELANOMA-CALC.TREATMENT-TYPE.GENERAL.LABEL-INCOURSE');
    } else {
      return this.translateSrv.instant('MELANOMA-CALC.TREATMENT-TYPE.GENERAL.LABEL-FINALIZED');
    }
  }

  getLevelClark(key?: string) {
    if (key === 'none') {
      return this.translateSrv.instant('MELANOMA-CALC.HISTOLOGY.NOT-SPECIFIED');
    } else {
      return key;
    }
  }

  getBreslow = (key?: number) => {
    if (!key) {
      return '';
    }
    return this.breslow.find((item) => item.key === key).value;
  };

  getUlcerationValue(id?: number) {
    switch (id) {
      case 0:
        return 'MELANOMA-CALC.TREATMENT-TYPE.GENERAL.YES';
      case 1:
        return 'MELANOMA-CALC.TREATMENT-TYPE.GENERAL.NO';
      case 2:
        return 'MELANOMA-CALC.HISTOLOGY.INDETERMINATED';
      default:
        return '';
    }
  }

  getNodesValue(histology?: any): string {
    if (histology?.lymphnodeDetail.length) {
      return this.nodalInvolvementOptions.find((item) => item.key === histology.lymphnodeDetail).value;
    } else {
      return '';
    }
    /* if (histology?.hasLymphnodes === 0) {
      return this.listGanglionOptions.find((item) => item.key === histology.lymphnodeDetail).value;
    } else {
      return '';
    } */
  }

  getMetastesesValue(histology?: any): string {
    if (histology?.metasteseDetail?.length) {
      const metastesOption = this.metastesesOptions.find((item) => item.key === histology.metasteseDetail);
      if (metastesOption) {
        return metastesOption.value;
      } else {
        return '';
      }
    } else {
      return '';
    }
    /* if (histology?.hasMetasteses === 0) {
      if (histology.metasteseDetail === 0) {
        return 'MELANOMA-CALC.HISTOLOGY.HAVE-METASTESES.LOCAL';
      } else {
        return 'MELANOMA-CALC.HISTOLOGY.HAVE-METASTESES.DISTANCE';
      }
    } else {
      return '';
    } */
  }

  backToEdit() {
    this.dataToEdit.emit(this.dataSource.data);
  }
}
