import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-detail-case-header',
  templateUrl: './detail-case-header.component.html',
  styleUrls: ['./detail-case-header.component.scss']
})
export class DetailCaseHeaderComponent implements OnInit {
  @Input() idCase = '';
  @Input() sticky = false;
  @Output() downloadPDF = new EventEmitter<any>();
  @Output() openSimilarPricing: EventEmitter<any> = new EventEmitter<any>();
  constructor(private router: Router) {}

  ngOnInit() {}

  goToWorkarea() {
    this.router.navigate(['work-area']);
  }

  async download() {
    this.downloadPDF.emit(true);
  }

  openSimilarPrincing() {
    this.openSimilarPricing.emit();
  }
}
