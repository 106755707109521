import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-reopen-case',
  templateUrl: './modal-reopen-case.component.html',
  styleUrls: ['./modal-reopen-case.component.scss']
})
export class ModalReopenCaseComponent implements OnInit {
  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<ModalReopenCaseComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {}

  ngOnInit() {
    this.form = new FormGroup({
      comments: new FormControl('', [Validators.required, Validators.maxLength(2000)])
    });
  }

  reopenCase() {
    if (this.form.valid) {
      this.dialogRef.close({ ...this.form.value, idCase: this.data });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
