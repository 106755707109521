import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { IDataFABMenu } from '../maresel-header-logo/maresel-header-logo.component';

@Component({
  selector: 'app-user-fab',
  templateUrl: './user-fab.component.html',
  styleUrls: ['./user-fab.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFabComponent {
  @Input() nameInitials = '';
  @Input() fabMenuData: IDataFABMenu;
  constructor(public authenticationService: AuthenticationService, private router: Router) {}

  goToWorkArea() {
    this.router.navigate(['/work-area']);
  }
}
