import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SearchuserUsers } from '@app/core/services/api';
import { CancelregisteruserService } from '@app/core/services/api/api/cancelregisteruser.service';
import { RemoveuserService } from '@app/core/services/api/api/removeuser.service';

import { ModalService } from '@app/core/services/modal/modal.service';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { IModelPaginator } from '@app/pages/user-management/user-management.page';
import { TranslateService } from '@ngx-translate/core';

export interface IHeaderSort {
  active: string;
  direction: 'asc' | 'desc' | '';
}
@Component({
  selector: 'app-maresel-table-users',
  templateUrl: './maresel-table-users.component.html',
  styleUrls: ['./maresel-table-users.component.scss']
})
export class MareselTableUsersComponent implements OnInit, OnChanges {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() data: SearchuserUsers[];
  @Input() numPages: number;
  @Output() sortPaginator: EventEmitter<IModelPaginator> = new EventEmitter();
  dataSortPaginator: IModelPaginator;
  displayedColumns: string[] = ['nombre', 'apellido1', 'apellido2', 'pais', 'empresa', 'email', 'state', 'fecha', 'menu'];
  dataSource: MatTableDataSource<any>;

  constructor(
    private modalSrv: ModalService,
    private translateSrv: TranslateService,
    private cancelUserSrv: CancelregisteruserService,
    private removerUserSrv: RemoveuserService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.dataSortPaginator = {
      page: 1,
      itemsPerPage: 10,
      active: 'nombre',
      direction: 'asc'
    };
  }
  ngOnChanges() {
    if (this.data) {
      this.dataSource = new MatTableDataSource(this.data);
    }
  }

  getUserSubject(state: number): string {
    return this.translateSrv.instant(`STATES.${state}`);
  }

  typeOf(value) {
    return typeof value;
  }

  instanceOfError(data: any) {
    if (!data) {
      return true;
    } else {
      return 'err' in data;
    }
  }

  sortData(event: IHeaderSort) {
    // console.log('data to sort', event);
    this.dataSortPaginator.active = event.active;
    this.dataSortPaginator.direction = event.direction;
    this.sortPaginator.emit(this.dataSortPaginator);
  }

  loadData(event) {
    this.dataSortPaginator.page = event.page;
    this.dataSortPaginator.itemsPerPage = event.itemsPerPage;
    this.sortPaginator.emit(this.dataSortPaginator);
  }

  openData(row: SearchuserUsers) {
    this.modalSrv.openModalDataUser({ user: row, readOnly: true });
  }

  openSubscribeModal(row: SearchuserUsers) {
    this.modalSrv.openModalSubscribeUser(row);
  }

  openUnSubscribeModal(row: SearchuserUsers) {
    this.modalSrv.openModalInfo(
      this.translateSrv.instant('MODAL-WARNING.TITLE'),
      this.translateSrv.instant('MODAL-WARNING.MSG-DELETE-USER'),
      {
        name: this.translateSrv.instant('MODAL.BTN-CONFIRM'),
        f: () => {
          /* llamar servicio aqui y cerrar modal*/
          const spinner = this.spinnerService.showSpinner();
          this.removerUserSrv.removeuserPost({ email: row.email }).subscribe(
            (resp) => {
              if (this.instanceOfError(resp)) {
                spinner.close();
                this.modalSrv.openModalDanger('ERROR.ERROR', 'MODAL.ERROR.UNSUBSCRIBLE');
              } else {
                spinner.close();
                this.modalSrv.refreshUsers$.next(true);
                this.modalSrv.openModalSuccess('MODAL.TTL-REG-USER-SUCCESS', 'MODAL.SUCCESS.UNSUBSCRIBLE');
              }
            },
            (err) => {
              spinner.close();
              this.modalSrv.openModalDanger('ERROR.ERROR', err);
            }
          );
        }
      },
      {
        name: this.translateSrv.instant('MODAL.BTN-CANCEL'),
        f: () => {}
      }
    );
  }

  openModifyModal(row: SearchuserUsers) {
    this.modalSrv.openModalDataUser({ user: row, readOnly: false });
  }

  openCancelSuscribeModal(row: SearchuserUsers) {
    this.modalSrv.openModalInfo(
      this.translateSrv.instant('MODAL-WARNING.TITLE'),
      this.translateSrv.instant('MODAL-WARNING.MSG-CANCEL-USER'),
      {
        name: this.translateSrv.instant('MODAL.BTN-CONFIRM'),
        f: () => {
          /* llamar servicio aqui y cerrar modal*/
          const spinner = this.spinnerService.showSpinner();
          this.cancelUserSrv.cancelregisteruserPost({ email: row.email }).subscribe(
            (resp) => {
              spinner.close();
              if (resp === null) {
                this.modalSrv.openModalDanger('ERROR.ERROR', 'No ha sido posible dar de baja');
              } else {
                this.modalSrv.refreshUsers$.next(true);
                // this.dialogRef.close();
              }
            },
            (err) => {
              spinner.close();
              console.log(err);
              this.modalSrv.openModalDanger('ERROR.ERROR', err);
            }
          );
        }
      },
      {
        name: this.translateSrv.instant('MODAL.BTN-CANCEL'),
        f: () => {}
      }
    );
  }
}
