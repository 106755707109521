import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ModalService } from '@app/core/services/modal/modal.service';

@Component({
  selector: 'app-maresel-dynamic-integration',
  templateUrl: './maresel-dynamic-integration.component.html',
  styleUrls: ['./maresel-dynamic-integration.component.scss']
})
export class MareselDynamicIntegrationComponent implements OnInit {
  _contentHtml: SafeHtml = '';
  @Input() set contentHtml(value: string) {
    this.sanitizerHtml(value);
    this.addEvents();
  }

  @ViewChild('template') myTemplate: ElementRef;
  _observer: MutationObserver;

  constructor(private sanitizer: DomSanitizer, private modalService: ModalService) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this._observer = new MutationObserver((mutations) => {
      // console.log(mutations);
      mutations.forEach((mutation, index) => {
        if (mutation.type === 'childList') {
          this.addEvents();
        }
      });
    });

    this._observer.observe(this.myTemplate?.nativeElement, { attributes: true, childList: true, characterData: true });
  }

  ngOnDestroy() {
    if (this._observer) {
      this._observer.disconnect();
    }
  }

  sanitizerHtml(value: string) {
    const parser = new DOMParser();
    const document = parser.parseFromString(value, 'text/html');
    this._contentHtml = this.sanitizer.bypassSecurityTrustHtml(document.body.outerHTML);
  }

  addEvents() {
    if (this.myTemplate?.nativeElement) {
      this.myTemplate?.nativeElement.querySelectorAll('[\\(click\\)]').forEach((element) => {
        let click = element.getAttribute('(click)');
        if (click.indexOf("openShadow('") !== -1) {
          click = click.replace("openShadow('", '').replace("')", '');
        }
        element.addEventListener('click', () => {
          this.openShadow(click);
        });
      });
      this.myTemplate?.nativeElement.querySelectorAll('td ul').forEach((element) => {
        let tr = element.parentElement.parentElement;
        if (!tr.classList.contains('hauto')) {
          tr.classList.add('hauto');
        }
      });
    }
  }

  openShadow(value: string) {
    this.modalService.openModalDynamicIntegration(value);
  }
}
