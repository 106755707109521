import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
@Component({
  selector: 'app-tooltip-info',
  templateUrl: './tooltip-info.component.html',
  styleUrls: ['./tooltip-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TooltipInfoComponent implements OnInit {
  @Input('message') message: string = '';
  @Input('position') position: TooltipPosition = 'left';

  constructor() {}

  ngOnInit() {}
}
