export * from './activity.service';
import { ActivityService } from './activity.service';
export * from './articles.service';
import { ArticlesService } from './articles.service';
export * from './cancelregisteruser.service';
import { CancelregisteruserService } from './cancelregisteruser.service';
export * from './cases.service';
import { CasesService } from './cases.service';
export * from './cedentes.service';
import { CedentesService } from './cedentes.service';
import { ComboRateService } from './comboRate.service';
export * from './comboRisk.service';
import { ComboRiskService } from './comboRisk.service';
export * from './contacRequest.service';
import { ContacRequestService } from './contacRequest.service';
export * from './contenido.service';
import { ContenidoService } from './contenido.service';
export * from './country.service';
import { CountryService } from './country.service';
export * from './coverages.service';
import { CoveragesService } from './coverages.service';
export * from './createtarifa.service';
import { CreatetarifaService } from './createtarifa.service';
export * from './currencies.service';
import { CurrenciesService } from './currencies.service';
export * from './duration.service';
import { DurationService } from './duration.service';
export * from './faq.service';
import { FaqService } from './faq.service';
export * from './forms.service';
import { FormsService } from './forms.service';
export * from './forgotPassword.service';
import { ForgotPasswordService } from './forgotPassword.service';
export * from './home.service';
import { HomeService } from './home.service';
export * from './lang.service';
import { LangService } from './lang.service';
export * from './login.service';
import { LoginService } from './login.service';
export * from './medicamentos.service';
import { MedicamentosService } from './medicamentos.service';
export * from './melanoma.service';
import { MelanomaService } from './melanoma.service';
export * from './menus.service';
import { MenusService } from './menus.service';
export * from './metabolico.service';
import { MetabolicoService } from './metabolico.service';
export * from './modifyuser.service';
import { ModifyuserService } from './modifyuser.service';
export * from './operandos.service';
import { OperandosService } from './operandos.service';
export * from './rates.service';
import { RatesService } from './rates.service';
export * from './refreshToken.service';
import { RefreshTokenService } from './refreshToken.service';
export * from './registercase.service';
import { RegistercaseService } from './registercase.service';
export * from './registeruser.service';
import { RegisteruserService } from './registeruser.service';
export * from './removeuser.service';
import { RemoveuserService } from './removeuser.service';
export * from './roles.service';
import { RolesService } from './roles.service';
export * from './search.service';
import { SearchService } from './search.service';
export * from './searchcase.service';
import { SearchcaseService } from './searchcase.service';
export * from './searchUser.service';
import { SearchuserService } from './searchUser.service';
export * from './signup.service';
import { SignupService } from './signup.service';
export * from './validateuser.service';
import { ValidateuserService } from './validateuser.service';
export * from './verificacode.service';
import { VerificacodeService } from './verificacode.service';
export * from './verpass.service';
import { VerpassService } from './verpass.service';
export const APIS = [
  ActivityService,
  ArticlesService,
  CancelregisteruserService,
  CasesService,
  CedentesService,
  ComboRateService,
  ComboRiskService,
  ContacRequestService,
  ContenidoService,
  CountryService,
  CoveragesService,
  CreatetarifaService,
  CurrenciesService,
  DurationService,
  FaqService,
  FormsService,
  ForgotPasswordService,
  HomeService,
  LangService,
  LoginService,
  MedicamentosService,
  MelanomaService,
  MenusService,
  MetabolicoService,
  ModifyuserService,
  OperandosService,
  RatesService,
  RefreshTokenService,
  RegistercaseService,
  RegisteruserService,
  RemoveuserService,
  RolesService,
  SearchService,
  SearchcaseService,
  SearchuserService,
  SignupService,
  ValidateuserService,
  VerificacodeService,
  VerpassService
];
