/* eslint-disable radix */
import { DecimalPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import {AbstractControl, FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import { CalcValidators } from '@app/core/directives/validators/calc-validators';

@Component({
  selector: 'app-metabolic-calc-blood-pressure',
  templateUrl: './metabolic-calc-blood-pressure.component.html',
  styleUrls: ['./metabolic-calc-blood-pressure.component.scss']
})
export class MetabolicCalcBloodPressureComponent implements OnInit, AfterViewInit {
  @Input() data;

  systolicMin = 60;
  systolicMax = 250;
  diastolicMin = 40;
  diastolicMax = 150;

  bloodPressure: FormGroup = new FormGroup({
    systolic: new FormGroup({
      first: new FormControl('', [CalcValidators.systolic(this.systolicMin, this.systolicMax)]),
      second: new FormControl('', [CalcValidators.systolic(this.systolicMin, this.systolicMax)]),
      third: new FormControl('', [CalcValidators.systolic(this.systolicMin, this.systolicMax)]),
      average: new FormControl({ disabled: true, value: 0 })
    }),
    diastolic: new FormGroup({
      first: new FormControl('', [CalcValidators.diastolic(this.diastolicMin, this.diastolicMax)]),
      second: new FormControl('', [CalcValidators.diastolic(this.diastolicMin, this.diastolicMax)]),
      third: new FormControl('', [CalcValidators.diastolic(this.diastolicMin, this.diastolicMax)]),
      average: new FormControl({ disabled: true, value: 0 })
    }),
    treatmentBloodPressure: new FormControl()
  });

  constructor(
    private formGroupDirective: FormGroupDirective,
    private _decimalPipe: DecimalPipe,
    @Inject(LOCALE_ID) private locale: string,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.formGroupDirective.form) {
      this.formGroupDirective.form.setControl('bloodPressure', this.bloodPressure);
    }

    this.bloodPressure.controls.systolic.valueChanges.subscribe((value) => {
      if (value !== '' || typeof value === 'number') {
        const { first, second, third } = value;
        this.checkFieldChanges(first, 'first');
        this.checkFieldChanges(second, 'second');
        this.checkFieldChanges(third, 'third');
        (this.bloodPressure.controls.systolic as FormGroup).controls.average.setValue(this.calcAvg(value), { emitEvent: false });
      }
    });

    this.bloodPressure.controls.diastolic.valueChanges.subscribe((value) => {
      if (value !== '' || typeof value === 'number') {
        const { first, second, third } = value;
        this.checkFieldChanges(first, 'first');
        this.checkFieldChanges(second, 'second');
        this.checkFieldChanges(third, 'third');
        (this.bloodPressure.controls.diastolic as FormGroup).controls.average.setValue(this.calcAvg(value), { emitEvent: false });
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.data) {
      this.bloodPressure.patchValue(this.data);
    }
    this.cd.detectChanges();
  }

  checkFieldChanges(value: number | null | '', field: string) {
    if (typeof value === 'number' && value >= 0) {
      this.addRequiredValidator(
        (this.bloodPressure.controls.diastolic as FormGroup).controls[field],
        (this.bloodPressure.controls.systolic as FormGroup).controls[field]
      );
      this.addFirstSystolicValidator((this.bloodPressure.controls.systolic as FormGroup).controls[field]);
      this.addFirstDiastolicValidator( (this.bloodPressure.controls.diastolic as FormGroup).controls[field]);
    } else {
      this.removeRequiredValidator(
        (this.bloodPressure.controls.diastolic as FormGroup).controls[field],
        (this.bloodPressure.controls.systolic as FormGroup).controls[field]
      );
    }


  }

  isRequired(control: AbstractControl) {
    try {
      return control.hasValidator(Validators.required);
    } catch (error) {
      return false;
    }
  }

  addRequiredValidator(controlDiastolic: AbstractControl, controlSystolic: AbstractControl) {
    if (!controlDiastolic.hasValidator(Validators.required)) {
      controlDiastolic.addValidators(Validators.required);
      controlDiastolic.updateValueAndValidity({ emitEvent: false });
    }
    if (!controlSystolic.hasValidator(Validators.required)) {
      controlSystolic.addValidators(Validators.required);
      controlSystolic.updateValueAndValidity({ emitEvent: false });
    }
    this.cd.detectChanges();
  }

  addFirstSystolicValidator(control: AbstractControl) {
    control.addValidators([CalcValidators.systolic(this.systolicMin, this.systolicMax)]);
    control.updateValueAndValidity({ emitEvent: false });
  }

  addFirstDiastolicValidator(control: AbstractControl) {
    control.addValidators([CalcValidators.diastolic(this.diastolicMin, this.diastolicMax)]);
    control.updateValueAndValidity({ emitEvent: false });
  }

  removeRequiredValidator(controlDiastolic: AbstractControl, controlSystolic: AbstractControl) {
    const firstSystolic = controlSystolic.value;
    const firstDiastolic = controlDiastolic.value;

    if (typeof firstSystolic !== 'number' && typeof firstDiastolic !== 'number') {
      if (controlSystolic.hasValidator(Validators.required)) {
        controlSystolic.removeValidators([Validators.required]);
        this.addFirstSystolicValidator(controlSystolic);
        controlSystolic.updateValueAndValidity({ emitEvent: false });
      }
      if (controlDiastolic.hasValidator(Validators.required)) {
        controlDiastolic.removeValidators([Validators.required]);
        this.addFirstDiastolicValidator(controlDiastolic);
        controlDiastolic.updateValueAndValidity({ emitEvent: false });
      }
      this.cd.detectChanges();
    }
  }

  /* Cuando tiene tratamiento no es necesario siempre que tengan sistolica o diastolica, por lo tanto, no deberia realizarse ninguna
     validación en este campo a los otros campos del formulario

  onChangeCheckboxTreatment(value: boolean) {
    if (value) {
      this.addRequiredValidator(
        (this.bloodPressure.controls.diastolic as FormGroup).controls['first'],
        (this.bloodPressure.controls.systolic as FormGroup).controls['first']
      );
    } else {
      const firstSystolic = (this.bloodPressure.controls.systolic as FormGroup).controls['first'].value;
      const firstDiastolic = (this.bloodPressure.controls.diastolic as FormGroup).controls['first'].value;
      if (typeof firstSystolic !== 'number' && typeof firstDiastolic !== 'number') {
        this.removeRequiredValidator(
          (this.bloodPressure.controls.diastolic as FormGroup).controls['first'],
          (this.bloodPressure.controls.systolic as FormGroup).controls['first']
        );
      }
    }
  }
  */

  calcAvg(values: any): number {
    const sum: number =
      parseInt(values.first ? values.first : '0') +
      parseInt(values.second ? values.second : '0') +
      parseInt(values.third ? values.third : '0');
    const totalElements =
      (parseInt(!isNaN(values.first) ? values.first : '-1') >= 0 ? 1 : 0) +
      (parseInt(!isNaN(values.second) ? values.second : '-1') >= 0 ? 1 : 0) +
      (parseInt(!isNaN(values.third) ? values.third : '-1') >= 0 ? 1 : 0);
    if (totalElements === 0) {
      return 0;
    } else {
      let avg: number = sum / totalElements;
      avg = Math.round(avg);
      return avg;
    }
  }
}
