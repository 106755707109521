import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormUtilsService {
  constructor() {}

  dateRangeValidator(date: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let invalid = false;
      const from = control.value ? control.value : '';
      if (from && date) {
        invalid = new Date(from).valueOf() < new Date(date).valueOf();
      }
      return invalid ? { invalidRange: { from, date } } : null;
    };
  }
}
