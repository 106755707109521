/* eslint-disable prefer-const */
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { CalcValidators } from '@app/core/directives/validators/calc-validators';
import { Mensaje, MetabolicoService } from '@app/core/services/api';
import { ModalService } from '@app/core/services/modal/modal.service';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-metabolic-calc-lipidic-profile',
  templateUrl: './metabolic-calc-lipidic-profile.component.html',
  styleUrls: ['./metabolic-calc-lipidic-profile.component.scss']
})
export class MetabolicCalcLipidicProfileComponent implements OnInit {
  @Input() data;

  public _unitMeasurement: string;
  @Input('unitMeasurement') set unitMeasurement(value: string) {
    this._unitMeasurement = value;
    this.lipidicProfile.controls.colesterolTotal.clearValidators();
    this.lipidicProfile.controls.HDL.clearValidators();
    this.lipidicProfile.controls.triglycerides.clearValidators();
    if (value === 'mmol/l') {
      this.lipidicProfile.controls.colesterolTotal.addValidators([CalcValidators.colesterol(2.6, 15.6, value)]);
      this.lipidicProfile.controls.HDL.addValidators([CalcValidators.hdl(0.31, 5.2, value)]);
      this.lipidicProfile.controls.triglycerides.addValidators([CalcValidators.triglycerides(0.33, 16.85, value)]);
    } else if (value === 'mg/dl') {
      this.lipidicProfile.controls.colesterolTotal.addValidators([CalcValidators.colesterol(100, 600, value)]);
      this.lipidicProfile.controls.HDL.addValidators([CalcValidators.hdl(12, 200, value)]);
      this.lipidicProfile.controls.triglycerides.addValidators([CalcValidators.triglycerides(30, 1500, value)]);
    }
    this.lipidicProfile.controls.colesterolTotal.updateValueAndValidity({ emitEvent: false });
    this.lipidicProfile.controls.HDL.updateValueAndValidity({ emitEvent: false });
    this.lipidicProfile.controls.triglycerides.updateValueAndValidity({ emitEvent: false });
  }

  public lipidicProfile: FormGroup = new FormGroup({
    colesterolTotal: new FormControl(''),
    HDL: new FormControl(''),
    atherogenicIndex: new FormControl({ disabled: true, value: '' }),
    LDL: new FormControl(''),
    VLDL: new FormControl(''),
    triglycerides: new FormControl(''),
    treatmentTriglycerides: new FormControl(''),
    treatmentColesterol: new FormControl('')
  });

  /*
  messages
  colesterolMessage: string = '';
  HDLMessage: string = '';
  atherogenicIndexMessage: string = '';
  LDLMessage: string = '';
  VLDLMessage: string = '';
  triglyceridesMessage: string = '';
  */

  constructor(
    private formGroupDirective: FormGroupDirective,
    private spinnerService: SpinnerService,
    private modalService: ModalService,
    private metabolicoService: MetabolicoService
  ) {}

  ngOnInit() {

    this.lipidicProfile.controls.treatmentColesterol.setValue(false);
    this.lipidicProfile.controls.treatmentTriglycerides.setValue(false);

    if (this.formGroupDirective.form) {
      this.formGroupDirective.form.setControl('lipidicProfile', this.lipidicProfile);
    }
    this.lipidicProfile.controls.colesterolTotal.valueChanges.pipe(debounceTime(500)).subscribe((value: number) => {
      if (this.lipidicProfile.controls.colesterolTotal.value > 0) {
        this.calcAtherogenicIndex();
      } else {
        /*
        this.atherogenicIndexMessage = '';
        this.HDLMessage = '';
        */
        this.lipidicProfile.controls.atherogenicIndex.setValue('');
      }
    });
    this.lipidicProfile.controls.HDL.valueChanges.pipe(debounceTime(500)).subscribe((value: number) => {
      if (this.lipidicProfile.controls.HDL.value > 0) {
        this.calcAtherogenicIndex();
      } else {
        /*
        this.atherogenicIndexMessage = '';
        this.HDLMessage = '';
        */
        this.lipidicProfile.controls.atherogenicIndex.setValue('');

      }
    });
    /*
    this.lipidicProfile.controls.LDL.valueChanges.pipe(debounceTime(500)).subscribe((value: number) => {
      this.calcLDL(value);
    });
    this.lipidicProfile.controls.VLDL.valueChanges.pipe(debounceTime(500)).subscribe((value: number) => {
      this.calcVLDL(value);
    });
    this.lipidicProfile.controls.triglycerides.valueChanges.pipe(debounceTime(500)).subscribe((value: number) => {
      if (this.lipidicProfile.controls.triglycerides.valid) {
        this.calcTriglycerides(value);
      }
    });
    */
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit(): void {
    if (this.data) {
      this.lipidicProfile.patchValue(this.data);
    }
  }

  // Calcula el indice Aterogenico y lanza el spinner
  calcAtherogenicIndex() {
    let colesterolTotal = this.lipidicProfile.controls.colesterolTotal.value;
    let HDL = this.lipidicProfile.controls.HDL.value;
    if (colesterolTotal > 0 && HDL > 0) {
      // this.calcColesterol(colesterolTotal);
      // this.calcHDL(HDL);
      let body = this.formGroupDirective?.form.getRawValue();
      delete body.basicData?.birthDate;
      let loading = this.spinnerService.showSpinner();
      this.metabolicoService.indiceAterogenicoPost(body).subscribe(
        (response: Mensaje) => {
          // this.lipidicProfile.controls.atherogenicIndex.setValue(response.value);
          if (response.value && typeof response.value === 'object') {
            this.lipidicProfile.controls.atherogenicIndex.setValue((response.value as any).ia);
            loading.close();
          } else {
            this.lipidicProfile.controls.atherogenicIndex.setValue(response.value);
            loading.close();
          }
        },
        (error) => {
          this.modalService.openModalDanger('Error', error);
          loading.close();
        }
      );
    }
  }

  /*
  Se comentan las lineas a dia 11/09/2023 para ceñirse al cambio del documento metabolico V8.1
  calcColesterol(value: number) {
    let spinner = this.spinnerService.showSpinner();
    let body = this.formGroupDirective?.form.getRawValue();
    delete body.basicData?.birthDate;
    this.metabolicoService.colesterolPost(body).subscribe(
      (response: Mensaje) => {
        this.colesterolMessage = response.text;
        spinner.close();
      },
      (error) => {
        spinner.close();
        this.modalService.openModalDanger('Error', error);
      }
    );
  }
  calcHDL(value: number) {
    let spinner = this.spinnerService.showSpinner();
    let body = this.formGroupDirective?.form.getRawValue();
    delete body.basicData?.birthDate;
    this.metabolicoService.hDLPost(body).subscribe(
      (response: Mensaje) => {
        this.HDLMessage = response.text;
        spinner.close();
      },
      (error) => {
        spinner.close();
        this.modalService.openModalDanger('Error', error);
      }
    );
  }*/

  /*
  calcLDL(value: number) {
    let spinner = this.spinnerService.showSpinner();
    let body = this.formGroupDirective?.form.getRawValue();
    delete body.basicData?.birthDate;
    this.metabolicoService.lDLPost(body).subscribe(
      (response: Mensaje) => {
        this.LDLMessage = response.text;
        spinner.close();
      },
      (error) => {
        spinner.close();
        this.modalService.openModalDanger('Error', error);
      }
    );
  }
  calcVLDL(value: number) {
    let spinner = this.spinnerService.showSpinner();
    let body = this.formGroupDirective?.form.getRawValue();
    delete body.basicData?.birthDate;
    this.metabolicoService.vLDLPost(body).subscribe(
      (response: Mensaje) => {
        this.VLDLMessage = response.text;
        spinner.close();
      },
      (error) => {
        spinner.close();
        this.modalService.openModalDanger('Error', error);
      }
    );
  }
  calcTriglycerides(value: number) {
    let spinner = this.spinnerService.showSpinner();
    let body = this.formGroupDirective?.form.getRawValue();
    delete body.basicData?.birthDate;
    this.metabolicoService.trigliceridosPost(body).subscribe(
      (response: Mensaje) => {
        this.triglyceridesMessage = response.text;
        spinner.close();
      },
      (error) => {
        spinner.close();
        this.modalService.openModalDanger('Error', error);
      }
    );
  }*/
}
