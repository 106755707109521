import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ActivityService,
  LangService,
  ModifyuserInput,
  ModifyuserService,
  RolesService,
  SearchuserUsers
} from '@app/core/services/api';
import { ICedente } from '@app/core/services/api/model/cedente.model';
import { CedenteService } from '@app/core/services/cedente/cedente.service';
import { ModalService } from '@app/core/services/modal/modal.service';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import {AuthenticationService} from '@core/authentication/authentication.service';

@Component({
  selector: 'app-modal-data-user',
  templateUrl: './modal-data-user.component.html',
  styleUrls: ['./modal-data-user.component.scss']
})
export class ModalDataUserComponent implements OnInit {
  isState0 = false;

  userForm: FormGroup;
  roles: string[] = [];
  cedentes: ICedente[];
  languages: Array<string>;
  activities: string[] = [];

  constructor(
    private activitySrv: ActivityService,
    private modalSrv: ModalService,
    private translateSrv: TranslateService,
    private cedenteSrv: CedenteService,
    public dialogRef: MatDialogRef<ModalDataUserComponent>,
    private modifyUserSrv: ModifyuserService,
    private spinnerService: SpinnerService,
    private roleSrv: RolesService,
    private langService: LangService,
    private authSrv: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: { user: SearchuserUsers; readOnly?: boolean }
  ) {}

  ngOnInit() {
    const loader = this.spinnerService.showSpinner();
    this.checkState();
    forkJoin([
      this.activitySrv.activityGet(),
      this.roleSrv.rolesGet(),
      this.langService.langGet()
    ]).subscribe(([activities, roles, lang]) => {
      this.activities = [...activities];
      this.cedentes = [...this.cedenteSrv.cedentes];
      this.roles = [...roles];
      this.languages = lang;

      if (!this.cedentes.length || !this.roles.length) {
        loader.close();
        this.modalSrv
          .openModalDanger('ERROR.ERROR', 'ERROR.MODAL.ERROR-DATA-MODIFY-USER')
          .afterClosed()
          .subscribe(() => this.close());
      } else {
        const centeObj: ICedente = this.cedentes.find((item) => item.key?.toString() === this.data.user.cedente?.toString());
        this.userForm = new FormGroup({
          nombre: new FormControl(this.data.user.nombre, [Validators.required]),
          apellido1: new FormControl(this.data.user.apellido1, [Validators.required]),
          apellido2: new FormControl(this.data.user.apellido2, []),
          pais: new FormControl(this.data.user.pais, [Validators.required]),
          empresa: new FormControl(this.data.user.empresa, [Validators.required]),
          cedente: new FormControl(this.data.user.cedente ? centeObj : '', [Validators.required]),
          rol: new FormControl(this.data.user.rol, [Validators.required]),
          actividad: new FormControl(this.data.user.actividad, [Validators.required]),
          email: new FormControl(this.data.user.email, [Validators.required, Validators.email]),
          idioma: new FormControl(this.data.readOnly ? this.translateSrv.instant(this.data.user.idioma) : this.data.user.idioma, [
            Validators.required
          ])
        });
        if (this.data.readOnly && this.data.user.cedente) {
          this.userForm.get('cedente').setValue(this.cedentes.find((item) => item.key === this.data.user.cedente));
        }
        loader.close();
      }
    });
  }

  checkState() {
    this.isState0 = this.data.user?.state === 0 ? true : false;
  }

  modifyUser() {
    if (this.userForm.valid) {
      const spinner = this.spinnerService.showSpinner();
      const body: ModifyuserInput = {
        ...this.userForm.value,
        cedente: this.userForm.value.cedente?.key
      };
      this.modifyUserSrv.modifyuserPost(body).subscribe(
        (resp) => {
          spinner.close();
          if ((resp as any).errorMessage) {
            // console.log((resp as any).errorMessage);
            this.modalSrv.openModalDanger('ERROR.ERROR', (resp as any).errorMessage);
          } else {
            this.dialogRef.close();
          }
        },
        (err) => {
          spinner.close();
          console.log(err);
          this.modalSrv.openModalDanger('ERROR.ERROR', err.errorMessage);
        }
      );
    }
  }

  isMapfreUser(): boolean {
    return this.authSrv.isMapfreDomian(this.data.user.email);
  }

  close() {
    this.dialogRef.close();
  }

  compareFnKey(x, y) {
    return x && y ? x?.key === y?.key : x === y;
  }
}
