/**
 * servicios menus y contenido
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: csgj@gft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MetabolicoInputBasicData } from './metabolicoInputBasicData';
import { MetabolicoInputBloodPressure } from './metabolicoInputBloodPressure';
import { MetabolicoInputGlycemicProfile } from './metabolicoInputGlycemicProfile';
import { MetabolicoInputKidneyProfile } from './metabolicoInputKidneyProfile';
import { MetabolicoInputLipidGlycemicProfile } from './metabolicoInputLipidGlycemicProfile';
import { MetabolicoInputLipidicProfile } from './metabolicoInputLipidicProfile';
import { MetabolicoInputLiverProfile } from './metabolicoInputLiverProfile';

export interface MetabolicoInput {
  basicData?: MetabolicoInputBasicData;
  bloodPressure?: MetabolicoInputBloodPressure;
  lipidGlycemicProfile?: MetabolicoInputLipidGlycemicProfile;
  lipidicProfile?: MetabolicoInputLipidicProfile;
  glycemicProfile?: MetabolicoInputGlycemicProfile;
  liverProfile?: MetabolicoInputLiverProfile;
  kidneyProfile?: MetabolicoInputKidneyProfile;
}
