export class Product {
  public id: string;
  public name?: string;
  public description?: string;
  public parentId?: string;

  constructor(src: any) {
    this.id = src.id;
    this.name = src.name;
    this.description = src.description;
    this.parentId = src.parentId;
  }

  public updateFrom(src: Product): void {
    this.id = src.id;
    this.name = src.name;
    this.description = src.description;
    this.parentId = src.parentId;
  }
}
