import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';

@Component({
  selector: 'app-nav-tab-filter',
  templateUrl: './nav-tab-filter.component.html',
  styleUrls: ['./nav-tab-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavTabFilterComponent implements OnInit {
  @Output() search = new EventEmitter<any>();
  @Output() newCase = new EventEmitter<any>();
  @Output() showFilter = new EventEmitter<boolean>();

  states = [
    { key: -1, value: 'WORKAREA.SEEKER.STATE.ALL' },
    { key: 0, value: 'WORKAREA.SEEKER.STATE.OPENED' },
    { key: 2, value: 'WORKAREA.SEEKER.STATE.PENDING' },
    { key: 3, value: 'WORKAREA.SEEKER.STATE.REVIEWED' },
    { key: 4, value: 'WORKAREA.SEEKER.STATE.CLOSED' }
  ];
  activeLink;
  show: boolean;

  constructor(private _authSrv: AuthenticationService) {}
  @Input() set showCFilter(value) {
    this.show = value;
  }

  ngOnInit() {
    this.activeLink = this.states[0];
  }

  createCase() {
    this.newCase.emit();
  }

  showCommonFilter() {
    this.showCFilter = !this.showCFilter;
    this.showFilter.emit(true);
  }

  filter(data) {
    this.activeLink = data;
    if (data.key >= 0) {
      this.search.emit(data.key);
    } else {
      this.search.emit();
    }
  }

  canCreateCase() {
    return (
      this._authSrv.checkRolUser('Administrador') || this._authSrv.checkRolUser('Suscriptor') || this._authSrv.checkRolUser('Jefe de suscriptores')
    );
  }
}
