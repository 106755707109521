import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { throwError, Observable, from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as Globals from '@global/constants';
import { Login } from '../services/api';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public authService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(request, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {

    if (this.authService.currentTokenValue && !req.url.includes('refreshToken')) {
      req = await this.addToken(req, this.authService.currentTokenValue);
    }

    let obs = next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // logOut
        }
        return throwError(error);
      })
    );

    return await obs.toPromise();
  }

  private addToken(request: HttpRequest<any>, token: string): Promise<HttpRequest<any>> {
    return new Promise((resolve, reject) => {
      if (this.authService.authType === 'COGNITO' && !this.authService.checkToken(this.authService.currentTokenValue).valid) {
        this.authService.refreshToken().subscribe({
          next: (data: Login) => {
            console.log(data);
            this.authService.storeTokens(data);
            resolve(request.clone({
              setHeaders: {
                Authorization: `${Globals.AUTHORIZATION} ${data.jwtToken}`
              }
            }));
          },
          error: (err) => {
            reject(err)
          }
        });
      } else {
        resolve(request.clone({
          setHeaders: {
            Authorization: `${Globals.AUTHORIZATION} ${token}`
          }
        }));
      }
    })
  }
}
