import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ButtonModal {
  name: string;
  f: Function;
}

export interface DataGenericModal {
  title: string;
  subtitle: string;
  type: string;
  mainButton: ButtonModal;
  secondaryButton: ButtonModal;
}

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<GenericModalComponent>, @Inject(MAT_DIALOG_DATA) public data: DataGenericModal) {}
  ngOnInit() {}
  close() {
    this.dialogRef.close();
  }
}
