/**
 * servicios menus y contenido
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: csgj@gft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { AddRatesCaseInput } from '../model/addRatesCaseInput';
import { CaseCommentsInput } from '../model/caseCommentsInput';
import { CasefinalresultInput } from '../model/casefinalresultInput';
import { CloseCaseInput } from '../model/closeCaseInput';
import { InlineResponse20022 } from '../model/inlineResponse20022';
import { InlineResponse20023 } from '../model/inlineResponse20023';
import { InlineResponse20024 } from '../model/inlineResponse20024';
import { InlineResponse20025 } from '../model/inlineResponse20025';
import { InlineResponse20026 } from '../model/inlineResponse20026';
import { InlineResponse20027 } from '../model/inlineResponse20027';
import { InlineResponse20028 } from '../model/inlineResponse20028';
import { InlineResponse20029 } from '../model/inlineResponse20029';
import { InlineResponse20030 } from '../model/inlineResponse20030';
import { InlineResponse20031 } from '../model/inlineResponse20031';
import { ModifyCaseInput } from '../model/modifyCaseInput';
import { OpenCaseInput } from '../model/openCaseInput';
import { PendingCaseInput } from '../model/pendingCaseInput';
import { ReminderDoctorBody } from '../model/reminderDoctorBody';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class CasesService {
  protected basePath = environment.apiUrl + environment.apiEnv;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Añade un grupo de tarifas a un caso
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addratescasePost(body: AddRatesCaseInput, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20025>;
  public addratescasePost(body: AddRatesCaseInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20025>>;
  public addratescasePost(body: AddRatesCaseInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20025>>;
  public addratescasePost(body: AddRatesCaseInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling addratescasePost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20025>('post', `${this.basePath}/addratescase`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * Guarda los comentarios
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public caseCommentsPost(body: CaseCommentsInput, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20029>;
  public caseCommentsPost(body: CaseCommentsInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20029>>;
  public caseCommentsPost(body: CaseCommentsInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20029>>;
  public caseCommentsPost(body: CaseCommentsInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling caseCommentsPost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20029>('post', `${this.basePath}/caseComments`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * Guarda los comentarios del resultado
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public casefinalresultPost(body: CasefinalresultInput, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20030>;
  public casefinalresultPost(
    body: CasefinalresultInput,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse20030>>;
  public casefinalresultPost(body: CasefinalresultInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20030>>;
  public casefinalresultPost(body: CasefinalresultInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling casefinalresultPost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20030>('post', `${this.basePath}/casefinalresult`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * cambia el estado a Cerrado
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public closeCasePost(body: CloseCaseInput, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20023>;
  public closeCasePost(body: CloseCaseInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20023>>;
  public closeCasePost(body: CloseCaseInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20023>>;
  public closeCasePost(body: CloseCaseInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling closeCasePost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20023>('post', `${this.basePath}/closeCase`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * Devuelve los datos del caso
   * @param idCase
   * @param lang
   * @param risk
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public consultCaseGet(idCase?: string, lang?: string, risk?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20027>;
  public consultCaseGet(
    idCase?: string,
    lang?: string,
    risk?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse20027>>;
  public consultCaseGet(
    idCase?: string,
    lang?: string,
    risk?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse20027>>;
  public consultCaseGet(idCase?: string, lang?: string, risk?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idCase !== undefined && idCase !== null) {
      queryParameters = queryParameters.set('idCase', <any>idCase);
    }
    if (lang !== undefined && lang !== null) {
      queryParameters = queryParameters.set('lang', <any>lang);
    }
    if (risk !== undefined && risk !== null) {
      queryParameters = queryParameters.set('risk', <any>risk);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20027>('get', `${this.basePath}/consultCase`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * Devuelve los datos del caso
   * @param idCase
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public doctorCaseGet(idCase?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<InlineResponse20028>>;
  public doctorCaseGet(idCase?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InlineResponse20028>>>;
  public doctorCaseGet(idCase?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InlineResponse20028>>>;
  public doctorCaseGet(idCase?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idCase !== undefined && idCase !== null) {
      queryParameters = queryParameters.set('idCase', <any>idCase);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<InlineResponse20028>>('get', `${this.basePath}/doctorCase`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * cambia el estado a inCourse
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public inCourseCasePost(body: CloseCaseInput, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20023>;
  public inCourseCasePost(body: CloseCaseInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20023>>;
  public inCourseCasePost(body: CloseCaseInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20023>>;
  public inCourseCasePost(body: CloseCaseInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling inCourseCasePost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20023>('post', `${this.basePath}/inCourseCase`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * Devuelve los datos basicos
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incourseDataGet(observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20022>;
  public incourseDataGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20022>>;
  public incourseDataGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20022>>;
  public incourseDataGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20022>('get', `${this.basePath}/incourseData`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * Guarda los cambios
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public modifyCasePost(body: ModifyCaseInput, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20031>;
  public modifyCasePost(body: ModifyCaseInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20031>>;
  public modifyCasePost(body: ModifyCaseInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20031>>;
  public modifyCasePost(body: ModifyCaseInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling modifyCasePost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20031>('post', `${this.basePath}/modifyCase`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * cambia el estado a Abierto
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public openCasePost(body: OpenCaseInput, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20023>;
  public openCasePost(body: OpenCaseInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20023>>;
  public openCasePost(body: OpenCaseInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20023>>;
  public openCasePost(body: OpenCaseInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling openCasePost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20023>('post', `${this.basePath}/openCase`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * cambia el estado a Pendiente
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public pendingCasePost(body: PendingCaseInput, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20024>;
  public pendingCasePost(body: PendingCaseInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20024>>;
  public pendingCasePost(body: PendingCaseInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20024>>;
  public pendingCasePost(body: PendingCaseInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling pendingCasePost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20024>('post', `${this.basePath}/pendingCase`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * Manda un recordatorio a un medico.
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public reminderDoctorPost(body: ReminderDoctorBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20026>;
  public reminderDoctorPost(body: ReminderDoctorBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20026>>;
  public reminderDoctorPost(body: ReminderDoctorBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20026>>;
  public reminderDoctorPost(body: ReminderDoctorBody, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling reminderDoctorPost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20026>('post', `${this.basePath}/reminderDoctor`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * cambia el estado a Reviewed Case
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public reviewedCasePost(body: CloseCaseInput, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20023>;
  public reviewedCasePost(body: CloseCaseInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20023>>;
  public reviewedCasePost(body: CloseCaseInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20023>>;
  public reviewedCasePost(body: CloseCaseInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling reviewedCasePost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20023>('post', `${this.basePath}/reviewedCase`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
