/**
 * servicios menus y contenido
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: csgj@gft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ICurrency {
  cod_idioma?: string;
  cln_moneda?: string;
  cla_moneda?: string;
  abr_moneda?: string;
  iso_moneda?: string;
  nom_moneda?: string;
  cln_fecha_contable_ini?: number;
  cln_fecha_contable_fin?: number;
  cod_grupo?: number;
  num_decimales?: number;
  swt_activa?: boolean;
  swt_convertible?: boolean;
  swt_euro?: boolean;
  swt_cambios_usa?: boolean;
}
