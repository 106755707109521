/**
 * servicios menus y contenido
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: csgj@gft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SearchcaseError } from './searchcaseError';
import { Searchcasehistory } from './searchcasehistory';

export type InlineResponse20021 = Searchcasehistory | SearchcaseError;
