/**
 * servicios menus y contenido
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: csgj@gft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ModifyuserInput {
  nombre?: string;
  apellido1?: string;
  apellido2?: string;
  empresa?: string;
  idioma?: string;
  pais?: string;
  actividad?: string;
  email?: string;
  state?: number;
  cedente?: any;
  rol?: string;
  fecha?: string;
}
