import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { CountryService, CoveragesService } from '@app/core/services/api';
import { ICurrency } from '@app/core/services/api/model/iCurrency';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-detail-case-basic-data',
  templateUrl: './detail-case-basic-data.component.html',
  styleUrls: ['./detail-case-basic-data.component.scss']
})
export class DetailCaseBasicDataComponent implements OnInit {
  @Input() data = null;
  @Input() canEdit = false;
  @Input() state: number;
  @Input() showReference = true;
  @Output() saveChange: EventEmitter<any> = new EventEmitter();
  notHasChanges = true;
  editMode = false;
  basicData: FormGroup;
  coveragesContract: FormGroup;
  countries: any[];
  coverages: any[];
  coveragesInCase: any[] = [];
  currencies: ICurrency[];
  currenciesMap: string[];

  constructor(
    private formGroupDirective: FormGroupDirective,
    private countrySrv: CountryService,
    private coverageSrv: CoveragesService,
    private translateSrv: TranslateService
  ) { }

  ngOnInit() {
    forkJoin([this.countrySrv.countryGet(this.translateSrv.currentLang), this.coverageSrv.coveragesGet()]).subscribe(([countries, coverages]) => {
      this.countries = [...countries];
      this.coverages = [...coverages];
    });

    this.basicData = new FormGroup({
      age: new FormControl(this.data.basicData.age, [Validators.required]),
      referenceNumber: new FormControl(this.data.basicData.referenceNumber, [Validators.required]),
      gender: new FormControl(this.data.basicData.gender, [Validators.required]),
      location: new FormControl(this.data.basicData.location, [Validators.required]),
      country: new FormControl(this.data.basicData.country, [Validators.required])
    });
    this.coveragesContract = new FormGroup({
      duration: new FormControl(this.data.coveragesContract.duration, [Validators.required]),
      coverages: new FormArray(
        this.data.coveragesContract.coverages.map((coverage) => {
          this.coveragesInCase.push({ key: coverage.coverage });
          return new FormGroup({
            coverage: new FormControl(coverage.coverage, [Validators.required]),
            capital: new FormControl(coverage.capital, [Validators.required]),
            currency: new FormControl(coverage.currency, [Validators.required])
          });
        }),
        [Validators.required, Validators.min(1)]
      )
    });
    if (this.formGroupDirective.form) {
      this.formGroupDirective.form.setControl('basicData', this.basicData);
      this.formGroupDirective.form.setControl('coveragesContract', this.coveragesContract);
    }
    this.basicData.valueChanges.subscribe(() => this.detectChange());
    this.coveragesContract.valueChanges.subscribe(() => this.detectChange());
    this.coveragesContract.controls['coverages'].valueChanges.subscribe({
      next: (value) => {
        this.coveragesInCase = value.map((item) => ({ key: item.coverage }));
      }
    });
  }

  saveData() {
    this.edit();
    this.saveChange.emit();
  }

  detectChange(): void {
    if (this.notHasChanges) {
      this.notHasChanges = false;
    }
  }

  validForms(): boolean {
    return this.basicData.valid && this.coveragesContract.valid && !this.notHasChanges;
  }

  isInBlackList(key: string) {
    return this.coveragesInCase.find((item) => item.key === key) !== undefined;
  }

  addCoverage(item?: any) {
    (this.coveragesContract.controls['coverages'] as FormArray).push(
      new FormGroup({
        coverage: new FormControl(item ? item.coverage : '', [Validators.required]),
        capital: new FormControl(item ? item.capital : '', [Validators.required]),
        currency: new FormControl(item ? item.currency : '', [Validators.required])
      })
    );
  }

  deleteCoverage(index: number) {
    (this.coveragesContract.controls['coverages'] as FormArray).removeAt(index);
  }

  edit() {
    this.editMode = !this.editMode;
  }

  cancelEdit() {
    this.edit();
    this.basicData.reset({
      age: this.data.basicData.age,
      referenceNumber: this.data.basicData.referenceNumber,
      gender: this.data.basicData.gender,
      location: this.data.basicData.location,
      country: this.data.basicData.country
    });
    this.coveragesContract.reset({
      duration: this.data.coveragesContract.duration
    });
    while (this.coveragesContract.controls['coverages'].value.length) {
      this.deleteCoverage(0);
    }
    this.data.coveragesContract.coverages.map((item) => this.addCoverage(item));
    this.notHasChanges = true;
  }
}
