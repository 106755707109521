import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty'
})
export class EmptyPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value !== null && value !== undefined && value !== '') {
      return value;
    }
    return '-';
  }
}
