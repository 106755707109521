import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { CaseDataService } from '@app/core/services/case-data/case-data.service';
import { TranslateService } from '@ngx-translate/core';
import { CasesService } from '@app/core/services/api/api/cases.service';
import { IncourseData } from '@app/core/services/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-case-detail-tooltip',
  templateUrl: './case-detail-tooltip.component.html',
  styleUrls: ['./case-detail-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CaseDetailTooltipComponent implements OnInit {
  case: IncourseData;
  constructor(private caseSrv: CaseDataService, private translateSrv: TranslateService, private casesSrv: CasesService, private router: Router) {}

  ngOnInit() {
    this.caseSrv.get().subscribe({
      next: (dataCase) => {
        if (dataCase) {
          this.caseSrv.setInStorage(dataCase);
          this.case = dataCase;
        } else {
          this.case = undefined;
        }
      }
    });
  }

  hasCaseInCourse(): boolean {
    return this.caseSrv.hasInCourse();
  }

  goToCase(): void {
    // console.log(this.case);
    this.router.navigate(['case-detail', this.case.idCase]);
  }

  writeCase() {
    return `${this.translateSrv.instant('WORKAREA.REFERENCE')}: ${this.case.referenceNumber} \n ${this.translateSrv.instant('WORKAREA.AGE')}: ${
      this.case.age
    } \n ${this.translateSrv.instant('WORKAREA.GENDER')}: ${this.translateSrv.instant(`GENDER.${this.case.gender}`)} \n ${this.translateSrv.instant(
      'WORKAREA.LOCALITY'
    )}: ${this.case.location}`;
  }
}
