import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchService } from '@app/core/services/api';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-content-search',
  templateUrl: './content-search.component.html',
  styleUrls: ['./content-search.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'true',
        style({
          overflow: 'hidden',
          height: '*',
          width: '300px'
        })
      ),
      state(
        'false',
        style({
          opacity: '0',
          overflow: 'hidden',
          height: '*',
          width: '0px'
        })
      ),
      transition('true => false', animate('400ms ease-in-out')),
      transition('false => true', animate('400ms ease-in-out'))
    ])
  ]
})
export class ContentSearchComponent implements OnInit {
  showInput: boolean = false;
  value: string = '';
  form: FormGroup = new FormGroup({
    value: new FormControl('', [Validators.required, Validators.minLength(3)])
  });

  filteredOptions: Observable<any>;
  showLoading: boolean = false;

  constructor(private router: Router, private searchService: SearchService, private translateService: TranslateService) {}

  ngOnInit() {
    this.filteredOptions = this.form.controls.value.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((val) => {
        if (val.length >= 3) {
          this.showLoading = true;
          return this.filter();
        } else {
          let obs = new Observable<any[]>((o) => {
            o.next([]);
          });
          return obs;
        }
      })
    );

    this.filteredOptions.subscribe(() => {
      this.showLoading = false;
    });
  }

  // filter and return the values
  filter(): Observable<any[]> {
    // call the service which makes the http-request
    return this.searchService
      .searchcontentautocompletePost({ value: this.form.controls.value.value, lang: this.translateService.currentLang })
      .pipe(map((response) => response?.results));
  }

  expandInput() {
    this.showInput = true;
  }
  closeInput() {
    this.showInput = false;
    this.clearInput();
  }
  clearInput() {
    this.form.controls.value.setValue('');
  }
  search() {
    if (this.form.valid) {
      this.router.navigateByUrl('/search/' + this.form.controls.value.value);
    }
  }
}
