import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-cookies-notice',
  templateUrl: './no-cookies-notice.component.html',
  styleUrls: ['./no-cookies-notice.component.scss'],
})
export class NoCookiesNoticeComponent implements OnInit {

  @Input() daysToShowAgain: number | boolean;
  showAlert = false;
  constructor(private router: Router) { }

  ngOnInit() {
    this.checkLocalStorage();
  }

  checkLocalStorage(): void {
    const savedData = localStorage.getItem('acceptConditions');

    // Verifica si el valor almacenado es 'true' y en ese caso no muestra la alerta.
    if (savedData === 'true') {
      this.showAlert = false;
      return;
    }

    const now = new Date().getTime();
    const expirationTime = typeof this.daysToShowAgain === 'number'
      ? this.daysToShowAgain * 24 * 60 * 60 * 1000
      : Infinity; // Si no se proporciona un número, nunca expira.
    const lastTimestamp = savedData ? parseInt(savedData, 10) : 0;

    // Se muestra el alerta si no hay timestamp o si el timestamp existente es más antiguo que el período especificado.
    this.showAlert = !lastTimestamp || now - lastTimestamp > expirationTime;
  }


  acceptConditions(): void {
    if (this.daysToShowAgain === true) {
      localStorage.setItem('acceptConditions', 'true');
    } else {
      const newTimestamp = new Date().getTime();
      localStorage.setItem('acceptConditions', newTimestamp.toString());
    }
    this.showAlert = false;
  }

  goPrivacyPolicy() {
    this.router.navigateByUrl('privacy-policy');
  }

}
