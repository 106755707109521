import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-modal-drug-bank',
  templateUrl: './modal-drug-bank.component.html',
  styleUrls: ['./modal-drug-bank.component.scss']
})
export class ModalDrugBankComponent implements OnInit {
  private data: any[] = [];

  constructor(public dialogRef: MatDialogRef<ModalDrugBankComponent>) {}

  ngOnInit() {}

  getData(data?: any) {
    this.data = data;
  }

  cancel() {
    this.dialogRef.close();
  }
  save() {
    this.dialogRef.close(this.data);
  }
}
