/* eslint-disable @typescript-eslint/prefer-for-of */
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

@Component({
  selector: 'app-btn-save-pdf',
  templateUrl: './btn-save-pdf.component.html',
  styleUrls: ['./btn-save-pdf.component.scss']
})
export class BtnSavePdfComponent {
  @Input() title = 'htmltopdf';
  @Input() selector = 'pdfTable';

  public downloadAsPDF() {
    const doc = new jsPDF();
    const elem: any = document.getElementById(this.selector);
    // const pdfTable = elem.nativeElement;
    pdfMake.createPdf({ ...this.createTableDoc(elem), pageOrientation: 'landscape' }).open();
  }

  createTableDoc(element) {
    const table: any = element.getElementsByClassName('mat-table')[0];
    const headerRows: any = table.getElementsByClassName('mat-header-row');
    const rows: any = table.getElementsByClassName('mat-row');
    const widths = [];
    const body = [];
    const bodyHeaderRow = [];
    for (let i = 0; i < headerRows.length; i++) {
      for (let j = 0; j < headerRows[i].getElementsByClassName('mat-header-cell').length; j++) {
        const cell = headerRows[i].getElementsByClassName('mat-header-cell')[j];
        if (cell.innerText) {
          bodyHeaderRow.push({text: cell.innerText, style: 'tableHeader'});
          widths.push(cell.style.width != '' ? cell.style.width : cell.offsetWidth); // if the cell's style width is not set, get its' actual width
        }
      }
    }
    body.push(bodyHeaderRow);
    for (let y = 0; y < rows.length; y++) {
      const bodyRow = [];
      for (let x = 0; x < rows[y].getElementsByClassName('mat-cell').length; x++) {
        if (rows[y].getElementsByClassName('mat-cell')[x].innerText) {
          bodyRow.push(rows[y].getElementsByClassName('mat-cell')[x].innerText);
        } else {
          // Checks the presence of CIE-10 columns and adds empty content to prevent data shifts
          if (rows[y].getElementsByClassName('mat-cell')[x].classList.contains('mat-column-CIE-10')) {
            bodyRow.push('');
          }
        }
      }
      if (bodyRow.length < bodyHeaderRow.length) { // Fills the table ending cells without data with an empty string
        while (bodyRow.length < bodyHeaderRow.length) {
          bodyRow.push('');
        }
      }
      body.push(bodyRow);
    }

    const docDef = {
      content: [{
        table: {
          headerRows: 1,
          body,
        }
      }],
      styles: {
        tableHeader: {
          bold: true,
          fillColor: '#cccccc'
        }
      }
    };

    // console.log(docDef);
    return docDef;
  }
}
