import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-detail-case-header-sticky',
  templateUrl: './detail-case-header-sticky.component.html',
  styleUrls: ['./detail-case-header-sticky.component.scss']
})
export class DetailCaseHeaderStickyComponent implements OnInit {
  @Input() idCase = '';
  @Output() downloadPDF = new EventEmitter<any>();
  @Output() openSimilarPricing: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {}

  async download() {
    this.downloadPDF.emit(true);
  }

  openSimilarPrincing() {
    this.openSimilarPricing.emit();
  }
}
