import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { CasesService, PendingCaseInput } from '@app/core/services/api';
import { CaseCommentsInput } from '@app/core/services/api/model/caseCommentsInput';
import { ModalService } from '@app/core/services/modal/modal.service';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { DOCTOR, instanceOfErrAttribute } from '../utils/commonMethods';

interface IComment {
  rol: string;
  comment: string;
  date: string;
  email?: string;
}
interface IInputData {
  idCase: string;
  stateCase: number;
  comments: IComment[];
}

@Component({
  selector: 'app-detail-case-comment',
  templateUrl: './detail-case-comment.component.html',
  styleUrls: ['./detail-case-comment.component.scss']
})
export class DetailCaseCommentComponent implements OnInit {
  @Input() canEdit = false;
  @Output() requestDoctor: EventEmitter<any> = new EventEmitter();
  _data: IInputData;
  textComment = '';
  form: FormArray;
  reviewForm: FormGroup;
  constructor(
    private formGroupDirective: FormGroupDirective,
    private casesSrv: CasesService,
    private loaderSrv: SpinnerService,
    private authSrv: AuthenticationService,
    private modalSrv: ModalService,
    private translateSrv: TranslateService,
    private datePipe: DatePipe,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  @Input() set data(value) {
    this._data = value;
    if (this.form) {
      this.ngOnInit();
    }
  }

  ngOnInit() {
    this.form = new FormArray([]);
    if (this._data.comments.length) {
      this._data.comments.forEach((comment) => {
        this.form.push(
          new FormGroup({
            rol: new FormControl(comment.rol, []),
            comment: new FormControl(comment.comment, [Validators.required, Validators.minLength(1)]),
            date: new FormControl(comment.date, [])
          })
        );
      });
    }
    if (this.formGroupDirective.form) {
      this.formGroupDirective.form.setControl('comments', this.form);
    }
    this.reviewForm = new FormGroup({
      medicalCheck: new FormControl(false, [Validators.requiredTrue]),
      limitDate: new FormControl('', [Validators.required])
    });
  }

  /**
   * Method to open modal where user select a doctor
   */
  openSelectDoctorModal() {
    this.modalSrv
      .openModalSelectDoctor(this._data.idCase)
      .afterClosed()
      .subscribe({
        next: (email) => {
          if (email) {
            const loader = this.loaderSrv.showSpinner();
            // Enviar al back la petición de revisión médica
            const body: PendingCaseInput = {
              idCase: this._data.idCase,
              date: this.reviewForm.get('limitDate').value,
              doctor: email
            };
            this.casesSrv.pendingCasePost(body).subscribe({
              next: (response) => {
                loader.close();
                // Cerramos el spinner y comprobamos que la respuesta no sea una instancia de error
                if (!instanceOfErrAttribute(response)) {
                  this.modalSrv
                    .openModalSuccess(
                      '',
                      this.translateSrv.instant('DETAIL-CASE-PAGE.COMMENTS.REVIEW.SUCCESS', {
                        param: this.datePipe.transform(body.date, undefined, this.locale)
                      })
                    )
                    .afterClosed()
                    .subscribe({
                      next: () => this.requestDoctor.emit()
                    });
                } else {
                  this.modalSrv.openModalDanger('ERROR.ERROR', 'ERROR.ERROR-REQUEST-DOCTOR');
                }
              }
            });
          }
        }
      });
  }

  addComment() {
    const loader = this.loaderSrv.showSpinner();
    const commentForm = new FormGroup({
      rol: new FormControl(this.authSrv.getRoles()[0], []),
      comment: new FormControl(this.textComment, [Validators.required, Validators.minLength(1)]),
      date: new FormControl(new Date().toISOString(), [])
    });
    const userData: any = this.authSrv.decodeJwt(sessionStorage.getItem(this.authSrv.TOKEN));
    const body: CaseCommentsInput = {
      comments: { ...commentForm.getRawValue(), email: userData.email },
      idCase: this._data.idCase
    };
    // console.log(err)('Datos comentarios para back', body);
    this.casesSrv.caseCommentsPost(body).subscribe({
      next: (response) => {
        loader.close();
        this.form.push(commentForm);
        this.textComment = '';
      }
    });
    // TODO Eliminar las líneas comentadas
    /* setTimeout(() => {
      loader.close();
      this.form.push(commentForm);
      this.textComment = '';
    }, 500); */
  }

  removeComment(index: number) {
    this.form.removeAt(index);
  }

  /**
   * Method to check if user is a doctor
   *
   * @returns boolean
   */
  isDoctorRole(): boolean {
    return this.authSrv.checkRolUser(DOCTOR);
  }

  getMinDoctorRequestDate(): Date {
    return moment({ hour: 0, minute: 0 }).add(1, 'day').toDate();
  }
}
