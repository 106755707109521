import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { ComboRiskError, ComboRiskResult, ComboRiskResults, ComboRiskService } from '@app/core/services/api';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IRiskAutoComplete } from '../maresel-input-autocomplete/maresel-input-autocomplete.component';

interface IRiskAutoCompleteExtended extends IRiskAutoComplete {
  fullLabel?: any;
}
interface ComboRiskResultExtended extends ComboRiskResult {
  label?: any;
  fullLabel?: any;
}

@Component({
  selector: 'app-select-risks',
  templateUrl: './select-risks.component.html',
  styleUrls: ['./select-risks.component.scss']
})
export class SelectRisksComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() label = '';
  @Input() control: FormControl = new FormControl();
  risks: ComboRiskResultExtended[] = [];
  subscription: Subscription;
  constructor(private loaderSrv: SpinnerService, private comboRiskSrv: ComboRiskService, public translateSrv: TranslateService) { }

  ngOnInit() {
    this.risks = [];
    if (this.control) {
      // El error de la validacion viene de este campo.
      this.control.setValidators(this.inArrayOptionsValidator(this.risks));
      this.control.updateValueAndValidity();
    }
    if (this.control && this.control.value && typeof this.control.value === 'object') {
      if (!this.risks.length) {
        this.risks = [...this.risks, this.control.value];
      } else {
        if (!this.risks.find((item) => item.link === this.control.value.link)) {
          this.risks = [...this.risks, this.control.value];
        }
      }
    }
  }

  ngAfterViewInit(): void {
    this.subscription = this.control.valueChanges.pipe(debounceTime(200)).subscribe((query: string) => {
      if (typeof query === 'string' && query.length >= 3) {
        if (query.indexOf('/') !== 0) {
          const loader = this.loaderSrv.showSpinner();
          this.comboRiskSrv.comboRiskPost({ query: query.toLowerCase(), lang: this.translateSrv.currentLang }).subscribe(
            (data: ComboRiskResults | ComboRiskError) => {
              if (typeof data === 'object' && (data as ComboRiskError).statusCode === 400) {
              } else {
                this.risks = (data as ComboRiskResults).map(dataFiltered =>
                  // dataFiltered.link.type = 'risk';
                  this.getDataFiltered(dataFiltered)
                  // return this.getDataFiltered(dataFiltered);
                );
              }
              loader.close();
            },
            (err) => {
              loader.close();
            }
          );
        }
      }
    });
  }

  // Construimos el objeto complejo con todas las opciones que debemos almacenar como valor en el input
  // y posteriormente enviar.

  // La propiedad fullLabel conserva el valor original de la etiqueta y es necesaria en este ámbito ya que es
  // donde se maneja el estado, al abreviar label perdemos su valor y la necesitamos como valor visual del input
  // y valor de la lista deplegable
  getDataFiltered(dataFiltered) {
    return {
      link: dataFiltered.link,
      linkType: 'risk',
      label: {
        es: typeof dataFiltered.label === 'object' ? dataFiltered.label.es : dataFiltered.label,
        en: typeof dataFiltered.label === 'object' ? dataFiltered.label.en : dataFiltered.label,
        fr: typeof dataFiltered.label === 'object' ? dataFiltered.label.fr : dataFiltered.label,
        pt: typeof dataFiltered.label === 'object' ? dataFiltered.label.pt : dataFiltered.label
      },
      fullLabel: {
        es: typeof dataFiltered.fullLabel === 'object' ? dataFiltered.fullLabel.es : dataFiltered.fullLabel,
        en: typeof dataFiltered.fullLabel === 'object' ? dataFiltered.fullLabel.en : dataFiltered.fullLabel,
        fr: typeof dataFiltered.fullLabel === 'object' ? dataFiltered.fullLabel.fr : dataFiltered.fullLabel,
        pt: typeof dataFiltered.fullLabel === 'object' ? dataFiltered.fullLabel.pt : dataFiltered.fullLabel
      }
    };
  }

  // Construye la cadena que se representa en la lista desplegable
  getComboOption(option) {
    let returnOption = '';
    if (option.label && typeof option.label === 'object') {
      if (option.fullLabel && typeof option.fullLabel === 'object') {
        returnOption = option.fullLabel[this.translateSrv.currentLang];
      } else {
        returnOption = option.label[this.translateSrv.currentLang];
      }
    } else {
      returnOption = option.label;
    }
    return returnOption;
  }

  ngOnDestroy(): void {
    this.control.clearValidators(); // Elimina todos los validadores
    this.control.updateValueAndValidity(); // Actualiza el estado del control
    this.subscription.unsubscribe();
    // console.log(err)('me destruyen');
  }

  getPanelSize() {
    let nChars = 0;
    this.risks.forEach((item) => {
      // Verifica si label es un objeto para determinar cómo calcular la longitud.
      if (typeof item.label === 'object') {
        // Si label es un objeto, intenta usar fullLabel en el idioma actual, si está disponible.
        const labelLength = item.fullLabel && item.fullLabel[this.translateSrv.currentLang] ? item.fullLabel[this.translateSrv.currentLang].length : 0;
        if (labelLength > nChars) {
          nChars = labelLength;
        }
      } else if (typeof item.label === 'string' && item.label.length > nChars) {
        // Si label es un string, simplemente usa su longitud.
        nChars = item.label.length;
      }
    });

    return nChars * 8.5;
  }

  inArrayOptionsValidator(array: string[] | ComboRiskResults): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && (this.risks as IRiskAutoCompleteExtended[]).find((o) => o.link === control.value?.link) === undefined) {
        return { inArrayOptions: true };
      }
      return null;
    };
  }

  isRequired() {
    try {
      return this.control?.validator(this.control)?.required;
    } catch (err) {
      return false;
    }
  }

  // Construye la cadena que se representa en el input. Se tienen en cuenta múltiples opciones para compatibilizar
  // las diferentes estructuras posibles
  getOptionText(option) {
    if (option) {
      // Encuentra la opción correspondiente basada en el link.
      const foundOption = (this.risks as IRiskAutoCompleteExtended[]).find((o) => o.link?.includes(option.link));

      // Verifica si foundOption.label es un objeto (lo que significa que tiene traducciones disponibles).
      if (foundOption && typeof foundOption.label === 'object') {
        // Intenta devolver fullLabel en el idioma actual si está disponible.
        if (foundOption.fullLabel && foundOption.fullLabel[this.translateSrv.currentLang]) {
          return foundOption.fullLabel[this.translateSrv.currentLang];
        }

        // Si fullLabel no está disponible o no tiene el idioma actual, devuelve label en el idioma actual.
        return foundOption.label[this.translateSrv.currentLang];
      } else if (foundOption) {
        // Si label no es un objeto, simplemente devuelve el string de label.
        return foundOption.label;
      }
    }

    // En caso de que option no esté definida o no se encuentre una coincidencia adecuada, devuelve una cadena vacía.
    return '';
  }

}