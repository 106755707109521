import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LEVEL, GlobalError } from '@core/error-handler/global-error';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor() {}

  // tslint:disable-next-line: no-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;

    if (request.url.indexOf('login.microsoftonline.com') === 0) {
      request = request.clone({
        setHeaders: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        }
      });
    }

    return next.handle(request).pipe(
      // tslint:disable-next-line: no-any
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        const errorObject = new GlobalError(error.status, error.message, LEVEL.error, error.error);
        return throwError(errorObject);
      })
    );
  }
}
