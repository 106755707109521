import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CoveragesService, IMensaje, LangService, OperandosService, RatesEditElement, RatesError, RatesService } from '@app/core/services/api';
import { ModalService } from '@app/core/services/modal/modal.service';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { DialogOverviewComponent } from '../dialog-overview/dialog-overview.component';
import { instanceOfError } from '../utils/commonMethods';

interface RateData {
  cie: string;
  descripcion: {
    en: string;
    fr: string;
    pt: string;
    es: string;
  };
  coberturas: {
    V: string;
    FA: string;
    IP: string;
  };
  children?: RateData[];
}
interface IKey {
  key: string;
}

@Component({
  selector: 'app-modify-tarifas',
  templateUrl: './modify-tarifas.component.html',
  styleUrls: ['./modify-tarifas.component.scss']
})
export class ModifyTarifasComponent implements OnInit, OnDestroy {
  @Input() risk = '';
  @Output() cancelEvent: EventEmitter<boolean> = new EventEmitter();
  originalLength = 0;
  medicalRisky: boolean;
  dataSource$: BehaviorSubject<AbstractControl[]>;
  nestedTreeControl: NestedTreeControl<AbstractControl>;
  nestedDataSource: MatTreeNestedDataSource<AbstractControl>;
  editForm: FormGroup = new FormGroup({
    children: this.formBuilder.array([])
  });
  pricingCriteriaGroup: FormGroup;
  options: string[] = [];
  languages: string[] = [];
  coverages: IKey[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private loaderSrv: SpinnerService,
    private ratesSrv: RatesService,
    private modalSrv: ModalService,
    private operandSrv: OperandosService,
    private langService: LangService,
    private coverageService: CoveragesService
  ) { }

  ngOnInit() {
    this.pricingCriteriaGroup = new FormGroup({
      pricingCriteria: this.formBuilder.array([])
    });
    const loader = this.loaderSrv.showSpinner();
    // Instanciamos el treeControl
    this.nestedTreeControl = new NestedTreeControl<AbstractControl>(this._getChildren);
    // Creamos un MatTreeNestedDataSource
    this.nestedDataSource = new MatTreeNestedDataSource();
    // Inicializamos dataSource$
    this.dataSource$ = new BehaviorSubject<AbstractControl[]>([]);
    // Detectamos cambios en dataSource$ y actualizamos nestedDataSource.data
    this.dataSource$.subscribe((items) => {
      this.nestedDataSource.data = null;
      this.nestedDataSource.data = [...items];
    });
    // Obtenemos los datos
    this.loadData(loader);
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.dataSource$.complete();
  }

  hasNestedChild = (_: number, node: FormGroup) => (node.controls['children'] as FormArray).controls.length;

  loadData(loader: MatDialogRef<DialogOverviewComponent, any>) {
    this.editForm = new FormGroup({
      children: this.formBuilder.array([])
    });
    forkJoin([
      this.ratesSrv.ratesEditGet(this.risk),
      this.operandSrv.operandosGet(),
      this.langService.langGet(),
      this.coverageService.coveragesGet()
    ]).subscribe(
      ([response, operandos, langs, coberturas]) => {
        if (instanceOfError(response)) {
          loader.close();
          this.modalSrv.openModalDanger('Error', 'ERROR.ERROR-RATES');
          this.options = [...operandos];
          this.languages = [...langs];
          this.coverages = [...coberturas];
          this.originalLength = 0;
          this.prepareData([], this.editForm);
          this.dataSource$.next((this.editForm.controls['children'] as FormArray).controls);
        } else {
          this.languages = [...langs];
          this.coverages = [...coberturas];
          // console.log('Languages: ', langs);
          for (const lang of langs) {
            // console.log(`Creating ${lang} option form`);
            (this.pricingCriteriaGroup.controls.pricingCriteria as FormArray).push(
              new FormGroup({
                [lang]: new FormControl((response as any).mensaje[lang] ? (response as any).mensaje[lang] : '')
              })
            );
          }
          this.options = [...operandos];
          this.originalLength = ((response as unknown as any).rates as RatesEditElement[]).length;
          this.prepareData((response as any).rates, this.editForm);
          this.dataSource$.next((this.editForm.controls['children'] as FormArray).controls);

          loader.close();
        }
      },
      (error) => loader.close()
    );
  }

  prepareData(data: RatesEditElement[], parentForm: FormGroup) {
    data.forEach((item, index) => {
      const form: FormGroup = this.addRate(item, parentForm);
      if (item.children && item.children.length) {
        this.prepareData(item.children as any, form);
      }
    });
  }

  addRate(rate: RatesEditElement, parentForm: FormGroup): FormGroup {
    const formGroup = this.createRateForm(rate);
    (parentForm.controls['children'] as FormArray).push(formGroup);
    return formGroup;
  }

  createRateForm(value?: RatesEditElement): FormGroup {
    const linkFormGroup: FormGroup = new FormGroup({});
    const coveragesFormGroup: FormGroup = new FormGroup({});
    const descriptionFormGroup: FormGroup = new FormGroup({});
    const linkTypeFormGroup: FormGroup = new FormGroup({});
    const translateFormGroup: FormGroup = new FormGroup({});
    this.coverages.forEach((item) => {
      const linkControl = new FormControl(false, []);
      const coverageControl = new FormControl('', []);
      // const linkTypeControl = new FormControl(true);
      // const linkTypeControl = new FormControl(value.link[item.key]);
      const linkTypeControl = new FormControl(value?.coberturas[item.key] ? this.checkLinkTypeRate(value.coberturas[item.key]) : true);
      const translateControl = new FormControl(false);
      const translateTextGroupControl = new FormGroup({
        es: new FormControl(''),
        en: new FormControl(''),
        pt: new FormControl(''),
        fr: new FormControl('')
      });

      if (value && value.coberturas[item.key]) {
        // Code flow when the function has a value parameter
        if (value.coberturas[item.key].es) {
          coveragesFormGroup.addControl(item.key, translateTextGroupControl);
          translateFormGroup.addControl(item.key, new FormControl(true));
        } else {
          coveragesFormGroup.addControl(item.key, coverageControl);
          translateFormGroup.addControl(item.key, translateControl);
        }
      } else {
        // Code flow when the function is called empty, so new empty structure needs to be created
        coveragesFormGroup.addControl(item.key, coverageControl);
        translateFormGroup.addControl(item.key, new FormControl(false));
      }

      if (value && value.coberturas[item.key]) {
        // Code flow when the function has a value parameter, prevents error when the function is called empty
        if (value.coberturas[item.key].type === 'rate') {
          linkTypeControl.setValue(false);
        }
      }

      linkTypeControl.valueChanges.subscribe(() => {
        coveragesFormGroup.controls[item.key].reset();
      });
      linkFormGroup.addControl(item.key, linkControl);
      linkTypeFormGroup.addControl(item.key, linkTypeControl);
      translateFormGroup.addControl(item.key, translateControl);
    });
    this.languages.forEach((lang) => {
      const descriptionControl = new FormControl('', [Validators.required]);
      descriptionFormGroup.addControl(lang, descriptionControl);
    });
    const form: FormGroup = new FormGroup({
      cie: new FormControl('', []),
      descripcion: descriptionFormGroup,
      coberturas: coveragesFormGroup,
      link: linkFormGroup,
      linkType: linkTypeFormGroup,
      translate: translateFormGroup,
      children: new FormArray([], [])
    });

    form.controls.translate.valueChanges.subscribe((subValue) => {
      this.setTranslateForm(subValue, form.controls.coberturas as FormGroup);
    });

    if (!value) {
      // Code flow when the function is called empty, so new empty structure needs to be created
      form.patchValue({
        cie: '',
        descripcion: {
          es: '',
          en: '',
          pt: '',
          fr: ''
        },
        coberturas: this.coverages.reduce((acc, coverage) => {
          acc[coverage.key] = '';
          return acc;
        }, {}),
      });
    } else {
      // Code flow when the function has a value parameter
      form.patchValue(value);
    }

    return form;
  }
  /* Pendiente de refinar


  checkLinkTypeRate(item: any) {
    // console.log('Parametro: ' + item.link);
    // Si tiene link type
    if (item.linkType) {
      // Si es risk
      if (item.linkType === 'risk') {
        return true;
        // en otro caso es false
      } else {
        return false;
      }
      // si no tiene link type o es undefined
    } else {
      // si tiene link e incluye /0
      if (item.link && item.link.toString().includes('/0')) {
        return true;
      }
      return true;
    }
  }
  */
  checkLinkTypeRate(item: any) {
    // console.log(err)('Parametro: ' + item);
    if (item.label) {
      if (item.linkType && item.linkType === 'risk') {
        return true;
      } else if (item.link && item.link.includes('/0')) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }



  setTranslateForm(value: any, formControl: FormGroup) {
    if (value) {
      Object.keys(value).forEach((v) => {
        if (value[v]) {
          formControl.controls[v] = new FormGroup({
            // Gives fields previous value or empty
            es: new FormControl(formControl.controls[v].value.es ? formControl.controls[v].value.es : ''),
            en: new FormControl(formControl.controls[v].value.en ? formControl.controls[v].value.en : ''),
            pt: new FormControl(formControl.controls[v].value.pt ? formControl.controls[v].value.pt : ''),
            fr: new FormControl(formControl.controls[v].value.fr ? formControl.controls[v].value.fr : ''),
          });
        } else {
          // Fixed to prevent a problem related to all fields of the rate being set to empty
          // Lets input get empty value after translate regret
          // formControl.controls[v] = new FormControl('');
          formControl.controls[v] = new FormControl(formControl.controls[v].value ? formControl.controls[v].value : '');
        }
      });
    }
  }

  refreshNodeData() {
    /* this.nestedDataSource.data = [
        ...(this.editForm.controls['children'] as FormArray).controls,
      ];*/
    this.dataSource$.next(this.dataSource$.value);
  }

  addNode(parentForm?: FormGroup) {
    const form = this.createRateForm();
    if (parentForm) {
      // (parentForm.controls['children'] as FormArray).controls.push(form);
      form.setParent(parentForm.controls['children'] as any);
      (parentForm.controls['children'] as FormArray).controls = [...(parentForm.controls['children'] as FormArray).controls, form];
      if (!this.nestedTreeControl.isExpanded(parentForm)) {
        this.nestedTreeControl.expand(parentForm);
      }
    } else {
      (this.editForm.controls['children'] as FormArray).push(form);
      // this.dataSource$.next([...this.dataSource$.value, form]);
    }
    this.refreshNodeData();
  }

  deleteNode(node: FormGroup) {
    const parent = node.parent;

    const index = (parent.value as RatesEditElement[]).indexOf(node.value as RatesEditElement);

    (parent.controls as AbstractControl[]).splice(index, 1);
    parent.value.splice(index, 1);

    this.refreshNodeData();
  }

  cancelEdit() {
    this.cancelEvent.emit(false);
  }

  saveRate() {
    if (this.isValid()) {
      const loader = this.loaderSrv.showSpinner();
      const data = this.editForm.getRawValue();
      let criteria: IMensaje;
      for (const message of (this.pricingCriteriaGroup.controls.pricingCriteria as FormArray).getRawValue()) {
        // console.log('Mensaje', message);
        criteria = { ...criteria, [Object.keys(message)[0]]: message[Object.keys(message)[0]] };
      }
      this.ratesSrv.ratesSavePost({ risk: this.risk, rates: data.children, originalLength: this.originalLength, mensaje: criteria }).subscribe({
        next: (response) => {
          loader.close();
          if (response && (response as any).orden > 0) {
            this.cancelEvent.emit(true);
          } else if (this.instanceOfRatesError(response)) {
            this.modalSrv.openModalDanger('ERROR.ERROR', (response as RatesError).err);
          } else {
            this.cancelEvent.emit(true);
          }
        },
        error: (error) => {
          loader.close();
          this.modalSrv.openModalDanger('ERROR.ERROR', error.key);
        }
      });
    } else {
      if (!this.editForm.valid) {
        this.editForm.markAllAsTouched();
        this.modalSrv.openModalDanger('ERROR.ERROR', 'ERROR.MODAL.MODIFY-RATES.INVALID-DESCRIPTION');
      }
      if (!this.criterialPricingIsValid()) {
        this.modalSrv.openModalDanger('ERROR.ERROR', 'ERROR.MODAL.MODIFY-RATES.INVALID-CRITERIAL-PRICING');
      }
    }
  }

  isValid(): boolean {
    if (this.editForm.valid && this.criterialPricingIsValid()) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Method to check if criterial pricing has valid value (If one of them has value, rest can't be empty)
   *
   * @returns boolean
   */
  private criterialPricingIsValid(): boolean {
    const criterialData: { [key: string]: string }[] = (this.pricingCriteriaGroup.controls.pricingCriteria as FormArray).getRawValue();
    const dataToCheck = criterialData.filter((item) => item[Object.keys(item)[0]].length);
    return criterialData.length === dataToCheck.length || dataToCheck.length === 0;
  }

  private _getChildren = (node: FormGroup) => of((node.controls['children'] as FormArray).controls);

  private instanceOfRatesError(data: any) {
    if (!data) {
      return true;
    } else {
      return 'err' in data;
    }
  }
}
