import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import { DomController } from '@ionic/angular';

@Directive({
  selector: '[ScrollEvents]'
})
export class ScrollEventsDirective implements OnInit {
  @Input('ScrollEvents') scrollArea;
  private hidden = true;
  private triggerDistance = 64;

  constructor(private element: ElementRef, private renderer: Renderer2, private domCtrl: DomController) {}
  ngOnInit() {
    this.initStyles();
    this.hide();
    this.scrollArea.ionScroll.subscribe((scrollEvent) => {
      if (this.hidden && scrollEvent.detail.currentY > this.triggerDistance) {
        this.show();
      } else if (!this.hidden && scrollEvent.detail.currentY < this.triggerDistance) {
        this.hide();
      }
    });
  }
  initStyles() {
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.element.nativeElement, 'transition', '0.2s linear');
      this.renderer.setStyle(this.element.nativeElement, 'height', 'auto');
      this.renderer.setStyle(this.element.nativeElement, 'width', '100%');
      this.renderer.setStyle(this.element.nativeElement, 'position', 'fixed');
      this.renderer.setStyle(this.element.nativeElement, 'z-index', '1000');
      this.renderer.setStyle(this.element.nativeElement, 'margin-top', '-1px');
      this.renderer.setStyle(this.element.nativeElement, 'top', '0px');
      this.renderer.setStyle(this.element.nativeElement, 'padding-right', '17px');
    });
  }
  hide() {
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.element.nativeElement, 'opacity', '0');
      this.renderer.setStyle(this.element.nativeElement, 'display', 'none');
      this.renderer.setStyle(this.element.nativeElement, 'position', 'fixed');
    });
    this.hidden = true;
  }
  show() {
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.element.nativeElement, 'opacity', '1');
      this.renderer.setStyle(this.element.nativeElement, 'position', 'fixed');
      this.renderer.setStyle(this.element.nativeElement, 'display', 'inline');
    });
    this.hidden = false;
  }
}
