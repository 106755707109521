import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, exhaustMap, filter, map, scan, startWith, switchMap, tap } from 'rxjs/operators';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';

@Component({
  selector: 'app-maresel-input-autocomplete-key-name',
  templateUrl: './maresel-input-autocomplete-key-name.component.html',
  styleUrls: ['./maresel-input-autocomplete-key-name.component.scss']
})
export class MareselInputAutocompleteKeyNameComponent implements OnInit {
  @Input() label = '';
  @Input() value;
  @Input() control: AbstractControl = new FormControl();
  @Input() options: any[] = [];
  @Input() filteredOptions: Observable<any[]>;
  private nextPage$ = new Subject();

  constructor() {}

  ngOnInit() {
    if (!this.filteredOptions) {
      const filter$ = this.control.valueChanges.pipe(
        startWith(''),
        debounceTime(500),
        filter((q) => typeof q === 'string')
      );
      this.filteredOptions = filter$.pipe(
        switchMap((f) => {
          let currentPage = 1;
          return this.nextPage$.pipe(
            startWith(currentPage),
            exhaustMap((_) => this._filter(f, currentPage)),
            tap(() => currentPage++),
            takeWhileInclusive((p) => p.length > 0),
            scan((allProducts: any, newProducts: any) => allProducts.concat(newProducts), [])
          );
        })
      );
      /* this.filteredOptions = this.control.valueChanges.pipe(
        startWith(''),
        debounceTime(500),
        map((value) => this._filter(value))
      ); */
    }
    this.control.setValidators(this.inArrayOptionsValidator(this.options));
    if (this.value) {
      const data = this.options.find((item) => item.key === this.value);
      this.control.setValue(data);
    }
  }

  inArrayOptionsValidator(array: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && array.indexOf(control.value) === -1) {
        return { inArrayOptions: true };
      }
      return null;
    };
  }

  onScroll() {
    // Note: This is called multiple times after the scroll has reached the 80% threshold position.
    this.nextPage$.next();
  }

  public displayFn(value: any): string {
    return value && typeof value === 'object' ? value.name : value;
  }

  private _filter(value: string, page: number): Observable<string[]> {
    const take = 10;
    const skip = page > 0 ? (page - 1) * take : 0;
    // console.log(value);
    const filterValue = value?.toString().toLowerCase();
    const filtered = this.options.filter((option) => option.name.toLowerCase().includes(filterValue));
    return of(filtered.slice(skip, skip + take));
  }
}
