/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { CalcValidators } from '@app/core/directives/validators/calc-validators';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-metabolic-calc-glycemic-profile',
  templateUrl: './metabolic-calc-glycemic-profile.component.html',
  styleUrls: ['./metabolic-calc-glycemic-profile.component.scss']
})
export class MetabolicCalcGlycemicProfileComponent implements OnInit {
  @Input() age;
  @Input() data;

  public _age: number;
  public _unitMeasurement: string;

  @Input('age') set setAge(value: number) {
    this._age = value;
    this.addYearsSinceDiabetesDiagnosisValidator();
  }

  @Input('unitMeasurement') set unitMeasurement(value: string) {
    this._unitMeasurement = value;
    this.addGlucosaValidator();
    this.glycemicProfile.controls.glucose.updateValueAndValidity({ emitEvent: false });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  glycemicProfile: FormGroup = new FormGroup({
    diabetes: new FormControl(false),
    glucose: new FormControl('', []),
    HBA1C: new FormControl('', []),
    treatment: new FormControl('no'),
    yearsSinceDiabetesDiagnosis: new FormControl({value: '', disabled: true}),
    complicationsExists: new FormControl({value: '', disabled: true})
  });

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(private formGroupDirective: FormGroupDirective) {}

  ngOnInit() {
    // Diabetes obligatoria
    this.glycemicProfile.controls.diabetes.addValidators(Validators.required);

    // Si existe formulario lo seteamos
    if (this.formGroupDirective.form) {
      this.formGroupDirective.form.setControl('glycemicProfile', this.glycemicProfile);
    }

    // Validadores de cantidades en glucosa y HBA1C
    this.addGlucosaValidator();
    this.addHbA1cValidator();
    this.addYearsSinceDiabetesDiagnosisValidator();

    // Escuchar cambios en el campo diabetes
    this.glycemicProfile.controls.diabetes.valueChanges.subscribe(value => {
      this.enableDisable();
    });

    // Llamar enableDisable inicialmente para establecer el estado correcto
    this.enableDisable();
  }

  // Funcion para gestionar los campos del formulario
  enableDisable() {
    if (this.glycemicProfile.controls.diabetes.value === true) {
      this.glycemicProfile.controls.yearsSinceDiabetesDiagnosis.enable();
      this.glycemicProfile.controls.complicationsExists.enable();
    } else {
      this.glycemicProfile.controls.yearsSinceDiabetesDiagnosis.disable();
      this.glycemicProfile.controls.yearsSinceDiabetesDiagnosis.setValue('');

      this.glycemicProfile.controls.complicationsExists.disable();
      this.glycemicProfile.controls.complicationsExists.setValue('');
    }
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit(): void {
    if (this.data) {
      this.glycemicProfile.patchValue(this.data);
    }
  }

  isRequired(control: AbstractControl) {
    try {
      return control.hasValidator(Validators.required);
    } catch (error) {
      return false;
    }
  }

  addGlucosaValidator() {
    const control = this.glycemicProfile.controls.glucose;
    const isRequired = this.isRequired(control);
    control.clearValidators();
    if (isRequired) {
      control.addValidators(Validators.required);
    }
    if (this._unitMeasurement === 'mmol/l') {
      control.addValidators(CalcValidators.glucose(1.66, 16.65, this._unitMeasurement));
    } else if (this._unitMeasurement === 'mg/dl') {
      control.addValidators(CalcValidators.glucose(30, 300, this._unitMeasurement));
    }
  }

  addHbA1cValidator() {
    this.glycemicProfile.controls.HBA1C.addValidators([CalcValidators.hbA1c(0, 20, '%')]);
  }

  addYearsSinceDiabetesDiagnosisValidator() {
    const control = this.glycemicProfile.controls.yearsSinceDiabetesDiagnosis;
    const isRequired = this.isRequired(control);
    if (typeof this._age === 'number') {
      control.clearValidators();
      control.addValidators(CalcValidators.yearsSinceDiabetesDiagnosis(0, this._age));
      if (isRequired) {
        control.addValidators(Validators.required);
      }
      control.updateValueAndValidity({ emitEvent: false });
    }
  }
}
