import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IPaginator } from '@app/core/services/api/model/paginator.model';

export interface IDataPaginator {
  page: number;
  itemsPerPage: number;
}

@Component({
  selector: 'app-maresel-paginator',
  templateUrl: './maresel-paginator.component.html',
  styleUrls: ['./maresel-paginator.component.scss']
})
export class MareselPaginatorComponent implements OnInit, OnChanges {
  @Input() current = 0;
  @Input() pagesNumber = 0;
  @Output() itemsPerPage: EventEmitter<IPaginator> = new EventEmitter<IPaginator>();
  @Output() goTo: EventEmitter<IPaginator> = new EventEmitter<IPaginator>();

  public pages: number[] = [];
  numItemsPerPage: number;
  itemsPerPageOptions = [10, 20, 30];

  constructor() {}

  ngOnInit() {
    this.numItemsPerPage = this.itemsPerPageOptions[0];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.current && changes.current.currentValue) || (changes.pagesNumber && changes.pagesNumber.currentValue)) {
      this.pages = this.getPages(this.current, this.pagesNumber);
    }
  }

  onGoTo(page: number): void {
    if (page === -1 || page === -2) {
      if (page === -1) {
        if (this.pages[2] - 3 >= 1) {
          this.goTo.emit({ page: this.current - 3, itemsPerPage: this.numItemsPerPage });
        } else {
          this.goTo.emit({ page: 1, itemsPerPage: this.numItemsPerPage });
        }
      } else {
        if (this.pages[this.pages.length - 3] + 3 <= this.pagesNumber) {
          this.goTo.emit({ page: this.current + 3, itemsPerPage: this.numItemsPerPage });
        } else {
          this.goTo.emit({ page: this.pagesNumber, itemsPerPage: this.numItemsPerPage });
        }
      }
    } else {
      this.goTo.emit({ page, itemsPerPage: this.numItemsPerPage });
    }
  }

  onFirst(): void {
    this.onGoTo(1);
  }

  onLast(): void {
    this.onGoTo(this.pagesNumber);
  }

  onNext(): void {
    this.onGoTo(this.current + 1);
  }

  onItemsPerPage(numItems: string): void {
    this.numItemsPerPage = parseInt(numItems, 10);
    this.itemsPerPage.emit({ itemsPerPage: this.numItemsPerPage, page: this.current });
  }

  private getPages(current: number, numItems: number): number[] {
    if (numItems <= 4) {
      return [...Array(numItems).keys()].map((x) => ++x);
    }
    if (numItems > 4 && 1 <= current && current <= 3) {
      return [1, 2, 3, 4, -2, numItems];
    }
    if (numItems > 4 && current === numItems) {
      return [1, -1, numItems - 3, numItems - 2, numItems - 1, numItems];
    }
    if (numItems > 4) {
      return [1, -1, current - 1, current, current + 1, -2, numItems];
    }
  }
}
