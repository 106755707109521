import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup, FormGroupDirective, ValidatorFn, Validators} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MelanomaService } from '@app/core/services/api';
import { IMelanomaForm } from '@app/core/services/api/model/melanomaCalcForm.model';
import { ModalService } from '@app/core/services/modal/modal.service';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { instanceOfError } from '../utils/commonMethods';
import { FormUtilsService } from '@app/core/services/form-utils.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';

interface IKeyValue {
  key: string | number;
  value: string;
}
@Component({
  selector: 'app-melanoma-calc-form',
  templateUrl: './melanoma-calc-form.component.html',
  styleUrls: ['./melanoma-calc-form.component.scss']
})
export class MelanomaCalcFormComponent implements OnInit {
  @ViewChild('treatmentTypeAccordion') accordion: MatAccordion;
  @ViewChild('medicineAccordion') medicineAccordion: MatAccordion;
  @Input() data: IMelanomaForm | null;
  @Output() dataResult: EventEmitter<any> = new EventEmitter();

  typeForm: FormGroup;
  surgicalForm: FormGroup;
  chemotherapyForm: FormGroup;
  radiotherapyForm: FormGroup;
  immunologyForm: FormGroup;
  medicineForm: FormGroup;
  treatmentForm: FormGroup;
  histologyForm: FormGroup;
  collapsableForm: FormGroup;
  subscription: Subscription;
  clarkLevel: IKeyValue[] = [
    { key: '', value: '' },
    { key: 'none', value: 'MELANOMA-CALC.HISTOLOGY.NOT-SPECIFIED' },
    { key: 'I', value: 'I' },
    { key: 'II', value: 'II' },
    { key: 'III', value: 'III' },
    { key: 'IV', value: 'IV' },
    { key: 'V', value: 'V' }
  ];
  /* breslow: IKeyValue[] = [
    { key: 0, value: 'MELANOMA-CALC.HISTOLOGY.INDETERMINATED' },
    { key: 1, value: 'In situ' },
    { key: 2, value: '≤ 1mm' },
    { key: 3, value: '1,01 - 2 mm' },
    { key: 4, value: '2,01 - 4 mm' },
    { key: 5, value: '> 4 mm' }
  ]; */
  breslow: IKeyValue[] = [
    { key: '', value: '' },
    { key: 'Tx', value: 'MELANOMA-CALC.HISTOLOGY.INDETERMINATED' },
    { key: 'Tis', value: 'In situ' },
    { key: 'T1', value: '≤ 1mm' },
    { key: 'T2', value: '1,01 - ≤ 2 mm' },
    { key: 'T3', value: '2,01 - ≤ 4 mm' },
    { key: 'T4', value: '> 4 mm' }
  ];
  listGanglionOptions: IKeyValue[] = [
    { key: 0, value: 'MELANOMA-CALC.HISTOLOGY.INDETERMINATED' },
    { key: 1, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.0' },
    { key: 2, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.1' },
    { key: 3, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.2' },
    { key: 4, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.3' },
    { key: 5, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.4' },
    { key: 6, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.5' },
    { key: 7, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.6' },
    { key: 8, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.7' },
    { key: 9, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.8' },
    { key: 10, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.9' }
  ];
  metastesesOptions: IKeyValue[] = [
    {
      key: '',
      value: ''
    },
    {
      key: 'M0',
      value: 'MELANOMA-CALC.HISTOLOGY.HAVE-METASTESES.M0'
    },
    {
      key: 'M1',
      value: 'MELANOMA-CALC.HISTOLOGY.HAVE-METASTESES.M1'
    }
  ];
  nodalInvolvementOptions: IKeyValue[] = [
    {
      key: '',
      value: ''
    },
    {
      key: 'N0',
      value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.N0'
    },
    {
      key: 'N1',
      value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.N1'
    },
    {
      key: 'N2',
      value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.N2'
    },
    {
      key: 'N3',
      value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.N3'
    },
    {
      key: 'Nx',
      value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.Nx'
    }
  ];
  melanomaTNM: IKeyValue[] = [
    {
      key: '',
      value: ''
    },
    {
      key: 'T0',
      value: 'MELANOMA-CALC.HISTOLOGY.TNM-CLASIFICATION.T0'
    },
    {
      key: 'T1a',
      value: 'MELANOMA-CALC.HISTOLOGY.TNM-CLASIFICATION.T1a'
    },
    {
      key: 'T1b',
      value: 'MELANOMA-CALC.HISTOLOGY.TNM-CLASIFICATION.T1b'
    },
    {
      key: 'T2a',
      value: 'MELANOMA-CALC.HISTOLOGY.TNM-CLASIFICATION.T2a'
    },
    {
      key: 'T2b',
      value: 'MELANOMA-CALC.HISTOLOGY.TNM-CLASIFICATION.T2b'
    },
    {
      key: 'T3a',
      value: 'MELANOMA-CALC.HISTOLOGY.TNM-CLASIFICATION.T3a'
    },
    {
      key: 'T3b',
      value: 'MELANOMA-CALC.HISTOLOGY.TNM-CLASIFICATION.T3b'
    },
    {
      key: 'T4a',
      value: 'MELANOMA-CALC.HISTOLOGY.TNM-CLASIFICATION.T4a'
    },
    {
      key: 'T4b',
      value: 'MELANOMA-CALC.HISTOLOGY.TNM-CLASIFICATION.T4b'
    },
    {
      key: 'Tis',
      value: 'MELANOMA-CALC.HISTOLOGY.TNM-CLASIFICATION.Tis'
    },
    {
      key: 'Tx',
      value: 'MELANOMA-CALC.HISTOLOGY.TNM-CLASIFICATION.Tx'
    }
  ];

  stages = ['', '0', 'IA', 'IB', 'IIA', 'IIB', 'IIC', 'III', 'IV'];
  errorMsgStage = '';
  formError: boolean;
  maxDate: Date;

  constructor(
    private translateSrv: TranslateService,
    private modalSrv: ModalService,
    private loaderSrv: SpinnerService,
    private melanomaCalcSrv: MelanomaService,
    private formUtils: FormUtilsService,
    private datePipe: DatePipe
  ) {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
  }

  ngOnInit() {

    this.subscription = new Subscription();
    // Initialize melanoma type form
    this.typeForm = new FormGroup({
      type: new FormControl(this.data ? this.data.melanomaType.typeCode : '', [Validators.required]),
      diagnosisDate: new FormControl(this.data ? this.data.melanomaType.diagnosisDate : '', [Validators.required, this.fechaNoPosterior.bind(this)])
    });
    // Initialize surgical form of treatment type block
    this.surgicalForm = new FormGroup({
      hasTreatment: new FormControl(false, []),
      exeresis: new FormControl('', []),
      interventionDate: new FormControl('', [])
    });
    // Initialize chemotherapy form of treatment type block
    this.chemotherapyForm = new FormGroup({
      hasTreatment: new FormControl(false, []),
      inCourse: new FormControl('', []),
      startDate: new FormControl('', []),
      endDate: new FormControl({ disabled: true, value: '' }, [])
    });
    // Initialize radiotherapy form of treatment type block
    this.radiotherapyForm = new FormGroup({
      hasTreatment: new FormControl(false, []),
      inCourse: new FormControl('', []),
      startDate: new FormControl('', []),
      endDate: new FormControl({ disabled: true, value: '' }, [])
    });
    // Initialize immunology form of treatment type block
    this.immunologyForm = new FormGroup({
      hasTreatment: new FormControl(false, []),
      inCourse: new FormControl('', []),
      startDate: new FormControl('', []),
      endDate: new FormControl({ disabled: true, value: '' }, [])
    });
    // Initialize medicine form of treatment type block
    this.medicineForm = new FormGroup({
      hasTreatment: new FormControl(false, []),
      corticosteroids: new FormControl(false, []),
      corticosteroidsInCourse: new FormControl('', []),
      corticosteroidsStartDate: new FormControl('', []),
      corticosteroidsEndDate: new FormControl({ disabled: true, value: '' }, []),
      other: new FormControl(false, []),
      otherInCourse: new FormControl('', []),
      otherStartDate: new FormControl('', []),
      otherEndDate: new FormControl({ disabled: true, value: '' }, [])
    });
    // Initialize treatement form
    this.treatmentForm = new FormGroup({});
    // Initialize histology form of histology block
    this.histologyForm = new FormGroup({
      levelClark: new FormControl('', []),
      breslow: new FormControl('', []),
      stage: new FormControl('', []),
      t: new FormControl('', []),
      hasUlceration: new FormControl('', []),
      // hasLymphnodes: new FormControl('', []),
      lymphnodeDetail: new FormControl({ disabled: false, value: '' }, []),
      // hasMetasteses: new FormControl('', []),
      metasteseDetail: new FormControl({ disabled: false, value: '' }, [])
    });
    // We init the form control listener
    this.initListenerForm();
    // If we have data, is edit mode
    if (this.data) {
      this.patchDataValues();
    }
    // We init histology form listener after patch data to avoid call back server
    this.initHistologyFormListener();

    // Formulario para el div
    this.collapsableForm = new FormGroup({
      surgical: new FormControl(this.surgicalForm.controls['hasTreatment'].value),
      chemotherapy: new FormControl(this.chemotherapyForm.controls['hasTreatment'].value),
      radiotherapy: new FormControl(this.radiotherapyForm.controls['hasTreatment'].value),
    });
    this.formError = this.checkAllFieldsFalse(this.collapsableForm);
    this.isFullflled();
  }
  fechaNoPosterior(control: FormControl) {
    const fechaIntroducida = new Date(control.value);
    const fechaActual = new Date();
    fechaActual.setHours(0, 0, 0, 0); // Para comparar solo las fechas sin tener en cuenta la hora

    if (fechaIntroducida > fechaActual) {
      return { fechaPosterior: true };
    }
    return null;
  }

  isFullflled(){
    if (this.typeForm.controls['type'].value !== ''){
      // console.log(err)('tengo valor: ' + this.typeForm.controls['type'].value);
      if (this.typeForm.controls['type'].value === 2){
        // console.log(err)('CASO A');
        this.histologyForm.controls['stage'].addValidators(Validators.required);
        this.histologyForm.updateValueAndValidity();
      /* } else if (this.histologyForm.controls['levelClark'].value !== '' ||
                 this.histologyForm.controls['breslow'].value !== '' ||
                 this.histologyForm.controls['hasUlceration'].value !== ''){
        // console.log('CASO B');
        this.histologyForm.controls['stage'].addValidators(Validators.required);
        this.histologyForm.updateValueAndValidity();*/
      } else {
        // console.log(err)('CASO C');
        this.histologyForm.controls['stage'].clearValidators();
        this.histologyForm.updateValueAndValidity();
      }

    }
  }

  // Initialize inCourse control listener to enable or disable end date control
  initListenerForm() {
    // Scope change => When melanoma's type is malign, stage field from Histology form must be required
    this.typeForm.controls['type'].valueChanges.subscribe({
      next: (newValue) => {
        if (newValue === 2) {
          this.histologyForm.controls['stage'].addValidators(Validators.required);
        } else {
          if (this.histologyForm.controls['stage'].hasValidator(Validators.required)) {
            this.histologyForm.controls['stage'].clearValidators();
          }
        }
        this.histologyForm.controls['stage'].updateValueAndValidity();
      }
    });
    this.chemotherapyForm.controls['inCourse'].valueChanges.subscribe({
      next: (newValue) => this.enableDisableEndDateControl(this.chemotherapyForm.controls['endDate'], newValue)
    });
    this.radiotherapyForm.controls['inCourse'].valueChanges.subscribe({
      next: (newValue) => this.enableDisableEndDateControl(this.radiotherapyForm.controls['endDate'], newValue)
    });
    this.immunologyForm.controls['inCourse'].valueChanges.subscribe({
      next: (newValue) => this.enableDisableEndDateControl(this.immunologyForm.controls['endDate'], newValue)
    });
    this.medicineForm.controls['corticosteroidsInCourse'].valueChanges.subscribe({
      next: (newValue) => this.enableDisableEndDateControl(this.medicineForm.controls['corticosteroidsEndDate'], newValue)
    });
    this.medicineForm.controls['otherInCourse'].valueChanges.subscribe({
      next: (newValue) => this.enableDisableEndDateControl(this.medicineForm.controls['otherEndDate'], newValue)
    });
  }

  /**
   * Method to initialize a listener in hitology form fields
   */
  initHistologyFormListener() {
    /* this.histologyForm.controls['hasLymphnodes'].valueChanges.subscribe({
      next: (newValue) => this.enableDisableDetailControl(this.histologyForm.controls['lymphnodeDetail'], newValue)
    }); */
    /* this.histologyForm.controls['hasMetasteses'].valueChanges.subscribe({
      next: (newValue) => this.enableDisableDetailControl(this.histologyForm.controls['metasteseDetail'], newValue)
    }); */
    this.histologyForm.controls['levelClark'].valueChanges.pipe(
      debounceTime(500)).subscribe(() => this.histologyForm.controls['levelClark'].value !== '' ? this.calcStage() : '');
    this.histologyForm.controls['breslow'].valueChanges.pipe(
      debounceTime(500)).subscribe(() => this.histologyForm.controls['breslow'].value !== '' ? this.calcStage() : '');
    this.histologyForm.controls['t'].valueChanges.pipe(
      debounceTime(500)).subscribe(() => this.histologyForm.controls['t'].value !== '' ? this.calcStage() : '');
    this.histologyForm.controls['hasUlceration'].valueChanges.pipe(
      debounceTime(500)).subscribe(() => this.calcStage());
    this.histologyForm.controls['lymphnodeDetail'].valueChanges.pipe(
      debounceTime(500)).subscribe(() => this.histologyForm.controls['lymphnodeDetail'].value !== '' ? this.calcStage() : '');
    this.histologyForm.controls['metasteseDetail'].valueChanges.pipe(
      debounceTime(500)).subscribe(() => this.histologyForm.controls['metasteseDetail'].value !== '' ? this.calcStage() : '');
  }

  /**
   * Method to get the from form data
   */
  calcStage() {

    const { breslow, hasUlceration, levelClark, lymphnodeDetail, metasteseDetail, t } = this.histologyForm.getRawValue();
    if (
      (breslow && breslow.length && levelClark && levelClark.length && typeof hasUlceration === 'number') ||
      (lymphnodeDetail && lymphnodeDetail.length && metasteseDetail && metasteseDetail.length && t && t.length)
    ) {
      const loader = this.loaderSrv.showSpinner();
      const data = {
        breslow,
        levelClark,
        hasUlceration,
        lymphnodeDetail,
        metasteseDetail,
        t
      };
      this.melanomaCalcSrv.melStageCalcPost(data).subscribe(
        (response) => {
          if (!instanceOfError(response)) {
            this.errorMsgStage = response.msg;
            this.histologyForm.controls['stage'].setValue(response.stage);
            this.histologyForm.controls['stage'].updateValueAndValidity();
            loader.close();
          } else {
            this.modalSrv.openModalDanger('ERROR.ERROR', (response as any).err?.key);
            loader.close();
          }
        },
        (error) => loader.close()
      );
    }
  }

  // Method to enable or disable select details control
  enableDisableDetailControl(control: AbstractControl, value: number | string) {
    if (value === 0) {
      control.addValidators(Validators.required);
      control.enable({ onlySelf: true });
      control.updateValueAndValidity();
    } else {
      control.clearValidators();
      control.reset();
      control.disable({ onlySelf: true });
      control.updateValueAndValidity();
    }
  }

  // Method to enable or disable end date control
  enableDisableEndDateControl(control: AbstractControl, value: boolean | string) {
    if (value === false) {
      control.addValidators(Validators.required);
      control.enable({ onlySelf: true });
      control.updateValueAndValidity();
    } else {
      control.clearValidators();
      control.reset();
      control.disable({ onlySelf: true });
      control.updateValueAndValidity();
    }
  }


  // Method to expand or collapse panel of medicine form in treatment block
  toggleExpansionMedicineForm(event: MatCheckboxChange, panel: MatExpansionPanel, option: number) {
    panel.toggle();
    if (option === 0) {
      this.setCorticosteroidsValidator(event.checked);
    } else {
      this.setOtherMedicineValidator(event.checked);
    }
  }

  isRequired(control: AbstractControl) {
    try {
      return control.hasValidator(Validators.required);
    } catch (error) {
      return false;
    }
  }

  checkTreatments(){
    if (this.typeForm.controls['type'].value === 2){
      if (!this.formError){
        return false;
      }
      return true;
    }
    return false;
  }
  // Method to set Other form validator in Medicine form
  setOtherMedicineValidator(checked: boolean) {
    if (checked) {
      this.medicineForm.controls['otherInCourse'].addValidators(Validators.required);
      this.medicineForm.controls['otherStartDate'].addValidators(Validators.required);
    } else {
      this.medicineForm.controls['otherInCourse'].clearValidators();
      this.medicineForm.controls['otherStartDate'].clearValidators();
      this.medicineForm.controls['otherEndDate'].clearValidators();
      this.medicineForm.controls['otherInCourse'].reset();
      this.medicineForm.controls['otherStartDate'].reset();
      this.medicineForm.controls['otherEndDate'].reset();
    }
    this.medicineForm.controls['otherInCourse'].updateValueAndValidity();
    this.medicineForm.controls['otherStartDate'].updateValueAndValidity();
    this.medicineForm.controls['otherEndDate'].updateValueAndValidity();
  }

  // Method to set Corticosteroids form validator in Medicine form
  setCorticosteroidsValidator(checked: boolean) {
    if (checked) {
      this.medicineForm.controls['corticosteroidsInCourse'].addValidators(Validators.required);
      this.medicineForm.controls['corticosteroidsStartDate'].addValidators(Validators.required);
    } else {
      this.medicineForm.controls['corticosteroidsInCourse'].clearValidators();
      this.medicineForm.controls['corticosteroidsStartDate'].clearValidators();
      this.medicineForm.controls['corticosteroidsEndDate'].clearValidators();
      this.medicineForm.controls['corticosteroidsInCourse'].reset();
      this.medicineForm.controls['corticosteroidsStartDate'].reset();
      this.medicineForm.controls['corticosteroidsEndDate'].reset();
    }
    this.medicineForm.controls['corticosteroidsInCourse'].updateValueAndValidity();
    this.medicineForm.controls['corticosteroidsStartDate'].updateValueAndValidity();
    this.medicineForm.controls['corticosteroidsEndDate'].updateValueAndValidity();
  }

  // Method to expand or collapse panel of treatment block except medicine form
  toggleExpansion(event: MatSlideToggleChange, panel: MatExpansionPanel, formType: string) {
    panel.toggle();
    this.updateColapsableForm(formType, event.checked);
    switch (formType) {
      case 'surgical':
        this.setValidatorsSurgicalForm(event.checked);
        break;
      case 'chemotherapy':
        this.setValidatorsTreatmentForm(this.chemotherapyForm, event.checked);
        break;
      case 'radiotherapy':
        this.setValidatorsTreatmentForm(this.radiotherapyForm, event.checked);
        break;
      case 'immunology':
        this.setValidatorsTreatmentForm(this.immunologyForm, event.checked);
        break;
    }
  }

  checkAllFieldsFalse(form: FormGroup): boolean {
    let formCheck = true;
    // Itera sobre todos los controles del formulario
    Object.keys(form.controls).forEach(key => {
      const control = form.controls[key];
      if (control.value === true) {
        formCheck = false;
      }
    });
    return formCheck;
  }

  updateColapsableForm(formName: string, checked: boolean){
    // Almaceno las claves de mi formulario
    const keys = Object.keys(this.collapsableForm.controls);
    keys.forEach(key => {
      // Verificar si el valor del control es igual al parámetro formCheck
      if (key === formName) {
        // Establecer el valor del control en el mismo valor que la clave modificada
        this.collapsableForm.get(key).setValue(checked);
        this.formError = this.checkAllFieldsFalse(this.collapsableForm);
      }
    });
  }

  // Method to set validators of treatment block form except surgical form
  setValidatorsTreatmentForm(form: FormGroup, checked: boolean) {
    if (checked) {
      form.controls['inCourse'].addValidators(Validators.required);
      // form.controls['startDate'].addValidators(Validators.required);
    } else {
      form.controls['inCourse'].clearValidators();
      // form.controls['startDate'].clearValidators();
      // form.controls['endDate'].clearValidators();

      // Fixed: 06/03/2024 -> Anteriormente el formulario se reseteaba usando solo la funcion .reset() sin parametros.
      // Esto daba lugar a errores, por ejemplo al volver a cargar el formulario, los campos tocados pero no rellenos (abrir y cerrar)
      // se marcaban abiertos bloqueando el formulario y trayendo información no valida
      // Al setear los campos manualmente en el reset, evitamos este comportamiento no deseado.
      form.reset({
        hasTreatment: false,
        inCourse: '',
        startDate: '',
        endDate: { disabled: true, value: '' }
      });
    }
    form.controls['inCourse'].updateValueAndValidity();
    // form.controls['startDate'].updateValueAndValidity();
    // form.controls['endDate'].updateValueAndValidity();
  }

  // Method to set validators of Surgical form
  setValidatorsSurgicalForm(checked: boolean) {

    // creamos una variable donde almacenaremos el valor original de typeForm
    if (checked) {
      // Añadimos validacion al campo exersis y al de intervención
      this.surgicalForm.controls['exeresis'].addValidators(Validators.required);
      this.surgicalForm.controls['interventionDate'].addValidators([
        Validators.required, this.formUtils.dateRangeValidator(this.typeForm.controls.diagnosisDate.value)
      ]);

      // Suscribimos el cambio de la fecha de diagnostico de forma que siempre valide si es anterior o igual que el campo de fecha de intervención
      // Asignamos a updatedDiagnosedDate el valor actualizado para el validador

      this.subscription = this.typeForm.controls.diagnosisDate.valueChanges.subscribe((value) => {
        this.surgicalForm.controls['interventionDate'].clearValidators();
        this.surgicalForm.controls['interventionDate'].addValidators([Validators.required, this.formUtils.dateRangeValidator(value)]);
        this.surgicalForm.controls['interventionDate'].updateValueAndValidity();
      });

    } else {
      // reiniciamos los validadores y el formulario si no estamos accediendo a el
      this.surgicalForm.controls['exeresis'].clearValidators();
      this.surgicalForm.controls['interventionDate'].clearValidators();
      // this.surgicalForm.reset();
      this.surgicalForm.reset({ hasTreatment: false, exeresis: '', interventionDate: '' });
      this.surgicalForm.updateValueAndValidity();

      this.subscription.unsubscribe();
    }

    // Actualizamos el formulario a consecuencia
    this.surgicalForm.controls['exeresis'].updateValueAndValidity();
    this.surgicalForm.controls['interventionDate'].updateValueAndValidity();
  }
  // Method to clean every form of this page
  cleanForms() {
    // Call ngOnit who initialize every form. So it has been resetted
    this.medicineAccordion.closeAll();
    this.accordion.closeAll();
    // Limpiamos el formulario de la cabecera
    this.typeForm.reset();
    // Cerramos y limpiamos el formulario de cirugia
    this.setValidatorsSurgicalForm(false);
    this.surgicalForm.controls.hasTreatment.setValue(false);
    this.surgicalForm.updateValueAndValidity();
    // Cerramos y limpiamos el formulario de quimioterapia
    this.setValidatorsTreatmentForm(this.chemotherapyForm, false);
    this.chemotherapyForm.controls.hasTreatment.setValue(false);
    this.chemotherapyForm.updateValueAndValidity();
    // Cerramos y limpiamos el formulario de radioterapia
    this.setValidatorsTreatmentForm(this.radiotherapyForm, false);
    this.radiotherapyForm.controls.hasTreatment.setValue(false);
    this.radiotherapyForm.updateValueAndValidity();
    // Cerramos y limpiamos el formulario de inmunologia
    this.setValidatorsTreatmentForm(this.immunologyForm, false);
    this.immunologyForm.controls.hasTreatment.setValue(false);
    this.immunologyForm.updateValueAndValidity();

    this.setCorticosteroidsValidator(false);
    this.setOtherMedicineValidator(false);
    this.medicineForm.controls.hasTreatment.setValue(false);
    this.medicineForm.controls.corticosteroids.setValue(false);
    this.medicineForm.controls.other.setValue(false);
    this.medicineForm.updateValueAndValidity();
    if (this.treatmentForm.controls.treatmentList) {
      while ((this.treatmentForm.controls.treatmentList as FormArray).length) {
        (this.treatmentForm.controls.treatmentList as FormArray).removeAt(0);
      }
    }
    this.errorMsgStage = '';

    this.histologyForm.reset(
      {
        levelClark: '',
        breslow: '',
        stage: '',
        t: '',
        hasUlceration: '',
        lymphnodeDetail: '',
        metasteseDetail: ''
      }
    );
    this.histologyForm.updateValueAndValidity();
    this.collapsableForm.reset(
      {
        surgical: false,
        chemotherapy: false,
        radiotherapy: false,
      }
    );
    this.formError = this.checkAllFieldsFalse(this.collapsableForm);
  }

  // Method to check if forms are valid. Return true if not valid, otherwise return false
  isNotValidateToCalc(): boolean {
    if (
      !this.typeForm.valid ||
      !this.surgicalForm.valid ||
      !this.chemotherapyForm.valid ||
      !this.radiotherapyForm.valid ||
      !this.immunologyForm.valid ||
      !this.medicineForm.valid ||
      !this.treatmentForm.valid ||
      !this.histologyForm.valid
    ) {
      return true;
    }
    return false;
  }

  markAllAsTouched() {
    this.typeForm.markAllAsTouched();
    this.surgicalForm.markAllAsTouched();
    this.chemotherapyForm.markAllAsTouched();
    this.radiotherapyForm.markAllAsTouched();
    this.immunologyForm.markAllAsTouched();
    this.medicineForm.markAllAsTouched();
    this.treatmentForm.markAllAsTouched();
    this.histologyForm.markAllAsTouched();
  }

  // Method which call calc service with the form data
  calc() {
    this.markAllAsTouched();
    if (!this.isNotValidateToCalc()) {
      const loader = this.loaderSrv.showSpinner();
      const data = this.prepareCalcData();
      this.melanomaCalcSrv.melanomaPost(data).subscribe({
        next: (response) => {
          loader.close();
          if (instanceOfError(response)) {
            if ((response as any).err?.msg === 'The stage is incompatible'){
              this.modalSrv.openModalDanger('ERROR.ERROR', 'ERROR.STAGE.INCOMPATIBLE');
            }else{
              this.modalSrv.openModalDanger('ERROR.ERROR', 'ERROR.MELANOMA-CALC');
            }
          } else {
            this.dataResult.emit({ data, result: response });
          }
        }
      });
    }
  }

  // Method to prepare data for calc service
  prepareCalcData(): any {
    const value: IMelanomaForm = {
      melanomaType: {
        typeCode: this.typeForm.get('type').value,
        diagnosisDate: this.typeForm.get('diagnosisDate').value
      },
      treatmentType: {
        surgical:
          this.surgicalForm.get('hasTreatment').value === false
            ? null
            : {
              exeresis: this.surgicalForm.get('exeresis').value,
              interventionDate: this.surgicalForm.get('interventionDate').value
            },
        chemotherapy:
          this.chemotherapyForm.get('hasTreatment').value === false
            ? null
            : {
              inCourse: this.chemotherapyForm.get('inCourse').value,
              startDate: this.chemotherapyForm.get('startDate').value,
              endDate: this.chemotherapyForm.get('endDate').value ? this.chemotherapyForm.get('endDate').value : null
            },
        radiotherapy:
          this.radiotherapyForm.get('hasTreatment').value === false
            ? null
            : {
              inCourse: this.radiotherapyForm.get('inCourse').value,
              startDate: this.radiotherapyForm.get('startDate').value,
              endDate: this.radiotherapyForm.get('endDate').value ? this.radiotherapyForm.get('endDate').value : null
            },
        immunology:
          this.immunologyForm.get('hasTreatment').value === false
            ? null
            : {
              inCourse: this.immunologyForm.get('inCourse').value,
              startDate: this.immunologyForm.get('startDate').value,
              endDate: this.immunologyForm.get('endDate').value ? this.immunologyForm.get('endDate').value : null
            },
        medicines:
          this.medicineForm.get('hasTreatment').value === false
            ? null
            : {
              corticosteroids: this.medicineForm.get('corticosteroids').value,
              corticosteroidsInCourse: this.medicineForm.get('corticosteroidsInCourse').value,
              corticosteroidsStartDate: this.medicineForm.get('corticosteroidsStartDate').value,
              corticosteroidsEndDate: this.medicineForm.get('corticosteroidsEndDate').value
                ? this.medicineForm.get('corticosteroidsEndDate').value
                : null,
              other: this.medicineForm.get('other').value,
              otherInCourse: this.medicineForm.get('otherInCourse').value,
              otherStartDate: this.medicineForm.get('otherStartDate').value,
              otherEndDate: this.medicineForm.get('otherEndDate').value ? this.medicineForm.get('otherEndDate').value : null
            }
      },
      treatment: (this.treatmentForm.controls.treatmentList as FormArray).getRawValue(),
      histology: {
        levelClark: this.histologyForm.get('levelClark').value,
        breslow: this.histologyForm.get('breslow').value,
        stage: this.histologyForm.get('stage').value,
        t: this.histologyForm.get('t').value,
        hasUlceration: this.histologyForm.get('hasUlceration').value,
        lymphnodeDetail: this.histologyForm.get('lymphnodeDetail').value,
        metasteseDetail: this.histologyForm.get('metasteseDetail').value
      }
    };
    return value;
  }

  // Method to patch value to forms in edit mode
  patchDataValues() {
    // Melanoma type
    this.typeForm.patchValue(this.data.melanomaType);
    // Histology form
    this.histologyForm.patchValue(this.data.histology);
    this.histologyForm.updateValueAndValidity();
    // Medical treatment form @Input in component app-medical-detail

    // Treatment type
    if (this.data.treatmentType.surgical) {
      this.surgicalForm.get('hasTreatment').setValue(true);
      this.surgicalForm.patchValue(this.data.treatmentType.surgical);
      this.setValidatorsSurgicalForm(true);
    }
    if (this.data.treatmentType.chemotherapy) {
      this.chemotherapyForm.get('hasTreatment').setValue(true);
      this.chemotherapyForm.patchValue(this.data.treatmentType.chemotherapy);
      this.setValidatorsTreatmentForm(this.chemotherapyForm, true);
    }
    if (this.data.treatmentType.radiotherapy) {
      this.radiotherapyForm.get('hasTreatment').setValue(true);
      this.radiotherapyForm.patchValue(this.data.treatmentType.radiotherapy);
      this.setValidatorsTreatmentForm(this.radiotherapyForm, true);
    }
    if (this.data.treatmentType.immunology) {
      this.immunologyForm.get('hasTreatment').setValue(true);
      this.immunologyForm.patchValue(this.data.treatmentType.immunology);
      this.setValidatorsTreatmentForm(this.immunologyForm, true);
    }
    if (this.data.treatmentType.medicines) {
      this.medicineForm.get('hasTreatment').setValue(true);
      this.medicineForm.patchValue(this.data.treatmentType.medicines);
      if (this.data.treatmentType.medicines.corticosteroids) {
        this.setCorticosteroidsValidator(true);
      }
      if (this.data.treatmentType.medicines.other) {
        this.setOtherMedicineValidator(true);
      }
    }
  }
}
