import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-detail-case-print',
  templateUrl: './detail-case-print.component.html',
  styleUrls: ['./detail-case-print.component.scss']
})
export class DetailCasePrintComponent implements OnInit {
  @Input() basicData: any;
  @Input() state: any;
  @Input() idCase: string;
  @Input() risksFees: any;
  @Input() dataComments: { idCase: string; stateCase: number; comments: any[] };
  @Input() finalComments: any;
  finalResult = [];
  formData: FormGroup = new FormGroup({});
  showFinalResult = false;
  date: Date;

  ngOnInit() {
    this.date = new Date();
    this.makeFinalResult();
  }

  makeFinalResult() {
    if (this.risksFees.values.find((item) => item.id === 'finalResult')) {
      for (let index = 2; index < this.risksFees.headerValues.length; index++) {
        const t = this.risksFees.headerValues[index];
        const v = this.risksFees.values.find((item) => item.id === 'finalResult').values[index];
        if (v) {
          this.finalResult.push({ title: t, value: v });
          this.showFinalResult = true;
        }
      }
    }
  }
}
