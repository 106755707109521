import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maresel-header',
  templateUrl: './maresel-header.component.html',
  styleUrls: ['./maresel-header.component.scss']
})
export class MareselHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
