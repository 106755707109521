import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CedentesService, RolesService, SearchuserUsers, ValidateuserInput, ValidateuserService } from '@app/core/services/api';
import { ICedente } from '@app/core/services/api/model/cedente.model';
import { Searchuser } from '@app/core/services/api/model/searchUser';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { ModalService } from '@app/core/services/modal/modal.service';
import { CedenteService } from '@app/core/services/cedente/cedente.service';
import {AuthenticationService} from '@core/authentication/authentication.service';

interface IDataInput {
  data: SearchuserUsers;
  cedentes: ICedente[];
}

@Component({
  selector: 'app-modal-subscribe-user',
  templateUrl: './modal-subscribe-user.component.html',
  styleUrls: ['./modal-subscribe-user.component.scss']
})
export class ModalSubscribeUserComponent implements OnInit  {
  @ViewChild('roleInput') roleInput: ElementRef<HTMLInputElement>;
  form: FormGroup;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  roles: string[] = [];
  userRole: string[] = [];
  cedentes: ICedente[] = [];
  filteredRoles: Observable<string[]>;

  constructor(
    private translateSrv: TranslateService,
    private validateSrv: ValidateuserService,
    private roleSrv: RolesService,
    private cedenteSrv: CedenteService,
    private spinnerService: SpinnerService,
    private modalSrv: ModalService,
    private authSrv: AuthenticationService,
    public dialogRef: MatDialogRef<ModalSubscribeUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SearchuserUsers
  ) {}

  ngOnInit() {
    this.data.cedente = { key: '', name: '' };
    this.data.rol = '';
    const loader = this.spinnerService.showSpinner();
    this.form = new FormGroup({
      cedente: new FormControl(this.data.cedente ? this.data.cedente : '', [Validators.required]),
      rol: new FormControl(this.data.rol ? this.data.rol : '', [Validators.required])
    });
    this.form.updateValueAndValidity();
    /*
    this.userRole = this.data.rol ? [...this.data.rol.split(',')] : [];
    */
    this.roleSrv.rolesGet().subscribe({
      next: (response) => {
        this.roles = [...response];
        // console.log(this.roles);
        this.cedentes = [...this.cedenteSrv.cedentes];
        loader.close();
        if (!this.cedentes.length || !this.roles.length) {
          this.showErrorModal();
        }
      },
      error: (error) => {
        loader.close();
        this.showErrorModal();
      }
    });
    this.form.get('cedente').valueChanges.subscribe({
      next: (newValue) => {
        // console.log(err)(newValue); [Comentado para debugguear]
        this.data.cedente = this.cedentes.find((cedente) => (cedente.key === newValue));
      }
    });

    this.form.get('rol').valueChanges.subscribe({
      next: (newValue) => {
        this.data.rol = newValue;
      }
    });
    /*
    this.filteredRoles = this.form.get('rol').valueChanges.pipe(
      startWith(null),
      map((rol: string | null) => (rol ? this._filter(rol) : this.roles.slice()))
    );
*/
    setTimeout(() => {
      if (this.isMapfreUser()) {
        this.form.get('rol').setValue('Suscriptor');
        this.form.get('rol').disable();
      }
    }, 500);
  }
  /*
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our role
    if (value) {
      this.userRole.push(value);
    }
    // Clear the input value
    event.chipInput!.clear();

    this.form.get('rol').setValue(null);

    this.data.rol = this.userRole.toString();
  }
*/

  showErrorModal() {
    this.modalSrv
      .openModalDanger('ERROR.ERROR', 'ERROR.MODAL.ERROR-LOAD-SUBSCRIBE-USER-DATA')
      .afterClosed()
      .subscribe(() => this.close());
  }

  confirmSuscribe() {
    const values = this.form.getRawValue();
    const body: ValidateuserInput = {
      email: this.data.email,
      cedente: values.cedente.key,
      rol: values.rol
    };
    // console.log(body);
    const spinner = this.spinnerService.showSpinner();
    this.validateSrv.validateuserPost(body).subscribe(
      (resp) => {
        spinner.close();
        if ((resp as any).errorMessage || resp === null) {
          // console.log((resp as any).errorMessage);
          this.modalSrv.openModalDanger('ERROR.ERROR', (resp as any).errorMessage);
        } else {
          this.dialogRef.close();
          this.modalSrv.refreshUsers$.next(true);
        }
      },
      (err) => {
        spinner.close();
        console.log(err);
        this.modalSrv.openModalDanger('ERROR.ERROR', err.errorMessage);
      }
    );
  }

  validateForm(){
    const isValid = this.form.valid;
    return isValid;
  }

  isMapfreUser(): boolean {
    return this.authSrv.isMapfreDomian(this.data.email);
  }

  /*
  remove(role: string): void {
    const index = this.userRole.indexOf(role);

    if (index >= 0) {
      this.userRole.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.userRole.push(event.option.viewValue);
    this.roleInput.nativeElement.value = '';
    this.form.get('rol').setValue(null);
    this.data.rol = this.userRole.toString();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.roles.filter((role) => role.toLowerCase().includes(filterValue));
  }
*/

  close() {
    this.dialogRef.close();
  }
}
