import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-maresel-input-autocomplete-coberturas',
  templateUrl: './maresel-input-autocomplete-coberturas.component.html',
  styleUrls: ['./maresel-input-autocomplete-coberturas.component.scss']
})
export class MareselInputAutocompleteCoberturasComponent implements OnInit {
  @Input() label = '';
  @Input() control: FormControl;
  @Input() dataOptions: string[] = [];

  filteredOptions: Observable<string[]>;
  controlValue: string;

  constructor() {}

  ngOnInit() {
    this.controlValue = this.control.value && typeof this.control.value === 'string' ? this.control.value : '';
    if (this.control.value && typeof this.control.value === 'object') {
      this.control.setValue('');
      this.control.clearValidators();
      this.control.updateValueAndValidity();
    }
    this.control.valueChanges.subscribe((value) => {
      if (typeof value === 'string') {
        let isSelectedOption = false;
        this.dataOptions.forEach((option) => {
          if (value.endsWith(option)) {
            isSelectedOption = true;
          }
        });
        if (!isSelectedOption) {
          this.controlValue = value;
        }
      }
    });
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  concatSelectedValue(event: MatAutocompleteSelectedEvent) {
    this.controlValue = this.controlValue.concat(event.option.value);
    this.control.setValue(this.controlValue);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.dataOptions.filter((option) => option?.toLowerCase().includes(filterValue));
  }
}
