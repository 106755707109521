import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArticlesArticles } from '@app/core/services/api';

@Component({
  selector: 'app-article-item',
  templateUrl: './article-item.component.html',
  styleUrls: ['./article-item.component.scss']
})
export class ArticleItemComponent implements OnInit {
  @Input() article: ArticlesArticles;
  @Output() selectArticle: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {
    // console.log(this.article);
  }

  emit() {
    this.selectArticle.emit();
  }
}
