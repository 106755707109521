import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contenido, ContenidoService } from '@app/core/services/api';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-dynamic-integration',
  templateUrl: './modal-dynamic-integration.component.html',
  styleUrls: ['./modal-dynamic-integration.component.scss']
})
export class ModalDynamicIntegrationComponent implements OnInit {
  contentHtml = '';

  constructor(
    private spinnerService: SpinnerService,
    private contenidoService: ContenidoService,
    @Inject(MAT_DIALOG_DATA) public data: { content: string },
    public dialogRef: MatDialogRef<ModalDynamicIntegrationComponent>,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.getContent(this.data.content);
  }

  close() {
    this.dialogRef.close();
  }

  getContent(content: string) {
    const loading = this.spinnerService.showSpinner();
    this.contenidoService.contenidoContentGet(content.toLowerCase() + '_' + this.translateService.currentLang).subscribe(
      (response: Contenido) => {
        // console.log('PRUEBAS =>', response);
        loading.close();
        this.contentHtml = response.html;
      },
      (err) => {
        console.error(err);
        loading.close();
      }
    );
  }
}
