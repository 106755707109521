import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-metabolic-calc-results',
  templateUrl: './metabolic-calc-results.component.html',
  styleUrls: ['./metabolic-calc-results.component.scss']
})
export class MetabolicCalcResultsComponent implements OnInit {
  @Input('calcResults') calcResults: any = {};
  tableData: any;
  constructor(
    private translateSrv: TranslateService,
    private activatedRouter: ActivatedRoute) {}

  ngOnInit() {
    this.prepareResultData();
  }
  prepareResultData() {
    this.tableData = {
      stickyColsStart: 2,
      stickyColsEnd: 1,
      headerValues: [' ', 'RATE.TTL-HEADER-RISK'],
      values: [
        {
          id: this.calcResults.result.id,
          values: ['', 'TITLES.METABOLIC-CALCULATOR']
        }
      ]
    };
    this.calcResults.result.cobertura.map((coverage) => {
      this.tableData.headerValues.push(this.translateSrv.instant(`RATE.COBERTURAS.${coverage.id}`));
      this.tableData.values[0].values.push(coverage.valor);
    });
  }
  cartDisclaimer(): boolean {
    const idCaseParam = this.activatedRouter.snapshot.paramMap.get('idCase');

    let idCaseStorage: any;

    try {
      const parsedData = JSON.parse(localStorage.getItem('CASE_IN_COURSE') || '{}');
      idCaseStorage = parsedData?.idCase || null;
    } catch (e) {
      idCaseStorage = null;
    }

    // console.log('Param: ', idCaseParam, 'Storage: ', idCaseStorage);

    // Caso en el que ambos tienen algún valor pero son distintos: no debemos almacenar el calculador de un caso parametrizado en el caso en curso
    if (idCaseStorage !== null && idCaseParam !== null && idCaseStorage !== idCaseParam) {
      return true;
    } else { // En todos los demás casos permitimos
      return false;
    }

  }

}
