import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { DurationService } from '@app/core/services/api/api/duration.service';
import { TranslateService } from '@ngx-translate/core';
import { IAutocompte } from '../maresel-input-autocomplete/maresel-input-autocomplete.component';

@Component({
  selector: 'app-duration-select',
  templateUrl: './duration-select.component.html',
  styleUrls: ['./duration-select.component.scss'],
})
export class DurationSelectComponent implements OnInit {
  @Input() control: FormControl;
  @Input() label: string;
  durationOptions: IAutocompte[] = [];

  constructor(
    private translateSrv: TranslateService,
    private durationSrv: DurationService
  ) { }

  ngOnInit() {
    this.durationSrv.durationGet().subscribe((durations: string[]) => {
      this.durationOptions = durations
        .map((_c: string) => ({ value: _c, label: this.translateSrv.instant(_c) }))
        .sort((a, b) => (a.label > b.label) ? 1 : -1);
    });
  }

  isRequired(control: AbstractControl) {
    try {
      return control.hasValidator(Validators.required);
    } catch (error) {
      return false;
    }
  }

}
