import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RatesService } from '@app/core/services/api';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ModalService } from '@app/core/services/modal/modal.service';
import { MenuService } from '@app/core/services/menu/menu.service';
import { instanceOfError } from '../utils/commonMethods';

@Component({
  selector: 'app-modal-tarifas',
  templateUrl: './modal-tarifas.component.html',
  styleUrls: ['./modal-tarifas.component.scss']
})
export class ModalTarifasComponent implements OnInit {
  ratesData: any;
  ratesEditData: any;
  title: string;
  isEdit = false;
  criterialPricing = '';

  constructor(
    public dialogRef: MatDialogRef<ModalTarifasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ratesService: RatesService,
    private loaderSrv: SpinnerService,
    private authSrv: AuthenticationService,
    private modalSrv: ModalService,
    private menuService: MenuService
  ) {}

  ngOnInit() {
    const loader = this.loaderSrv.showSpinner();
    this.ratesData = null;
    this.ratesService.ratesPost({ risk: this.data.risk, lang: this.data.lang }).subscribe({
      next: (response) => {
        // console.log(response);
        if (instanceOfError(response)) {
          loader.close();
          this.modalSrv.openModalDanger('Error', 'ERROR.ERROR-RATES');
        } else {
          this.criterialPricing = response.mensaje;
          this.ratesData = { ...response };
          this.title = this.menuService.getTitleSubtitle('/risks/' + this.data.risk)?.title;
          loader.close();
        }
      },
      error: (error) => {
        console.error(error);
        loader.close();
        this.modalSrv.openModalDanger('Error', 'ERROR.ERROR-RATES');
      }
    });
  }

  edit() {
    this.isEdit = true;
  }
  cancelEdit(isSaved: boolean) {
    this.isEdit = false;
    isSaved && this.ngOnInit();
  }

  canEditFromRole(): boolean {
    return this.authSrv.checkRolUser('Administrador') || this.authSrv.checkRolUser('Responsable de contenido');
  }

  close() {
    this.dialogRef.close();
  }
}
